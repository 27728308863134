// libs
import * as React from 'react';
import { useHistory, useLocation } from 'react-router';

// interfaces / constants

// helpers
import { textResources } from 'src/lang/de';
import { createBemBlock } from 'src/utils/bem_helper/bem_helper';
import { UrlUtils } from 'src/utils/url/url';
import './wizard_button.scss';

const cls = createBemBlock('wizard-button')();
const labels = textResources.wizard;
const sharedLabels = textResources.shared;

export const WIZARD_BUTTON_TYPE_CANCEL = 'CANCEL';
export const WIZARD_BUTTON_TYPE_COMPLETED = 'COMPLETED';
export const WIZARD_BUTTON_TYPE_NEXT = 'NEXT';
export const WIZARD_BUTTON_TYPE_PREV = 'PREV';
export const WIZARD_BUTTON_TYPE_REQUEST = 'REQUEST';
export const WIZARD_BUTTON_TYPE_SAVE = 'SAVE';
export const WIZARD_BUTTON_TYPE_SKIP = 'SKIP';
export const WIZARD_BUTTON_TYPE_SUBMIT = 'SUBMIT';
export const WIZARD_BUTTON_TYPE_VERIFY = 'VERIFY';
export const WIZARD_BUTTON_TYPE_SEND = 'SEND';
export const WIZARD_BUTTON_TYPE_START = 'START';

type WizardButtonTextType =
| typeof WIZARD_BUTTON_TYPE_CANCEL
| typeof WIZARD_BUTTON_TYPE_COMPLETED
| typeof WIZARD_BUTTON_TYPE_NEXT
| typeof WIZARD_BUTTON_TYPE_PREV
| typeof WIZARD_BUTTON_TYPE_REQUEST
| typeof WIZARD_BUTTON_TYPE_SAVE
| typeof WIZARD_BUTTON_TYPE_SKIP
| typeof WIZARD_BUTTON_TYPE_SEND
| typeof WIZARD_BUTTON_TYPE_START
| typeof WIZARD_BUTTON_TYPE_SUBMIT
| typeof WIZARD_BUTTON_TYPE_VERIFY;

export interface IWizardButton {
  isValid?: boolean;
  textType: WizardButtonTextType;
  onClick: () => void;
}

const getButtonLabel = (btnType: WizardButtonTextType): string => {
  switch (btnType) {
    case WIZARD_BUTTON_TYPE_CANCEL:
      return sharedLabels.cancel;
    case WIZARD_BUTTON_TYPE_NEXT:
      return sharedLabels.continue;
    case WIZARD_BUTTON_TYPE_PREV:
      return sharedLabels.back;
    case WIZARD_BUTTON_TYPE_REQUEST:
      return labels.request;
    case WIZARD_BUTTON_TYPE_SAVE:
      return sharedLabels.save;
    case WIZARD_BUTTON_TYPE_SKIP:
      return sharedLabels.skip;
    case WIZARD_BUTTON_TYPE_SUBMIT:
      return sharedLabels.submit;
    case WIZARD_BUTTON_TYPE_COMPLETED:
      return sharedLabels.done;
    case WIZARD_BUTTON_TYPE_VERIFY:
      return sharedLabels.verify;
    case WIZARD_BUTTON_TYPE_SEND:
      return labels.send;
    case WIZARD_BUTTON_TYPE_START:
      return labels.start;
    default : return '';
  }
};

export const WizardButton: React.FC<IWizardButton> = ({
  isValid = true,
  textType,
  onClick,
}) => (
  <button
    className={cls}
    disabled={!isValid}
    onClick={onClick}
  >
    {getButtonLabel(textType)}
  </button>
);

/*
 * WizardGoBackButton
 */
interface IWizardGoBackButton {
  isValid?: boolean;
  textType?: WizardButtonTextType;
  callback?: () => void;
  fallbackRoute?: string;
}

export const WizardGoBackButton: React.FC<IWizardGoBackButton> = ({
  callback,
  isValid = true,
  textType = WIZARD_BUTTON_TYPE_PREV,
  fallbackRoute,
}) => {
  const history = useHistory();
  const onClick = () => {
    callback && callback();
    UrlUtils.goBack(history, fallbackRoute);
  };
  return (
    <WizardButton isValid={isValid} textType={textType} onClick={onClick}/>
  );
};

/*
 * WizardGoToButton
 */
interface IWizardGoToButton {
  textType?: WizardButtonTextType;
  callback?: () => void;
  path: string;
  state?: { index: number };
}

interface IWizardGoToButtonValidated extends IWizardGoToButton {
  isValid?: boolean;
}

// To set the validaty of a WizardButton in the common step, we need to use a Factory
type WizardGoToButtonFactoryType = (props: IWizardGoToButton) => WizardGoToButtonValidator;
export type WizardGoToButtonValidator = (isValid?: boolean) => JSX.Element;

export const WizardGoToButtonFactory: WizardGoToButtonFactoryType = (props: IWizardGoToButton) =>
  (isValid = true) => (<WizardGoToButton {...props} isValid={isValid} />);

export const WizardGoToButton: React.FC<IWizardGoToButtonValidated> = ({
  callback,
  isValid,
  path,
  state,
  textType = WIZARD_BUTTON_TYPE_NEXT,
}) => {
  const history = useHistory();
  const { search } = useLocation();
  const onClick = () => {
    callback && callback();
    const relativePath = path.replace('/', '');
    history.push(relativePath + search, state);
  };
  return (
    <WizardButton isValid={isValid} textType={textType} onClick={onClick} />
  );
};

export default WizardButton;
