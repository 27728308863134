import { useCallback } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { IContentItemType } from '../../../interfaces/posts';
import { useSurvey } from '../../../plugins/survey/hook/useSurvey';
import { UrlUtils } from '../../../utils/url/url';

export const useCreatePostWizard = () => {
  const { isEnabled: isSurveyEnabled } = useSurvey();
  const history = useHistory();
  const { search } = useLocation();
  const match = useRouteMatch();

  return useCallback((forceWizard: boolean, postType?: IContentItemType) => {
    const matchUrl = match!.url;
    history.push(UrlUtils.getPostCreateURL(matchUrl, search, forceWizard && isSurveyEnabled, postType));
  }, [history, isSurveyEnabled, match, search]);
};
