import classNames from 'classnames';
import { History } from 'history';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

// components
import AppStoreLinks from 'src/components/app_store_links/app_store_links';
import { TextButton } from 'src/components/authentication/auth_form_elements';
import FacebookRegistrationEmail from 'src/components/authentication/layouts/facebook_registration_email';
import ForgotPassword from 'src/components/authentication/layouts/forgot_password';
import Login from 'src/components/authentication/layouts/login';
import Registration from 'src/components/authentication/layouts/register';
import ResetPassword from 'src/components/authentication/layouts/reset_password';
import MessageBox from 'src/components/message_box/message_box';
import SnackBar from 'src/components/snack_bar/snack_bar';
import StaticPages from 'src/components/static_pages/static_pages';
import { TabItemButton } from 'src/components/tabs/tab_item';
import Tabs from 'src/components/tabs/tabs';

// interfaces, types
import { AUTH_UI_CLASS } from 'src/constants';
import {
  SettingsStatusError,
  SettingsStatusSuccess,
} from 'src/constants/settings';
import { IAuthUIProps } from 'src/containers/auth_ui';
import {
  MODAL_TYPE_FORGOT_PASSWORD,
  MODAL_TYPE_LOGIN,
  MODAL_TYPE_REGISTRATION,
  MODAL_TYPE_FACEBOOK_REGISTRATION_EMAIL,
  AuthModalType,
} from 'src/interfaces/app-state';
import { IPosition } from 'src/interfaces/location';

// helpers
import 'src/components/authentication/auth_ui.scss';
import { textResources } from 'src/lang/de';
import { createBemBlock } from 'src/utils/bem_helper/bem_helper';
import initTracker, { AUTH, FORGOT_PASSWORD } from 'src/utils/reporting/events_tracking/events_tracking';

interface IError {
  message: string;
  id: number;
}

interface IState {
  activeLayout: AuthModalType;
  errors: IError[];
  email: string;
}

type LayoutType = {
  [key in AuthModalType]?: React.ComponentType<IAuthProps>;
};

type DefaultLayoutTitle = {
  [key in AuthModalType]: string;
};

interface IPasswordResetError {
  errorCode: string;
  statusCode: SettingsStatusError;
}

interface IPasswordResetSuccess {
  statusCode: SettingsStatusSuccess;
  token: string;
}

export type PasswordResetParams = IPasswordResetError | IPasswordResetSuccess;

export interface IAuthProps {
  changeEmail?: (val: string) => void;
  email?: string;
  goToForgotPassword?: () => void;
  goToLogin?: () => void;
  goToRegister?: () => void;
  googlePlaceId?: string;
  history: History;
  logIn?: () => void;
  onClose?: () => void;
  passwordResetParams?: PasswordResetParams;
  position?: IPosition;
  loggedIn?: boolean;
  pushError?: (message: string) => void;
  showAlreadyRegistered?: boolean;
  rounded?: boolean;
}

interface IProps extends IAuthUIProps, RouteComponentProps {
}
let uid = 0;

const authTracker = initTracker(AUTH);
const cls = createBemBlock(AUTH_UI_CLASS);
const labels = textResources.authentication;

const layoutTitle: DefaultLayoutTitle = {
  appRegistration: labels.registerNow,
  facebookRegistrationEmail: labels.facebookRegistrationEmailTitle,
  forgotPassword: labels.passwordForgot,
  login: labels.loginTitle,
  registration: labels.registerNow,
  resetPassword: labels.passwordResetTitle,
};

// this is the Authentication Container Component
// it can handle the navigation between the different authentication views,
// it contains the state and it handles the submit
class AuthUI extends React.Component<IProps, IState> {
  private layout: LayoutType = {
    appRegistration: props => <Registration {...props} showAlreadyRegistered={false} />,
    facebookRegistrationEmail: FacebookRegistrationEmail,
    forgotPassword: ForgotPassword,
    login: Login,
    registration: props => <Registration {...props} showAlreadyRegistered={true} />,
    resetPassword: (props) => <ResetPassword {...props} history={this.props.history}/>,
  };

  constructor(props: IProps) {
    super(props);
    this.state = {
      activeLayout: props.layout,
      email: props.email || '',
      errors: [],
    };
    this.pushError = this.pushError.bind(this);
    this.removeError = this.removeError.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.goToLogin = this.goToLogin.bind(this);
    this.goToRegister = this.goToRegister.bind(this);
    this.goToForgotPassword = this.goToForgotPassword.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  public render() {
    const Element: React.ComponentType<IAuthProps> | undefined = this.layout[this.state.activeLayout];
    const headerTitle = this.props.headerTitle || layoutTitle[this.state.activeLayout];
    const { hasCancelButton, hasTabs, hasStaticLinks, rounded } = this.props;
    if (Element) {
      return (
        <>
          {this.state.errors.map(({ message, id }) =>
            <SnackBar key={id} id={id} onClose={this.removeError} message={message} showClose />)}
          <div className={cls()}>
            {hasTabs && this.renderTabs()}
            <MessageBox
              rounded={rounded}
              title={headerTitle}>
              <div>
                {this.renderElement(Element)}
                {hasStaticLinks &&
                  <div className={
                    classNames(
                      cls('static-links'),
                      { [cls('static-links', 'login')]: this.state.activeLayout === MODAL_TYPE_LOGIN }
                    )
                  }>
                    <StaticPages />
                  </div>
                }
                {this.state.activeLayout === MODAL_TYPE_LOGIN &&
                  <div className={cls('store-links')}><AppStoreLinks/></div>}
                {hasCancelButton &&
                <div className={cls('cancel-button')}>
                  <TextButton onClick={this.onCancel} label={textResources.shared.cancel} />
                </div>}
              </div>
            </MessageBox>
          </div>
        </>
      );
    }
    return null;
  }

  public renderTabs() {
    return (
      <Tabs className={cls('tabs')}>
        <TabItemButton
          textLabel={textResources.shared.login}
          onClick={this.goToLogin}
          isActive={this.isLoginTabActive()}
        />
        <TabItemButton
          textLabel={textResources.shared.register}
          onClick={this.goToRegister}
          isActive={this.isRegisterTabActive()}
        />
      </Tabs>
    );
  }

  private isLoginTabActive(): boolean {
    const { activeLayout } = this.state;
    return activeLayout === MODAL_TYPE_LOGIN || activeLayout === MODAL_TYPE_FORGOT_PASSWORD;
  }

  private isRegisterTabActive(): boolean {
    const { activeLayout } = this.state;
    return activeLayout === MODAL_TYPE_REGISTRATION || activeLayout === MODAL_TYPE_FACEBOOK_REGISTRATION_EMAIL;
  }

  private goToLogin() {
    authTracker(AUTH.ACTIONS.SWITCH_FORM_LOGIN, AUTH.LABELS.FINISH);
    this.setState({ activeLayout: MODAL_TYPE_LOGIN });
  }

  private goToRegister() {
    authTracker(AUTH.ACTIONS.SWITCH_FORM_REGISTRATION, AUTH.LABELS.FINISH);
    this.setState({ activeLayout: MODAL_TYPE_REGISTRATION });
  }

  private goToForgotPassword() {
    authTracker(AUTH.ACTIONS.SWITCH_FORM_FORGOT_PASSWORD, AUTH.LABELS.FINISH);
    this.setState({ activeLayout: MODAL_TYPE_FORGOT_PASSWORD });
  }

  private onCancel() {
    const { loggedIn, onClose } = this.props;
    if (this.state.activeLayout === MODAL_TYPE_FORGOT_PASSWORD) {
      const forgotPWTracker = initTracker(FORGOT_PASSWORD);
      forgotPWTracker(FORGOT_PASSWORD.ACTIONS.EMAIL, FORGOT_PASSWORD.LABELS.CANCEL);
      if (!loggedIn) {
        this.goToLogin();
        return;
      }
    }
    onClose && onClose();
  }

  private changeEmail(value: string) {
    this.setState({ email: value });
  }

  private renderElement(Element: React.ComponentType<IAuthProps>) {
    const { loggedIn, logIn, position, passwordResetParams, onClose,
      history, googlePlaceId } = this.props;
    const newProps: IAuthProps = {
      changeEmail: this.changeEmail,
      email: this.state.email,
      goToForgotPassword: this.goToForgotPassword,
      goToLogin: this.goToLogin,
      goToRegister: this.goToRegister,
      googlePlaceId,
      history,
      logIn,
      loggedIn,
      onClose,
      passwordResetParams,
      position,
      pushError: this.pushError,
    };

    return <Element {...newProps} />;
  }

  private pushError(message: string) {
    this.setState({
      errors: [...this.state.errors, { id: uid++, message }],
    });
  }

  private removeError(idToRemove: number) {
    this.setState({
      errors: this.state.errors.filter(({ id }) => id !== idToRemove),
    });
  }
}

export default AuthUI;
