// libs
import classNames from 'classnames';
import { throttle } from 'lodash';
import React, { useState, useCallback, useEffect, ComponentType } from 'react';

// interfaces / constants
import { IContentItemType } from 'src/interfaces/posts';

// components
import CreateItem from './create_item/create_item';

// helpers
import { useResizeListener } from 'src/hooks_shared/use_event_listener';
import { createBemBlock } from 'src/utils/bem_helper/bem_helper';
import { readColumnCountFromDOM } from 'src/utils/column_count';

import FixedHeight from '../../../containers/smart_components/fixed_height/fixed_height';
import './create_banner.scss';
import { useCreatePostWizard } from './useCreatePostWizard';

export interface IOwnProps {
  callToActionText: string;
  featureBlocked: boolean;
  postType?: IContentItemType;
  tileSize?: boolean;
  forceWizard?: boolean;
  centerText?: boolean;
  CustomCreateItem?: ComponentType;
}

export interface IeStoreState {
  avatarImageUrl?: string;
}

type IProps = IeStoreState & IOwnProps;

const cls = createBemBlock('create-banner');

const CreateBanner: React.FC<IProps> = ({
  callToActionText,
  children,
  featureBlocked,
  forceWizard,
  postType,
  tileSize,
  centerText,
  CustomCreateItem,
}) => {
  const [columnCount, setColumnCount] = useState(() => readColumnCountFromDOM());
  const listener = useCallback(
    throttle(() => {
      setColumnCount(readColumnCountFromDOM());
    }, 100),
    [],
  );

  const showWizard = useCreatePostWizard();

  const handleClick = useCallback(() => {
    showWizard(!!forceWizard, postType);
  }, [forceWizard, postType, showWizard]);

  useEffect(() => {
    setColumnCount(readColumnCountFromDOM());
  }, []);

  useResizeListener(listener, { passive: true });

  const Create = () => <CreateItem
    height={CustomCreateItem && null}
    active={featureBlocked}
    onClick={handleClick}
    label={callToActionText}
    centerText={centerText}
  >
    {CustomCreateItem && <CustomCreateItem />}
  </CreateItem>;

  const Content = () => (
    <div className={classNames({
      [cls('ctas')]: true,
      [cls('ctas', 'tile-size')]: !!tileSize,
    })}>
      {(CustomCreateItem || columnCount > 1) && <Create />}
      {children}
    </div>
  );

  if (!CustomCreateItem) {
    return <Content />;
  }

  if (columnCount === 1) {
    return <FixedHeight className={ cls('ctas', 'fixed')}>
      <div className={classNames({
        [cls('ctas', 'mobile')]: true,
      })}>
        <Content />
      </div>
    </FixedHeight>;
  }

  return <FixedHeight><Content /></FixedHeight>;
};

export default CreateBanner;
