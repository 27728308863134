// libs
import classNames from 'classnames';
import * as React from 'react';
import { CLASS_PREFIX } from 'src/constants/';

// classes
import Button, {
  BUTTON_TYPE_CONTAINED,
  BUTTON_TYPE_TEXT,
} from 'src/components/forms/button/button';
import FieldSet, {
  FIELDSET_TYPE_SMALL_SPACE,
  FieldSetType,
} from 'src/components/forms/field_set/field_set';
// helpers
import { textResources } from 'src/lang/de';
import {
  COLOR_TYPE_DANGER,
  COLOR_TYPE_PRIMARY,
  COLOR_TYPE_PRIMARY_2,
  ColorType,
} from 'src/utils/color';
import './message_box.scss';

export const MSGBOX_BUTTON_TYPE_DANGER = 'DANGER';
export const MSGBOX_BUTTON_TYPE_SECONDARY = 'SECONDARY';
export const MSGBOX_BUTTON_TYPE_PRIMARY = 'PRIMARY';
export const MSGBOX_BUTTON_TYPE_CANCEL = 'CANCEL';

type MessageBoxButtonType = 'CANCEL' | 'DANGER' | 'PRIMARY' | 'SECONDARY';

type ButtonDefaultsType = {
  [key in MessageBoxButtonType]: {
    color?: ColorType;
    label?: string;
  };
};

const buttonDefaults: ButtonDefaultsType = {
  CANCEL: {
    label: textResources.shared.cancel,
  },
  DANGER: {
    color: COLOR_TYPE_DANGER,
    label: textResources.shared.delete,
  },
  PRIMARY: {
    color: COLOR_TYPE_PRIMARY,
  },
  SECONDARY: {
    color: COLOR_TYPE_PRIMARY_2,
  },
};

export interface IMessageBoxButton {
  label?: string;
  onClick?: () => void;
  type: MessageBoxButtonType;
  className?: string;
}

export interface IMessageBoxProps {
  buttons?: IMessageBoxButton[];
  bodyTitle?: string;
  title: string;
  highlighted?: boolean;
  rounded?: boolean;
  light?: boolean;
  type?: FieldSetType;
}

export const cls: string = CLASS_PREFIX + 'message-box';

const MSGBoxButton = (props: IMessageBoxButton) => (
  <Button
    className={cls + '__button'}
    color={buttonDefaults[props.type].color}
    fullWidth
    label={props.label ? props.label : buttonDefaults[props.type].label}
    lowerCase
    onClick={props.onClick}
    variant={props.type === MSGBOX_BUTTON_TYPE_CANCEL ? BUTTON_TYPE_TEXT : BUTTON_TYPE_CONTAINED}
  />
);

const MessageBox: React.FC<IMessageBoxProps> = ({
  buttons,
  children,
  bodyTitle,
  title,
  highlighted = true,
  rounded,
  light,
  type = FIELDSET_TYPE_SMALL_SPACE,
}) => {
  const clsBlock = {
    [cls]: true,
    [cls + '--highlighted']: highlighted,
    [cls + '--rounded']: rounded,
    [cls + '--light']: light,
  };
  return (
    <div className={classNames(clsBlock)}>
      <header className={cls + '__header'}>
        <h2 className={cls + '__title'}>{title}</h2>
      </header>
      {bodyTitle && <div className={cls + '__body-title'}>{bodyTitle}</div>}
      {children && <div className={cls + '__body-text'}>{children}</div>}
      {buttons &&
      <div className={cls + '__footer'}>
        <FieldSet type={type}>
          {buttons.map((val, i) => <MSGBoxButton key={i} {...val} />)}
        </FieldSet>
      </div>}
    </div>
  );
};

export default MessageBox;
