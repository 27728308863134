// libs
import classNames from 'classnames';
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants';

// classes
import Badge from 'src/components/badge/badge';
import Icon, { IconSize } from 'src/components/forms/icon/icon';
import NavigationItem from 'src/components/navigation_item/navigation_item';
import UserOnboardingCTA from 'src/containers/smart_components/user_onboarding_cta/user_onboarding_cta';

// helpers
import { textResources } from 'src/lang/de';
import { COLOR_TYPE_GRAY_TINT_9 } from '../../utils/color';
import './tab_item.scss';

export interface ITabItem {
  isActive: boolean;
  isInvisible?: boolean;
  isLocked?: boolean;
}

const cls: string = CLASS_PREFIX + 'tab-item';
const labels = textResources.header;

const TabItem: React.FC<ITabItem> = ({
  isActive,
  isInvisible,
  isLocked,
  children,
}) => {
  if (isInvisible) {
    return null;
  }

  const isTabLocked = isLocked || false;

  const clsBlock = {
    [cls]: true,
    [cls + '--active']: isActive,
  };

  return (
    <div className={classNames(clsBlock)}>
      <UserOnboardingCTA active={isTabLocked}>
        {children}
      </UserOnboardingCTA>
    </div>
  );
};

interface ITabItemButton extends ITabItem {
  onClick?: () => void;
  textLabel: string;
}

export const TabItemButton: React.FC<ITabItemButton> =
  ({ onClick, textLabel, isActive, isInvisible, isLocked }) => {
    return (
      <TabItem
        isActive={isActive}
        isLocked={isLocked}
        isInvisible={isInvisible}
      >
        <button className={cls + '__link'} onClick={onClick}>
          {textLabel}
        </button>
      </TabItem>
    );
  };

interface ITabItemLink extends ITabItem {
  badge?: boolean;
  icon?: string;
  badgeNumber?: number;
  onClick?: () => void;
  onActiveClick?: () => void;
  textLabel: string;
  url?: string;
}

const iconDescription: { [key: string]: { label: string; size: IconSize } } = {
  'event': { label: labels.events, size: 25 },
  'groups': { label: labels.groups, size: 16 },
  'newsfeed': { label: labels.mainPage, size: 25 },
  'notification': { label: labels.notifications, size: 24 },
  'search-profile': { label: labels.profilesDirectory, size: 24 },
};

export const TabItemLink: React.FC<ITabItemLink> =
  ({ textLabel, badge, badgeNumber, url, icon, isActive, isLocked, onActiveClick, isInvisible }) => (
    <TabItem
      isActive={isActive}
      isLocked={isLocked}
      isInvisible={isInvisible}
    >
      <NavigationItem
        className={cls + '__link'}
        target='_self'
        url={url}
        onClick={isActive ? onActiveClick : undefined}
      >
        {icon &&
        <div className={cls + '__icon'}>
          <Icon
            name={icon}
            description={iconDescription[icon].label}
            size={iconDescription[icon].size}
            color={COLOR_TYPE_GRAY_TINT_9}
          />
          {badge && <Badge
            value={badgeNumber}
            size={'medium'}
            className={cls + '__badge'}
            type='notification'
          />}
        </div>
        }
        <span className={cls + '__text'}>{textLabel}</span>
      </NavigationItem>
    </TabItem>
  );
