// interfaces & constants
import {
  IGroupParticipation,
  IGroupParticipationsResponseMeta,
} from 'src/api/interfaces/responses/group';
import * as Response from 'src/api/interfaces/responses/profile';
import { PRIVATE_PROFILE_TYPE } from 'src/constants/profile';
import { IGroupListProfile, IProfile, IProfilePayload } from 'src/interfaces/profile';

// decoders
import { $locationShape } from 'src/api/decoders/location_shape';
import { $profileMarkers } from 'src/api/decoders/marker';
import { $pagination } from 'src/api/decoders/pagination';
import { $profileTags } from 'src/api/decoders/tags';
import {
  IProfilesCategorizedResponse,
  IProfilesCategory,
  IProfilesResponse,
  IShapeSponsorsResponse,
  IShapeSponsorResponse,
} from 'src/api/interfaces/responses/profiles_list';
import { Id } from 'src/interfaces/';
import {
  IGroupedProfilesList, IProfilesInGroupListCategory,
  IProfilesListCategory,
} from 'src/interfaces/profiles_list';

// helpers
import { IShapeSponsor } from 'src/interfaces/shape_sponsors';
import Translate from 'src/lang/de';
import { UrlUtils } from 'src/utils/url/url';

const { authorTypeCategoryTitle } = Translate;

export const $profileResponse = (json: Response.IProfileResponse): IProfilePayload => ({
  profile: $profile(json.data),
});

export const $questionsAnswers = (json: Response.IQuestionAnswer[] | undefined) => ({
  questionsAnswers: json ? json.map(({ answer, question, questionId }) => {
    const actualAnswer = answer?.[0];
    return {
      answer: actualAnswer ? actualAnswer.text : null,
      question,
      questionId,
    };
  }) : [],
});

export const $profile = (json: Response.IProfile): IProfile => {
  const profile: IProfile = {
    description: json.description,
    gid: json.gid,
    id: json.global_friendly_identifier,
    imageUrl: json.avatar.image_urls.square_small,
    locationShape: json.location_shape ? $locationShape(json.location_shape) : undefined,
    markers: [],
    member_of: json.member_of,
    meta: {
      blacklistsMe: json.meta.blacklists_me,
      fenced: !!json.meta.fenced,
      isBlacklistable: json.meta.is_blacklistable,
      isBlacklisted: json.type === PRIVATE_PROFILE_TYPE
        ? !!json.meta.is_blacklisted : false,
      isBookmarkable: json.meta.is_bookmarkable,
      isFollowable: !!json.meta.is_followable,
      isFollowed: !!json.meta.is_followed,
      isFollowing: !!json.meta.is_following,
      isGraylistable: !!json.meta.is_graylistable,
      isGraylisted: !!json.meta.is_graylisted,
      isMessagable: !!json.meta.is_messagable,
      isOwn: !!json.meta.is_own,
      isReadable: !!json.meta.is_readable,
      notifiesMe: !!json.meta.notifies_me,
    },
    name: json.name,
    permissions: {
      canBeFollowed: !!json.meta.permissions?.can_be_followed,
      canBlacklist: json.type === PRIVATE_PROFILE_TYPE
        ? !!json.meta.is_blacklistable : false,
      canGraylist: !!json.meta.is_graylistable,
      canMessage: !!json.meta.permissions?.can_message,
      canViewDetails: json.type === PRIVATE_PROFILE_TYPE
        ? !!json.meta.is_readable : true,
    },
    profileLinks: json.profile_links,
    ...$questionsAnswers(json.questions_answers),
    siteNotice: json.site_notice,
    sponsorLevel: json.sponsor_level || undefined,
    tags: json.taggings && $profileTags(json.taggings),
    type: json.type,
    urls: json.urls ? {
      blacklist: json.urls.blacklist,
      graylist: json.urls.graylist,
      sendMessage: json.urls.send_message,
    } : {},
    website: json.website,
    isMessengerEnabled: json.set_messenger_availability === undefined ? true : json.set_messenger_availability,
  };

  profile.markers = $profileMarkers(profile, json.markers);

  return profile;
};

export const $groupParticipant = (json: IGroupParticipation): IGroupListProfile => {
  // Do not add actions for open group and for admin
  const shouldHaveGroupAction = json.group.accessibility === 'closed' && json.role === 'member';
  return ({
    ...$profile(json.participant),
    isConfirmedParticipant: json.meta.is_confirmed,
    urls: json.urls,
    canAccept: shouldHaveGroupAction && json.meta.permissions.can_confirm,
    canReject: shouldHaveGroupAction && json.meta.permissions.can_eliminate,
    canRemove: shouldHaveGroupAction && json.meta.permissions.can_eliminate,
  });
};

export const getNormalizedProfiles = (profiles: Response.IProfile[]): { ids: Id[]; profiles: IProfile[] } => {
  const decodedProfiles = profiles.map($profile);
  return {
    ids: decodedProfiles.map(({ id }) => id),
    profiles: decodedProfiles,
  };
};

export const getNormalizedGroupParticipants = (
  profiles: IGroupParticipation[]
): { ids: Id[]; profiles: IGroupListProfile[] } => {
  const decodedProfiles = profiles.map($groupParticipant);
  return {
    ids: decodedProfiles.map(({ id }) => id),
    profiles: decodedProfiles,
  };
};

export const $profiles = (json: Pick<IProfilesResponse, 'data' | 'meta'>): IProfilesListCategory => {
  const { profiles, ids } = getNormalizedProfiles(json.data);

  return {
    groups: [{ ids }],
    pagination: $pagination(json.meta),
    profiles,
  };
};

export const $profilesInGroup = (
  json: { data: IGroupParticipation[]; meta: IGroupParticipationsResponseMeta }
): IProfilesInGroupListCategory => {
  const { profiles, ids } = getNormalizedGroupParticipants(json.data);

  return {
    groups: [{ ids }],
    pagination: $pagination(json.meta),
    profiles,
  };
};

export const $categorizedProfiles = (json: IProfilesCategorizedResponse): IProfilesListCategory => {
  let profiles: IProfile[] = [];
  const groups: IGroupedProfilesList = json.data
    .map((category: IProfilesCategory) => {
      const { profiles: decodedProfiles, ids } = getNormalizedProfiles(category.items);

      profiles = profiles.concat(decodedProfiles);

      return {
        ids,
        label: authorTypeCategoryTitle[category.extension_type],
        slug: UrlUtils.mapAuthorTypeToUrlParam(category.extension_type),
      };
    });

  return {
    groups,
    profiles,
  };
};

const $shapeSponsor = (json: IShapeSponsorResponse): IShapeSponsor => {
  return {
    level: json.level,
    placements: {
      detailPage: json.placements.details_page,
    },
    sponsorId: $profile(json.sponsor).id,
  };
};

export const $shapeSponsors = (json: IShapeSponsorsResponse): IProfilesListCategory & {sponsors: IShapeSponsor[]} => {
  if (!json.data) {
    return {
      groups: [],
      profiles: [],
      sponsors: [],
    };
  }

  const sponsors = json.data.map($shapeSponsor);
  const profiles = json.data.map(sponsor => $profile(sponsor.sponsor));

  return {
    groups: [],
    profiles,
    sponsors: sponsors,
  };
};
