import classNames from 'classnames';
import * as React from 'react';
import { PageTitlePossibleTags } from '../../../../containers/smart_components/page_title/page_title';
import { COLOR_TYPE_GRAY_TINT_9 } from '../../../../utils/color';
import Icon from '../../../forms/icon/icon';
import Logo from '../../../logo/logo';
import './page_title_content.scss';

interface IProps {
  customTag?: PageTitlePossibleTags;
  isHome?: boolean;
  location?: string;
  showLocation?: boolean;
  text: string;
  cls: string;
}

export const PageTitleContent: React.FunctionComponent<IProps> = ({
  customTag,
  location,
  text,
  isHome,
  showLocation,
  cls,
}) => {
  const Tag = customTag || 'h1';
  return (
    <div className={`${cls}__container`}>
      <Tag className={cls}>
        <span className={cls + '__page-name'}>
          {text}
        </span>
        {showLocation &&
          <span className={cls + '__location'}>
            <span className={cls + '__icon'}>
              {isHome
                ? <span className={cls + '__logo'}><Logo type={'NO_TEXT'}/></span>
                : <Icon name='nav-pin-filled' size={10} color={COLOR_TYPE_GRAY_TINT_9}/>}
            </span>
            {location}
          </span>
        }
      </Tag>
      <div className={classNames(`${cls}__logo`)}>
        <Logo type={'HORIZONTAL'}/>
      </div>
    </div>
  );
};
