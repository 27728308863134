// libs
import React, { useMemo, useCallback, useState } from 'react';

// interfaces & constants
import { FRONTEND_SETTINGS_PATH } from 'src/constants/urls';
import {
  NO_PROFILE,
  PROFILE_STEP_PROFILE_COMPLETE,
  PROFILE_STEP_EMAIL_CONFIRMED,
  PROFILE_STEP_VERIFIED,
} from 'src/constants/user';

// components
import {
  ICTAContent,
  renderFencedRegistration,
  ctaCompleteProfile,
  ctaConfirmEmail,
  ctaVerifyProfile,
} from 'src/components/cta/cta_intermediate_options';
import MessageBox from 'src/components/message_box/message_box';
import SnackBar from 'src/components/snack_bar/snack_bar';

// helpers
import { useHistory } from 'react-router-dom';
import { useShowLoginModal, useShowRegistrationModal } from 'src/actions/app-state/app-state_hooks';
import api from 'src/api';
import { textResources } from 'src/lang/de';
import { useSelectProfileStatus, useSelectUserMail } from 'src/reducers/user/user_hooks';
import { createBemBlock } from 'src/utils/bem_helper/bem_helper';
import initTracker, { EMAIL_NOT_RECEIVED } from 'src/utils/reporting/events_tracking/events_tracking';

import './content_blocker_info.scss';

export type Reasons = typeof NO_PROFILE |
  typeof PROFILE_STEP_PROFILE_COMPLETE |
  typeof PROFILE_STEP_EMAIL_CONFIRMED |
  typeof PROFILE_STEP_VERIFIED;

interface ICustomReason {
  condition: boolean;
  content: ICTAContent;
}

type Contents = Partial<ICTAContent>;

interface IProps {
  customReasons?: ICustomReason[];
  customContents?: Partial<Record<Reasons, Contents | undefined>>;
  layout?: 'modal' | 'inPage';
  onClose?: () => void;
}

const sharedLabels = textResources.shared;

const cls = createBemBlock('content-blocker-info');

const trackerEmailNotReceived = initTracker(EMAIL_NOT_RECEIVED);

export const sendEmailAgain = (callBack: (message: string) => void) => {
  trackerEmailNotReceived(EMAIL_NOT_RECEIVED.ACTIONS.SEND_EMAIL_AGAIN, EMAIL_NOT_RECEIVED.LABELS.START);
  api.profile.resendConfirmationEmail()
    .then(() => {
      callBack(textResources.onboardingSettings.sentMail);
    })
    .catch(() => {
      trackerEmailNotReceived(EMAIL_NOT_RECEIVED.ACTIONS.SEND_EMAIL_AGAIN, EMAIL_NOT_RECEIVED.LABELS.FAIL);
      callBack(sharedLabels.errorUnknown);
    });
};

/*
 A component that tries to inform the user why content is blocked for him
 and what he can do to see this content
 */
const ContentBlockerInfo: React.FC<IProps> = ({
  customReasons,
  customContents,
}) => {
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const profileStatus = useSelectProfileStatus();
  const userEmail = useSelectUserMail();
  const history = useHistory();

  const showLogin = useShowLoginModal();
  const showRegistration = useShowRegistrationModal();

  const goToContinueOnboarding = useCallback(() => {
    history.push(FRONTEND_SETTINGS_PATH);
  }, [history]);

  const options = useMemo<ICTAContent | undefined>(() => {
    switch (profileStatus) {
      case NO_PROFILE:
        return renderFencedRegistration(showRegistration, showLogin, customContents);
      case PROFILE_STEP_PROFILE_COMPLETE:
        return ctaCompleteProfile(goToContinueOnboarding, customContents);
      case PROFILE_STEP_EMAIL_CONFIRMED:
        return ctaConfirmEmail(() => sendEmailAgain(setSnackbarMessage), userEmail, customContents);
      case PROFILE_STEP_VERIFIED:
        return ctaVerifyProfile(goToContinueOnboarding, customContents);
      default:
        const firstCustomReason = customReasons?.find(({ condition }) => !!condition);
        return firstCustomReason?.content;
    }
  }, [profileStatus, customReasons, showRegistration, showLogin,
    customContents, goToContinueOnboarding, userEmail]);

  if (!options) {
    return null;
  }

  const closeSnackbar = () => {
    setSnackbarMessage('');
  };

  return (
    <div className={cls(undefined, 'in-page')}>
      <MessageBox
        title={options.title || ''}
        bodyTitle={options.bodyTitle}
        highlighted={false}
        buttons={options.buttons}
        rounded
      >
        {options.body}
      </MessageBox>
      {snackbarMessage &&
        <SnackBar message={snackbarMessage} onClose={closeSnackbar} />}
    </div>
  );
};

export default ContentBlockerInfo;
