// libs
import classNames from 'classnames';
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';

// classes
import Icon from 'src/components/forms/icon/icon';

// helpers
import { COLOR_TYPE_DEFAULT, ColorType } from 'src/utils/color';
import { logToDevConsole } from 'src/utils/reporting/report-errors';
import './button.scss';

export const BUTTON_TYPE_ROUND = 'round';
export const BUTTON_TYPE_ROUNDMID = 'round-mid';
export const BUTTON_TYPE_ROUNDMICRO = 'round-micro';
export const BUTTON_TYPE_TEXT = 'text';
export const BUTTON_TYPE_CONTAINED = 'contained';
export const BUTTON_TYPE_CROPPED = 'cropped';

export type BtnVariant = 'round' | 'round-mid' | 'round-micro' | 'text' | 'contained' | 'cropped';
export type IBtnType = 'button' | 'submit' | 'reset';

type IBtnCssTypes = {readonly[K in BtnVariant]: string};

export interface IProps {
  className?: string;
  color?: ColorType;
  disabled?: boolean;
  fullWidth?: boolean;
  variant?: BtnVariant;
  icon?: string;
  id?: string;
  label?: string;
  onClick?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
  title?: string;
  type?: IBtnType;
  lowerCase?: boolean;
  backgroundImage?: string; // NB: please only use this with ROUND type buttons for now
  badge?: JSX.Element; // added for neighbor-chat poc please improve if needed somwhere else
  primary?: boolean;
}

const clsBlock: string = CLASS_PREFIX + 'button';

const btnCssClasses: IBtnCssTypes = {
  [BUTTON_TYPE_CONTAINED]: `${clsBlock}--${BUTTON_TYPE_CONTAINED}`,
  [BUTTON_TYPE_CROPPED]: `${clsBlock}--${BUTTON_TYPE_CROPPED}`,
  [BUTTON_TYPE_ROUND]: `${clsBlock}--${BUTTON_TYPE_ROUND}`,
  [BUTTON_TYPE_ROUNDMICRO]: `${clsBlock}--${BUTTON_TYPE_ROUND} ${clsBlock}--${BUTTON_TYPE_ROUNDMICRO}`,
  [BUTTON_TYPE_ROUNDMID]: `${clsBlock}--${BUTTON_TYPE_ROUND} ${clsBlock}--${BUTTON_TYPE_ROUNDMID}`,
  [BUTTON_TYPE_TEXT]: `${clsBlock}--${BUTTON_TYPE_TEXT}`,
};

export default class Button extends React.PureComponent<IProps, any> {
  constructor(props: IProps) {
    super(props);
    const { label, variant } = props;

    const isRound =
      variant === BUTTON_TYPE_ROUND ||
      variant === BUTTON_TYPE_ROUNDMID ||
      variant === BUTTON_TYPE_ROUNDMICRO;

    if (isRound && label) {
      logToDevConsole(`label - ${label} - cannot work if isRound: ${isRound}`);
    }
  }

  public render() {
    const {
      backgroundImage,
      className,
      fullWidth,
      icon,
      id,
      label,
      disabled,
      color,
      variant,
      onClick,
      title,
      type,
      lowerCase,
      badge,
      primary,
    } = this.props;

    const btnColor = color || COLOR_TYPE_DEFAULT;
    const btnColorClass = `${clsBlock}--${btnColor}`;
    const defaultVariant = btnCssClasses[variant || BUTTON_TYPE_TEXT];

    const cls = classNames({
      [btnColorClass]: !backgroundImage,
      [clsBlock]: true,
      [`${btnColorClass}--primary`]: primary,
      [`${clsBlock}--disabled`]: disabled,
      [`${clsBlock}--fullwidth`]: fullWidth,
      [`${className}`]: !!className,
      [defaultVariant]: true,
    });

    const textCls = classNames({
      [clsBlock + '__text']: true,
      [clsBlock + '__text--uppercase']: !lowerCase,
    });

    return (
      <button
        className={`${cls} ${this.props.className}`}
        disabled={disabled}
        id={id}
        onClick={onClick}
        title={label ? undefined : title}
        aria-label={title}
        type={type}
      >
        {icon && <Icon name={icon} />}
        {label && <span className={textCls}>{label}</span>}
        {backgroundImage && <img className={`${clsBlock}__image`} src={backgroundImage} alt={label || title} />}
        {badge}
      </button>
    );
  }
}
