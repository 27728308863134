// libs
import * as React from 'react';

// interfaces / constants
import { itemSegmentCls } from 'src/components/item_segment/base/item_segment_base';

// classes
import Avatar, { IAvatarBadge } from 'src/components/avatar/avatar';

// helpers
import { THUMBNAIL_SIZE_TYPE_SMALL, ThumbnailSizeType } from '../../../thumbnail/thumbnail';
import './segment_avatar.scss';

export interface IProps {
  imageUrl?: string;
  badge?: IAvatarBadge;
  size?: ThumbnailSizeType;
  rounded?: boolean;
  withBorder?: boolean;
}

const cls: string = itemSegmentCls + 'avatar';

const SegmentAvatar: React.FC<IProps> = (
  {
    imageUrl,
    badge,
    size = THUMBNAIL_SIZE_TYPE_SMALL,
    rounded = true,
    withBorder,
  }) => (
  <div className={cls}>
    <Avatar image={imageUrl} badge={badge} size={size} rounded={rounded} withBorder={withBorder} />
  </div>
);

export default SegmentAvatar;
