import { throttle } from 'lodash';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { CLASS_PREFIX } from '../../../../constants';
import PostRecommendationMobile
  from '../../../../containers/smart_components/post_recommendation_mobile/post_recommendation_mobile';
import { useResizeListener } from '../../../../hooks_shared/use_event_listener';
import { readColumnCountFromDOM } from '../../../../utils/column_count';
import './custom_create_item.scss';

const cls = CLASS_PREFIX + 'custom-create-item';

const CustomCreateItem: FunctionComponent = ({ children }) => {
  const [columnCount, setColumnCount] = useState(() => readColumnCountFromDOM());
  const listener = useCallback(
    throttle(() => {
      setColumnCount(readColumnCountFromDOM());
    }, 100),
    [],
  );

  useEffect(() => {
    setColumnCount(readColumnCountFromDOM());
  }, []);

  useResizeListener(listener, { passive: true });
  if (columnCount !== 1) {
    return <>{children}</>;
  }
  return <div className={cls}>
    <div style={{
      flex: 3,
    }}>
      {children}
    </div>
    <PostRecommendationMobile />
  </div>;
};

export default CustomCreateItem;
