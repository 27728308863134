// libs
import classNames from 'classnames';
import * as React from 'react';

// classes
import LoadingSpinner from 'src/components/loading_spinner/loading_spinner';
import Popover from 'src/components/popover/popover';

// interfaces / constants
import { IRenderAPI } from 'src/components/_libs/popover_lib/popover_lib';
import { CLASS_PREFIX } from 'src/constants/';
import Icon from '../forms/icon/icon';

import { COLOR_TYPE_GRAY_TINT_9 } from '../../utils/color';
import './emoji_popup.scss';

const EmojiPicker = React.lazy(() =>
  import(/* webpackChunkName: "emoji-picker" */ 'src/components/emoji_popup/emoji_picker'));

interface IProps {
  disabled?: boolean;
  onEmojiSelected: (nativeEmoji: string) => void;
  isInputInvalid?: boolean;
}

const clsBlock: string = CLASS_PREFIX + 'emoji-popup';

const onEmojiSearchFocus = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => event.stopPropagation();

export default class InputField extends React.PureComponent<IProps, {}> {
  constructor(props: IProps) {
    super(props);
    this.renderPopoverChildren = this.renderPopoverChildren.bind(this);
  }

  public render() {
    const triggerClasses = {
      [clsBlock + '__trigger']: true,
      [clsBlock + '__trigger--hide']: this.props.isInputInvalid,
    };
    return (
      <div className={clsBlock} onClick={onEmojiSearchFocus}>
        <Popover
          disabled={this.props.disabled}
          trigger={
            <div className={classNames(triggerClasses)}>
              <Icon name={'emoji'}
                size={22}
                color={COLOR_TYPE_GRAY_TINT_9}
              />
            </div>}
          position={'top'}
          align={'right'}
          layout={'default'}
        >{this.renderPopoverChildren}
        </Popover>
      </div>
    );
  }

  private renderPopoverChildren(renderProps: IRenderAPI) {
    if (renderProps.isOpen) {
      return (
        <React.Suspense fallback={<LoadingSpinner shown/>}>
          <EmojiPicker
            onEmojiSelected={this.props.onEmojiSelected}
            {...renderProps}
          />
        </React.Suspense>
      );
    } else {
      return null;
    }
  }
}
