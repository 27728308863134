import classNames from 'classnames';
import React, { FunctionComponent, useMemo } from 'react';
import { CLASS_PREFIX } from 'src/constants';
import { IProfileAnswer } from '../../../../interfaces/profile';
import { getAnswersStatuses } from './progress-helper';
import './progressbar.scss';

const cls = CLASS_PREFIX + 'answers-progress';
const clsAnswer = cls + '__answer';

interface IProps {
  answers: IProfileAnswer[] | undefined;
}

const AnswersProgressBar: FunctionComponent<IProps> = ({ answers }) => {
  const statuses = useMemo(() => getAnswersStatuses(answers), [answers]);
  return <div className={cls}>
    {statuses.map((answer, index) =>
      <div key={index} className={classNames(clsAnswer, `${clsAnswer}--${answer}`)}/>)}
  </div>;
};

export default AnswersProgressBar;
