import { ProfileType } from '../../../../../../interfaces/profile';
import { IUserProfile } from '../../../../../../interfaces/user';
import { getConfigValue } from '../../../../../../utils/config/config';

export const getMessengerToggle = (type: ProfileType) =>
  isMessengerFlagEnabled() && type === 'private_profile';
export const isMessengerFlagEnabled = () => getConfigValue<boolean>('plugins.messengerToggle.enabled');

export const isUserMessagable = ({ type, isMessengerEnabled }: IUserProfile) => {
  if (type === 'private_profile') {
    return isMessengerEnabled;
  }

  return true;
};
