import React, { useEffect, useState } from 'react';
import api from '../../../api';
import NavigationItem from '../../../components/navigation_item/navigation_item';
import { CLASS_PREFIX } from '../../../constants';
import { IPost } from '../../../interfaces/posts';
import { textResources } from '../../../lang/de';
import FixedHeight from '../fixed_height/fixed_height';
import './post_recommendation.scss';

const cls = CLASS_PREFIX + 'post-recommendation';
const PostRecommendation = () => {
  const [recommendedPost, setRecommendedPost] = useState<IPost>();

  useEffect(() => {
    api.postRecommendation.get().then(recommendation => {
      if (recommendation) {
        setRecommendedPost(recommendation.post);
      }
    });
  }, []);

  return recommendedPost
    ? <FixedHeight>
      <NavigationItem url={recommendedPost.urls.frontend}>
        <div className={cls}>
          <img className={`${cls}__image`}
            src={recommendedPost.featuredImage}
            alt={recommendedPost.ingredients.title?.data.title as unknown as string}
          />
          <div className={`${cls}__texts`}>
            <div className={`${cls}__headline`}>{textResources.postRecommendation.title}</div>
            <hr/>
            <div className={`${cls}__teaser`}>{recommendedPost.ingredients.title?.data.title}</div>
          </div>
        </div>
      </NavigationItem>
    </FixedHeight>
    : null;
};

export default PostRecommendation;
