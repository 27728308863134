// lib
import React from 'react';

// components
import ItemSegmentBase, { BIG_ITEM_SEGMENT, itemSegmentCls } from 'src/components/item_segment/base/item_segment_base';
import ItemSegmentLink, { IProps as ILinkProps } from 'src/components/item_segment/base/item_segment_link';
import ItemSegmentButton from 'src/components/item_segment/parts/segment_button/segment_button';
import ItemSegmentIcon from 'src/components/item_segment/parts/segment_icon/segment_icon';
import ItemSegmentRightArrow from 'src/components/item_segment/parts/segment_right_arrow/segment_right_arrow';
import ItemSegmentRoundIcon from 'src/components/item_segment/parts/segment_round_icon/segment_round_icon';
import ItemSegmentText from 'src/components/item_segment/parts/segment_text/segment_text';
import { textResources } from 'src/lang/de';

// constants/interfaces
import { COLOR_TYPE_GRAY_TINT_9, COLOR_TYPE_GRAY_TINT_10, COLOR_TYPE_GRAY_TINT_11 } from 'src/utils/color';
import { GroupAccessibility } from '../../../../api/interfaces/responses/group';

export type GroupIconType = 'admin' | 'join' | 'pending';

interface IProps {
  accessibility: GroupAccessibility;
  label: string;
  description?: string;
  groupIconType?: GroupIconType;
  navigation?: ILinkProps;
  onJoin?: () => void;
  canJoinGroup?: boolean;
  lastPostAt: string;
  lastPostAtMobile: string;
  className?: string;
  customIcon?: React.ReactNode;
}

const getIconByAccessibility = (accessibility: GroupAccessibility) => {
  if (accessibility === 'closed') {
    return <ItemSegmentRoundIcon
      name={'padlock'}
      color={COLOR_TYPE_GRAY_TINT_9}
      backgroundColor={COLOR_TYPE_GRAY_TINT_11}
      customSize={18}
      isInverted
      isSmall
      noHover
      isLightRadius={true}
    />;
  }

  if (accessibility === 'open') {
    return <ItemSegmentRoundIcon
      name={'plus'}
      color={COLOR_TYPE_GRAY_TINT_9}
      backgroundColor={COLOR_TYPE_GRAY_TINT_11}
      isSmall
      isInverted
      isLightRadius={true}
    />;
  }

  return null;
};

/*
  A Wrapper for ItemSegment for the group-list
*/
const GroupItemSegment: React.FC<IProps> =
  ({
    description,
    accessibility,
    label,
    navigation,
    groupIconType,
    onJoin,
    canJoinGroup,
    lastPostAt,
    lastPostAtMobile,
    className,
    customIcon,
  }) => {
    if (groupIconType === 'pending') {
      return <ItemSegmentBase className={className || itemSegmentCls} height={BIG_ITEM_SEGMENT} rounded noSpacing>
        <ItemSegmentLink>
          <ItemSegmentText label={label}
            labelSize='small'
            description={description}
            additionalLabel={lastPostAt}
            descriptionMobile={lastPostAtMobile}
          />
          <ItemSegmentRoundIcon
            noHover
            color={COLOR_TYPE_GRAY_TINT_9}
            backgroundColor={COLOR_TYPE_GRAY_TINT_11}
            isSmall
            isInverted
            name='pending'
            isLightRadius={true}
          />
        </ItemSegmentLink>
      </ItemSegmentBase>;
    }
    return (
      <ItemSegmentBase className={className || itemSegmentCls} height={BIG_ITEM_SEGMENT} rounded noSpacing>
        <ItemSegmentLink {...navigation} >
          <ItemSegmentText label={label}
            labelSize='small'
            description={description}
            additionalLabel={lastPostAt}
            descriptionMobile={lastPostAtMobile}
          />
          {groupIconType === 'admin' &&
            <ItemSegmentIcon
              name='admin'
              color={COLOR_TYPE_GRAY_TINT_10}
              description={textResources.screenReaderText.administrator} />
          }
          {groupIconType === 'join' &&
            <ItemSegmentButton onClick={onJoin} featureBlocked={!canJoinGroup}>
              {getIconByAccessibility(accessibility)}
            </ItemSegmentButton>}
          {customIcon || <ItemSegmentRightArrow />}
        </ItemSegmentLink>
      </ItemSegmentBase>
    );
  };

export default GroupItemSegment;
