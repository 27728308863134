// libs
import classNames from 'classnames';
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';

// helpers
import './item_segment_base.scss';

export const SMALL_ITEM_SEGMENT = 'small';
export const SMALLER_ITEM_SEGMENT = 'smaller';
export const XSMALL_ITEM_SEGMENT = 'xsmall';
export const BIG_ITEM_SEGMENT = 'big';

export const XBIG_ITEM_SEGMENT = 'xbig';

export const XXBIG_ITEM_SEGMENT = 'xxbig';

export type ItemSegmentHeight = typeof XXBIG_ITEM_SEGMENT
  | typeof XBIG_ITEM_SEGMENT
  | typeof BIG_ITEM_SEGMENT
  | typeof SMALL_ITEM_SEGMENT
  | typeof SMALLER_ITEM_SEGMENT
  | typeof XSMALL_ITEM_SEGMENT
  | null

interface IProps {
  height: ItemSegmentHeight;
  className: string;
  noBorder?: boolean;
  title?: string;
  noSpacing?: boolean;
  rounded?: boolean;
  forceTopBorder?: boolean;
}

export const itemSegmentCls = CLASS_PREFIX + 'item-segment-';
const cls: string = itemSegmentCls + 'base';

/*
  This component should only be used in an Item Segment Layout file
  And contain one or several Item Segment Links as children
 */

const ItemSegmentBase: React.FC<IProps> = ({
  children,
  height,
  className,
  noBorder,
  forceTopBorder,
  title,
  noSpacing,
  rounded }) => {
  const classes = {
    [className]: true,
    [cls]: true,
    [cls + '--' + height]: true,
    [cls + '--no-border']: noBorder,
    [cls + '--no-spacing']: noSpacing,
    [cls + '--rounded']: rounded,
    [cls + '--force-border']: forceTopBorder,
  };

  return <div className={classNames(classes)} title={title}>{children}</div>;
};

export default ItemSegmentBase;
