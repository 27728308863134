// libs
import classNames from 'classnames';
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';

// classes

// helpers
import { ColorType } from 'src/utils/color';
import './icon.scss';

const cls = CLASS_PREFIX + 'icon';

export type IconSize = 8 | 10 | 12 | 14 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24 | 25 | 26
| 28 | 30 | 32 | 36 | 40 | 44 | 48 | 55 | 56 | 160;

interface IProps {
  color?: ColorType;
  name: string;
  size?: IconSize;
  description?: string;
  className?: string;
  onClick?: (e?: React.SyntheticEvent<HTMLElement>) => void;
}

const Icon: React.FC<IProps> = ({ color, name, size, className, description, ...props }) => {
  const colorCls = color && `${cls}__color--${color}` || '';
  const sizeCls = `${cls}__size` + (size ? ` ${cls}__size--${size}` : '');
  const nameCls = `${cls}__${name}`;
  return (
    <>
      <i
        aria-hidden='true'
        className={classNames([className, nameCls, sizeCls, colorCls])}
        title={description && description}
        {...props}
      />
      {description && <span className={cls + '__icon-text'}>{description}</span>}
    </>
  );
};

export default Icon;
