import classNames from 'classnames';
import React from 'react';
// components
import GroupItemSegment, { GroupIconType } from 'src/components/item_segment/layouts/group/group';

// interfaces
import { IGroup } from 'src/interfaces/group';

// helpers
import { UrlUtils } from 'src/utils/url/url';
import DateHelper from '../../../../utils/date_helper/date';

import './group_item.scss';
import { getLastPostAtTexts } from './helpers';

interface IGroupItemProps {
  group: IGroup;
  joinGroup?: (group: IGroup) => void;
  displayLastPostAt: boolean;
}

const getGroupIconType = (group: IGroup): GroupIconType | undefined => {
  if (group.isAdmin) {
    return 'admin';
  }
  if (!group.isMember) {
    if (group.isParticipating) {
      return 'pending';
    }
    return 'join';
  }
  return undefined;
};

const GroupItem: React.FC<IGroupItemProps> = ({ group, joinGroup, displayLastPostAt }) => {
  const join = () => {
    joinGroup && joinGroup(group);
  };

  const isLastPostAt = displayLastPostAt &&
    !!group.lastPostAt &&
    !DateHelper.isOlderThan(group.lastPostAt, 72, 'hours');
  const { desktop, mobile } = getLastPostAtTexts(group.lastPostAt, isLastPostAt);
  return (
    <div className={classNames('group-item', { 'group-item--has-last-post': isLastPostAt })}>
      <GroupItemSegment
        accessibility={group.accessibility}
        label={group.name}
        description={group.description}
        groupIconType={getGroupIconType(group)}
        navigation={{ target: '_self', url: UrlUtils.groupPage(group.id) }}
        canJoinGroup={group.permissions.canJoin}
        onJoin={join}
        lastPostAt={desktop}
        lastPostAtMobile={mobile}
      />
    </div>
  );
};

export default GroupItem;
