import classNames from 'classnames';
import * as React from 'react';

// components
import Icon from 'src/components/forms/icon/icon';

// constants
import { CLASS_PREFIX } from 'src/constants/';
import { textResources } from 'src/lang/de';
import { COLOR_TYPE_GRAY_TINT_9 } from 'src/utils/color';

import './zoom_button.scss';

const cls = CLASS_PREFIX + 'zoom-button';
const buttonCls = cls + '__button';
const iconCls = cls + '__icon';
const labels = textResources.map;

interface IProps {
  zoomIn?: () => void;
  zoomOut?: () => void;
  hideOnMobile?: boolean;
}

const ZoomButton: React.FC<IProps> = ({ zoomOut, zoomIn, hideOnMobile }) => (
  <div className={classNames(cls, hideOnMobile && cls + '--hide-on-mobile')}>
    <button
      className={buttonCls}
      onClick={zoomIn}
      title={labels.zoomInButtonTooltip}
    >
      <Icon name='plus' color={COLOR_TYPE_GRAY_TINT_9} size={36} className={iconCls} />
    </button>
    <div className={cls + '__separator'} />
    <button
      className={buttonCls}
      onClick={zoomOut}
      title={labels.zoomOutButtonTooltip}
    >
      <Icon name='minus' color={COLOR_TYPE_GRAY_TINT_9} size={36} className={iconCls} />
    </button>
  </div>
);

export default ZoomButton;
