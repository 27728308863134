// lib
import * as React from 'react';

// constants/interfaces
import { IProps as IItemSegmentIconProps } from 'src/components/item_segment/parts/segment_icon/segment_icon';

// components
import ItemSegmentBase, { itemSegmentCls, ItemSegmentHeight, SMALLER_ITEM_SEGMENT }
  from 'src/components/item_segment/base/item_segment_base';
import ItemSegmentLink, { IProps as ILinkProps } from 'src/components/item_segment/base/item_segment_link';
import SegmentAvatar, {
  IProps as IItemSegmentAvatarProps,
} from 'src/components/item_segment/parts/segment_avatar/segment_avatar';
import SegmentRoundIcon from 'src/components/item_segment/parts/segment_round_icon/segment_round_icon';
import SegmentText from 'src/components/item_segment/parts/segment_text/segment_text';

// helpers
import { THUMBNAIL_SIZE_TYPE_MEDIUM_LARGE } from '../../../thumbnail/thumbnail';
import './post_create.scss';

interface IProps {
  label: string;
  description?: string;
  avatar?: IItemSegmentAvatarProps;
  icon?: IItemSegmentIconProps;
  navigation?: ILinkProps;
  center?: boolean;
  height?: ItemSegmentHeight;
}

const cls = itemSegmentCls + 'post-create';

/*
  A Wrapper for ItemSegment for Create post
*/
const PostCreateItemSegment: React.FC<IProps> = (
  {
    description,
    label,
    navigation,
    avatar,
    icon,
    center,
    children,
    height = SMALLER_ITEM_SEGMENT,
  }
) => (
  <ItemSegmentBase className={cls} height={height} noBorder>
    <ItemSegmentLink {...navigation}>
      {children || <>
        {avatar && <SegmentAvatar imageUrl={avatar.imageUrl} size={THUMBNAIL_SIZE_TYPE_MEDIUM_LARGE}/>}
        {icon && <SegmentRoundIcon {...icon} isInverted isLightRadius={true}/>}
        <SegmentText label={label} labelSize='big' description={description} center={center}/>
      </>
      }
    </ItemSegmentLink>
  </ItemSegmentBase>
);

export default PostCreateItemSegment;
