// libs
import classNames from 'classnames';
import * as React from 'react';

// component
import Icon from 'src/components/forms/icon/icon';
import Popover from 'src/components/popover/popover';
import Reactions from 'src/components/post/shared/reactions/reactions';
import UserOnboardingCTA from 'src/containers/smart_components/user_onboarding_cta/user_onboarding_cta';

// constants
import { CLASS_PREFIX } from 'src/constants/';
import { REACTION_NAME_CANDLE } from 'src/constants/reactions';
import { textResources } from 'src/lang/de';

// interfaces / constants
import { IRenderAPI } from 'src/components/_libs/popover_lib/popover_lib';
import { IReaction, IReactionsOwnProps, ReactionName } from 'src/interfaces/reactions';

// helpers
import { translateReactions } from 'src/utils/reactions';

import './reactions_button.scss';

interface IProps extends IReactionsOwnProps {
  reactions: IReaction[];
  updateUrl: string;
  updateReaction: (reaction: ReactionName, updateUrl: string, oldReaction?: ReactionName) => void;
}

const cls = CLASS_PREFIX + 'reaction_compact';
const wrapperCls = CLASS_PREFIX + 'reactions_compact';

const ReactionCompact = ({ name }: { name: ReactionName | 'default' | 'default-candle' }) =>
  <div className={classNames(cls, `${cls}--${name}`)} />;

const ReactionIcon = ({ iconName }: { iconName: string }) =>
  <Icon className={classNames(cls, `${cls}--${iconName}`)}
    name={iconName}
    size={21}
  />;

export default class ReactionsCompact extends React.PureComponent<IProps, {}> {
  constructor(props: IProps) {
    super(props);

    this.renderPopoverChildren = this.renderPopoverChildren.bind(this);
  }

  public render() {
    const { reactions, disabled, className } = this.props;

    const zeroReactionsText = reactions.some((reaction) => reaction.name === REACTION_NAME_CANDLE)
      ? textResources.reactions.condolences
      : textResources.reactions.like;

    const myReaction = reactions.find((reaction) => reaction.own);
    const activeWrapper = myReaction && wrapperCls + '--active';
    const countCls = classNames({
      [wrapperCls + '__count']: true,
      [wrapperCls + '__count--active']: !!myReaction,
    });

    return (
      <UserOnboardingCTA active={!!disabled} gtmSourceName='reactions'>
        <Popover
          position={'top'}
          align={reactions.length > 3 ? 'relative' : 'relative-left'}
          layout={'round'}
          trigger={
            <button className={classNames(wrapperCls, className, activeWrapper)} >
              {myReaction
                ? <ReactionCompact name={myReaction.name} />
                : this.renderNoReactions()
              }
              <p className={countCls}>
                {myReaction ? translateReactions(myReaction.name) : zeroReactionsText}
              </p>
            </button>}
        >{this.renderPopoverChildren}
        </Popover>
      </UserOnboardingCTA>
    );
  }

  private renderPopoverChildren({ hide }: IRenderAPI) {
    const { reactions, ...rest } = this.props;
    return (
      <Reactions reactions={reactions} disabled={false} onClose={hide} {...rest} />
    );
  }

  private checkCandlePost(): boolean {
    return this.props.reactions.length === 1 && this.props.reactions[0].name === 'candle';
  }

  private renderNoReactions() {
    const isCandle = this.checkCandlePost();
    return isCandle
      ? <ReactionCompact name='default-candle' />
      : <ReactionIcon iconName='heart-outline' />;
  }
}
