// libs
import classNames from 'classnames';
import * as React from 'react';

// interfaces / constants
import { itemSegmentCls } from 'src/components/item_segment/base/item_segment_base';

import { SegmentTextLabelSize } from 'src/components/item_segment/parts/segment_text/model';
import './segment_text.scss';

interface IProps {
  label: string;
  additionalLabel?: string | null;
  labelSize: SegmentTextLabelSize;
  description?: string;
  descriptionMobile?: string;
  center?: boolean;
}

const cls = itemSegmentCls + 'text';

const SegmentText: React.FC<IProps> = ({
  label,
  labelSize,
  center,
  description,
  descriptionMobile,
  additionalLabel }) => {
  const labelCls = cls + '__label';
  const classes = {
    [labelCls]: true,
    [labelCls + '--small']: labelSize === 'small',
    [labelCls + '--big']: labelSize === 'big' || labelSize === 'xxbig',
  };

  const containerClasses = {
    [cls]: true,
    [cls + '--center']: center,
  };

  return (
    <p className={classNames(containerClasses)}>
      <span className={classNames(classes)}>
        {label}
        {additionalLabel && <span className={cls + '__additional-label'}>{additionalLabel}</span>}
      </span>
      {description &&
        <span className={
          classNames(`${cls}__description`, { [`${cls}__description--desktop`]: !!descriptionMobile })
        }>{description}</span>
      }
      {descriptionMobile &&
        <span className={
          classNames(`${cls}__description`, `${cls}__description--mobile`)
        }>{descriptionMobile}</span>
      }
    </p>
  );
};

export default SegmentText;
