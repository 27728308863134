import React, { useCallback } from 'react';

// interfaces / constants
import { FEED_POST_TYPE_EVENT } from 'src/constants/feed';

// components
import PostCreateBanner from 'src/components/post/create/create_banner';

// helpers
import PostCreate from 'src/containers/post/create';
import { textResources } from 'src/lang/de';
import {
  useSelectProfilePermissions,
  useSelectIsUserLoggedIn,
} from 'src/reducers/user/user_hooks';

const labels = textResources.postCreate;

export const useGetRenderEventCreate = (isPostCreateOpen: boolean) => {
  const isLoggedIn = useSelectIsUserLoggedIn();
  const canCreateEvent = useSelectProfilePermissions()?.createEvents;
  const postType = FEED_POST_TYPE_EVENT;
  return useCallback((): JSX.Element => {
    return (<>
      <PostCreateBanner
        postType={postType}
        callToActionText={labels.createEventTitle}
        featureBlocked={!canCreateEvent}
      />
      <PostCreate
        key='-1'
        open={isPostCreateOpen}
        postType={postType}
        showsBanner={!isLoggedIn}
        featureBlocked={!canCreateEvent}
      />
    </>);
  }, [canCreateEvent, isLoggedIn, isPostCreateOpen, postType]);
};
