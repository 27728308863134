import { IProfileAnswer } from '../../../../interfaces/profile';

export const getAnswersStatuses = (answers: IProfileAnswer[] = []) => answers.map(({ answer }) => {
  if (answer === null) {
    return 'not-answered';
  }

  if (answer) {
    return 'answered';
  }

  return 'skipped';
});
