// libs
import classNames from 'classnames';
import * as React from 'react';

// components
import Icon from 'src/components/forms/icon/icon';

// helpers
import Logo from 'src/components/logo/logo';
import { textResources } from 'src/lang/de';
import { createBemBlock } from 'src/utils/bem_helper/bem_helper';
import { scrollTop } from 'src/utils/wizard';
import './wizard_step.scss';

const cls = createBemBlock('wizard-step');

export interface IProps {
  headerText?: string | JSX.Element;
  leftButton?: JSX.Element;
  rightButton?: JSX.Element;
  rightButtonFn?: () => Promise<void> | void;
  contentCls?: string;
  layout: WizardLayout;
  alignLeft?: boolean;
  spacing?: boolean;
  isValid?: boolean;
}

export const WIZARD_LAYOUT_MODAL = 'modal';
export const WIZARD_LAYOUT_PAGE = 'page';
type WizardLayout = typeof WIZARD_LAYOUT_MODAL | typeof WIZARD_LAYOUT_PAGE;

export default class WizardStep extends React.PureComponent<IProps, {}> {
  componentDidMount() {
    if (this.props.layout === WIZARD_LAYOUT_PAGE) {
      scrollTop();
    }
  }
  public render() {
    const { leftButton, rightButton, children, headerText,
      contentCls, alignLeft, spacing = true, rightButtonFn, isValid } = this.props;

    const handleRightButtonClick = () => {
      if (!isValid) return;
      rightButtonFn && rightButtonFn();
    };

    return (
      <div className={classNames(cls(), spacing && cls('', 'spacing'))}>
        <div className={`${cls()}__logo-container`}>
          <Logo type={'NO_TEXT'} altText={textResources.shared.lokalportal} />
        </div>
        <div className={cls('header')}>
          <h2 className={classNames(cls('header-text'), cls('header-text', alignLeft ? 'left' : ''))}>{headerText}</h2>
        </div>
        <div className={classNames(cls('content-wrapper'), contentCls)}>
          <div className={cls('content')}>{children}</div>
        </div>
        <div className={classNames(cls('footer'))}>
          {leftButton && <div className={classNames(cls('button'), cls('button', 'left'))}>
            { leftButton }
          </div>}
          {rightButton &&
            <div className={classNames(cls('button'), cls('button', 'right'))}>
              { rightButton }
            </div>
          }
          {!!rightButtonFn && (
            <div
              className={classNames(
                cls('icon-button'),
                !isValid && cls('icon-button', 'disabled')
              )}
              onClick={handleRightButtonClick}
            >
              <Icon name={'arrow-right'} size={40} />
            </div>
          )}
        </div>
      </div>
    );
  }
}
