// libs
import * as React from 'react';

// interfaces / constants
import NavigationItem from 'src/components/navigation_item/navigation_item';
import {
  ThumbnailSizeType,
  THUMBNAIL_SIZE_TYPE_SMALL,
} from 'src/components/thumbnail/thumbnail';
import { CLASS_PREFIX } from 'src/constants/';
import { Id } from 'src/interfaces';
import { PostDisplayMode } from 'src/interfaces/posts';
import { UrlUtils } from 'src/utils/url/url';

// classes
import Avatar from 'src/components/avatar/avatar';

// helpers
import { getDateTimeFormat } from 'src/components/post/utils';
import { useSelectProfile } from 'src/reducers/profiles/profiles_hooks';
import { getSponsorBadge } from 'src/utils/badges';

import UserOnboardingCTA from '../../../../containers/smart_components/user_onboarding_cta/user_onboarding_cta';
import { useShouldBlockProfile } from '../../../../pages/hooks/hooks';
import './author.scss';

export interface IProps {
  id: Id;
  publishedAt?: string;
  displayMode: PostDisplayMode;
  size?: ThumbnailSizeType;
}

export const cls = `${CLASS_PREFIX}author__`;

const Author: React.FC<IProps> = ({ id, publishedAt, displayMode, size }) => {
  const author = useSelectProfile(id);
  const authorClass = `${cls}${displayMode}`;
  const isDisabled = useShouldBlockProfile(author?.type);

  if (!author) {
    return null;
  }

  const { type, imageUrl, name, sponsorLevel } = author;

  const profileLink = UrlUtils.getProfileFrontendPath(id, type);

  const avatarSize = size || THUMBNAIL_SIZE_TYPE_SMALL;

  return (
    <UserOnboardingCTA active={isDisabled}>
      <div className={authorClass}>
        <div className={authorClass + '__avatar-container'}>
          <NavigationItem target='_self' url={profileLink}>
            <Avatar
              image={imageUrl}
              size={avatarSize}
              badge={sponsorLevel && getSponsorBadge(sponsorLevel)}
              rounded={false}
            />
          </NavigationItem>
        </div>
        <div className={authorClass + '__description'}>
          <NavigationItem
            target='_self'
            url={profileLink}
            className={`${authorClass}__name ${authorClass}__name-${type}`}
          >
            <span>{name}</span>
          </NavigationItem>
          <div className={authorClass + '__where-and-when'}>
            {publishedAt &&
            <time dateTime={publishedAt}>
              {getDateTimeFormat(publishedAt)}
            </time>
            }
          </div>
        </div>
      </div>
    </UserOnboardingCTA>
  );
};

export default Author;
