// libs
import React, { FunctionComponent } from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { MAP_DEFAULT_ZOOM } from 'src/constants/map';
import { IIngredient, IMap } from 'src/interfaces/post_ingredients';
import { IContentItemType, mapDisplayModeToConst } from 'src/interfaces/posts';

// classes
import Icon from 'src/components/forms/icon/icon';

import StaticMap from './static_map';

// helpers
import { usePostData } from 'src/reducers/feed/feed_hooks';
import './map.scss';

export type IMapProps = IIngredient<IMap>;

// we cannot access the SCSS variables here unfortunatly, so we need to duplicate the color values
const COLOR_PRIMARY = '1E9F21'; // $color-primary
const COLOR_SECONDARY_6 = 'F99A00'; // $color-secondary-6
const cls = `${CLASS_PREFIX}map-ingredient`;

const mapPostTypeToMarkerColor = (postType?: IContentItemType) =>
  postType === 'event' ? COLOR_SECONDARY_6 : COLOR_PRIMARY;

const MapIngredient: FunctionComponent<IMapProps> = ({ postId, postType, data, displayMode }) => {
  const { position } = data.markers[0];

  const { address } = usePostData(postId) || {};

  const displayModeCls = `${cls}__${mapDisplayModeToConst[displayMode]}`;

  return (
    <div className={displayModeCls}>
      <div className={displayModeCls + '__map-wrapper'}>
        <StaticMap
          position={position}
          zoom={MAP_DEFAULT_ZOOM}
          markerColor={mapPostTypeToMarkerColor(postType)}
          address={address}
        />
      </div>
      <p className={displayModeCls + '__address'}>
        {address &&
            <>
              <Icon size={16} name='marker' className={displayModeCls + '__icon'} />
              {address}
            </>
        }
      </p>
    </div>
  );
};

export default MapIngredient;
