// libs
import * as React from 'react';

// components
import { ActivableNavigationItems } from 'src/components/header/navigation/header_navigation';
// constants
import { THREE_COLUMNS, TWO_COLUMNS } from 'src/constants/layout';
import LayoutBase from 'src/layout/base/layout_base';

export interface LayoutNoMapProps {
  activeNavigationItem?: ActivableNavigationItems;
  withHeaderMargin?: boolean;
  subHeader?: JSX.Element;
  contentWidth?: typeof TWO_COLUMNS | typeof THREE_COLUMNS; // default is full width - 3 columns
  pageTitle: JSX.Element;
  isSettingsPage?: boolean;
}

const LayoutNoMap: React.FC<LayoutNoMapProps> = ({
  activeNavigationItem,
  contentWidth,
  subHeader,
  children,
  withHeaderMargin = false,
  pageTitle,
  isSettingsPage = false,
}) => {
  return (
    <LayoutBase
      subHeader={subHeader}
      activeNavigationItem={activeNavigationItem}
      withSidebar={false}
      enableMapToggler={false}
      contentWidth={contentWidth}
      withHeaderMargin={withHeaderMargin}
      pageTitle={pageTitle}
      isSettingsPage={isSettingsPage}
    >
      {children}
    </LayoutBase>
  );
};

export default LayoutNoMap;
