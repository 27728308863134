import * as React from 'react';

import './app_store_links.scss';

const AppStoreLinks: React.FC = () => {
  return null;
  /**
   * Temporarily removed due to problems with iOS app being published
   */
  // return (
  //   <div className={cls()}>
  //     <NavigationItem className={cls('google-play')} url={GOOGLE_PLAY_PATH} target='_blank'>
  //       <img alt='Google Play Store' src={googlePlayButton} />
  //     </NavigationItem>
  //     <NavigationItem url={APP_STORE_PATH} target='_blank'>
  //       <img alt='App Store' src={appStoreButton}/>
  //     </NavigationItem>
  //   </div>
  // );
};

export default AppStoreLinks;
