// libs
import React, { useEffect, useCallback, useMemo } from 'react';
import ItemGroupHeader from 'src/components/item_group_header/item_group_header';

// interfaces / constants
import { FEED_TYPE_EVENT_CATEGORIZED, FEED_TYPE_EVENT_RELATED } from 'src/constants/feed';

// components
import BannerFeed from 'src/components/feed/layouts/banner_feed/banner_feed';
import MyEvents from 'src/components/my_events/my_events';
import FeedWrapper from 'src/containers/smart_components/list_wrapper/feed_wrapper';
import UserOnboardingCTA from 'src/containers/smart_components/user_onboarding_cta/user_onboarding_cta';

// helpers
import {
  useFetchCategorizedEventFeedPage,
  useFetchRelatedEventFeedNextPage,
} from 'src/actions/feed/events_feeds/events_feed_hooks';
import { useMarkersInViewport } from 'src/actions/feed/feed_hooks';
import LoadingSpinnerWrapper from 'src/components/loading_spinner/loading_spinner_wrapper';
import { textResources } from 'src/lang/de';
import { useGetBoundingBox } from 'src/reducers/app-state/app-state_hooks';
import {
  useSelectFeedGroups,
  useSelectFeedIds,
  useSelectFeedIsLoading,
  useSelectPosts,
  useSelectFeedHash,
} from 'src/reducers/feed/feed_hooks';
import { useSelectDateFilter } from 'src/reducers/filters/filters_hooks';
import {
  useSelectProfilePermissions,
} from 'src/reducers/user/user_hooks';
import { removeDuplicateEventsInSecondaryFeed } from 'src/utils/feeds';
import { useGetRenderEventCreate } from './hooks';

interface IProps {
  isPostCreateOpen?: boolean;
}

const CategorizedEventFeed: React.FC<IProps> = ({ isPostCreateOpen = false }) => {
  const boundingBox = useGetBoundingBox();
  const feedHash = useSelectFeedHash(FEED_TYPE_EVENT_CATEGORIZED);
  const posts = useSelectPosts();
  const categorizedIds = useSelectFeedIds(FEED_TYPE_EVENT_CATEGORIZED);
  const relatedIds = useSelectFeedIds(FEED_TYPE_EVENT_RELATED);
  const ids = useMemo(() => categorizedIds.concat(relatedIds), [categorizedIds, relatedIds]);
  const groups = useSelectFeedGroups(FEED_TYPE_EVENT_CATEGORIZED);
  const relatedGroups = useSelectFeedGroups(FEED_TYPE_EVENT_RELATED);
  const isCategorizedLoading = useSelectFeedIsLoading(FEED_TYPE_EVENT_CATEGORIZED);
  const isRelatedLoading = useSelectFeedIsLoading(FEED_TYPE_EVENT_RELATED);
  const isLoading = isCategorizedLoading || isRelatedLoading;
  const dateFilterRange = useSelectDateFilter();

  useMarkersInViewport(ids, posts);
  const getFirstPages = useFetchCategorizedEventFeedPage();
  const getNextRelatedPage = useFetchRelatedEventFeedNextPage();
  const renderPostCreate = useGetRenderEventCreate(isPostCreateOpen);

  useEffect(() => {
    getFirstPages();
  }, [boundingBox, getFirstPages, feedHash, dateFilterRange, isLoading]);

  const canCreateEvents = useSelectProfilePermissions()?.createEvents;

  const cleanRelatedGroups = removeDuplicateEventsInSecondaryFeed(groups, relatedGroups);

  const renderFeedTitle = useCallback(() => (
    <ItemGroupHeader
      title={textResources.shared.hereIsMore}
      subtitle={textResources.eventfeed.relatedContentSubtitle}
      spacing
    />
  ), []);
  return (
    <LoadingSpinnerWrapper isLoading={isLoading} >
      <FeedWrapper
        getNextPage={getNextRelatedPage}
        feedType={FEED_TYPE_EVENT_RELATED}
        initialLoad={false}
      >
        <UserOnboardingCTA active={!canCreateEvents} gtmSourceName='eventCreate'>
          <MyEvents/>
        </UserOnboardingCTA>
        <BannerFeed
          groups={groups}
          isLoading={isLoading}
          posts={posts}
          renderPostCreate={renderPostCreate}
          showGroupLabels
          showEmptyFeed={false}
          showAllTopLink
        />
        <BannerFeed
          groups={cleanRelatedGroups}
          showGroupLabels={false}
          posts={posts}
          isLoading={isLoading}
          renderFeedTitle={renderFeedTitle}
        />
      </FeedWrapper>
    </LoadingSpinnerWrapper >
  );
};

CategorizedEventFeed.displayName = 'CategorizedEventFeed';

export default CategorizedEventFeed;
