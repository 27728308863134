// libs
import React, { useEffect, useState } from 'react';

// interfaces / constants
import { IRenderAPI } from 'src/components/_libs/popover_lib/popover_lib';
import { CLASS_PREFIX } from 'src/constants/';
import { NO_USER_PROFILE_TYPE } from 'src/constants/profile';
import {
  FRONTEND_CONVERSATION_PATH,
  FRONTEND_CREATE_PROFILE,
  FRONTEND_GROUP_OVERVIEW_PATH,
  FRONTEND_SETTINGS_PATH,
} from 'src/constants/urls';
import { POPUP_TYPE_MAP } from 'src/interfaces/app-state';

// classes / components
import BlogProfileAdminModal from 'src/components/blog_profile_admin_modal/blog_profile_admin_modal';
import { FeatureFlag } from 'src/components/feature_flag/feature_flag';
import { navigationFeatureFlags } from 'src/components/feature_flag/navigation_feature_flag/constants';
import { NavigationFeatureFlags } from 'src/components/feature_flag/navigation_feature_flag/model';
import Feedback from 'src/components/feedback/feedback';
import MenuItemSegment from 'src/components/item_segment/layouts/menu/menu';
import PopoverMenu, { callAll } from 'src/components/popover_menu/popover_menu';
import Spread from 'src/components/spread/spread';
import StaticPages from 'src/components/static_pages/static_pages';
import MenuButton from 'src/containers/smart_components/main_menu/main_menu_button';
import UserOnboardingCTA from 'src/containers/smart_components/user_onboarding_cta/user_onboarding_cta';
import { URLConsumer } from 'src/containers/url_provider/url_provider';

// helpers
import { useUnreadConversationsCount } from '@lokalportal/frontends-shared';
import {
  useHideMap,
  useLogOut,
  useShowLoginModal,
  useShowRegistrationModal,
} from 'src/actions/app-state/app-state_hooks';
import { textResources } from 'src/lang/de';
import { useGetAppActivePopupMenu } from 'src/reducers/app-state/app-state_hooks';
import { useSelectProfileNotifications } from 'src/reducers/notifications/notifications_hooks';
import { useGetSponsorLevelFromUser } from 'src/reducers/profiles/profiles_hooks';
import { useSelectCurrentProfile, useSelectUser } from 'src/reducers/user/user_hooks';
import { getSponsorBadge } from 'src/utils/badges';
import { getConfigValue } from 'src/utils/config/config';
import { isBlogProfile, isFullProfile, isIntermediateProfile } from 'src/utils/profile';
import initTracker, { MAIN_MENU } from 'src/utils/reporting/events_tracking/events_tracking';
import { UrlUtils } from 'src/utils/url/url';
import { SMALL_ITEM_SEGMENT } from '../../../components/item_segment/base/item_segment_base';
import {
  useMessenger,
} from '../../../components/settings/settings_wizard/steps/settings_overview/messenger_toggle/useMessenger';
import './main_menu.scss';

const cls: string = CLASS_PREFIX + 'main-menu';
const clsSeparator = `${cls}__separator`;
const clsStatic = `${cls}__static`;
const tracking = initTracker(MAIN_MENU);

const { shared: labelsShared, mainMenu: labelsMainMenu, header: labelsHeader } = textResources;

interface IProps {
  className?: string;
  popupPosition?: 'left' | 'right';
}

const MainMenu: React.FC<IProps> = ({ className, popupPosition }) => {
  const { email, loggedIn, loginRequestPending, profiles, selectedProfileId } = useSelectUser();
  const { avatar, id, name, type, permissions } = useSelectCurrentProfile();
  const { enabled: isMessengerEnabled } = useMessenger();
  const shallInvite = typeof UrlUtils.getCurrentUrlSearchParam('invite_neighbour') === 'string';
  const isMapShown = useGetAppActivePopupMenu() === POPUP_TYPE_MAP;
  const mailboxCount = useUnreadConversationsCount();
  const immediateInvite = shallInvite && loggedIn && !loginRequestPending;
  const notificationsForProfile = useSelectProfileNotifications();
  const profileType = type || NO_USER_PROFILE_TYPE;
  const sponsorLevel = useGetSponsorLevelFromUser();
  const showLoginModal = useShowLoginModal();
  const showRegistrationModal = useShowRegistrationModal();
  const logOut = useLogOut();
  const hideMap = useHideMap();
  const [blogProfileAdminModalShown, setBlogProfileAdminModalShown] = useState(false);
  const [feedbackModalShown, setFeedbackModalShown] = useState(false);
  const [spreadModalShown, setSpreadModalShown] = useState(immediateInvite);
  const notificationsCountProfileMap: { [profielId: string]: number | undefined } =
    Object.keys(notificationsForProfile).reduce(
      (acc, profileId) => ({
        ...acc,
        [profileId]: notificationsForProfile[profileId]!.unseenCount,
      }),
      {}
    );

  useEffect(() => {
    setSpreadModalShown(immediateInvite);
  }, [immediateInvite]);

  const showBlogProfileAdminModal = () => {
    setBlogProfileAdminModalShown(true);
  };

  const hideBlogProfileAdminModal = () => {
    setBlogProfileAdminModalShown(false);
  };

  const onShow = (): void => {
    tracking(MAIN_MENU.ACTIONS.OPEN, MAIN_MENU.LABELS.FINISH);
  };

  const closeMap = (): void => {
    isMapShown && hideMap();
  };

  const showFeedbackModal = () => {
    setFeedbackModalShown(true);
  };

  const hideFeedbackModal = () => {
    setFeedbackModalShown(false);
  };

  const showSpreadModal = () => {
    setSpreadModalShown(true);
  };

  const hideSpreadModal = () => {
    setSpreadModalShown(false);
  };

  const renderAdditionalProfiles = () => {
    const profileKeys = Object.keys(profiles);
    const moreThanOneProfile = profileKeys.length > 1;
    if (!loggedIn || !moreThanOneProfile) {
      return null;
    }

    return (
      <>
        {profileKeys.map(
          (profileId, key) =>
            selectedProfileId !== profileId && (
              <MenuItemSegment
                key={key}
                navigation={{ onClick: switchProfile(profileId) }}
                label={profiles[profileId].name || ''}
                badge={
                  notificationsCountProfileMap[profileId]
                    ? { color: 'gray', type: 'notification', value: notificationsCountProfileMap[profileId] }
                    : undefined
                }
                noBorder
                smallMargin
                height={SMALL_ITEM_SEGMENT}
                hover={true}
              />
            )
        )}
        <li className={clsSeparator} />
      </>
    );
  };

  const switchProfile = (id: string) => {
    const { search, origin } = window.location;
    const params = new URLSearchParams(search);

    return () => {
      tracking(MAIN_MENU.ACTIONS.SWITCH_PROFILE, MAIN_MENU.LABELS.FINISH, () => {
        params.set('as', id);
        window.location.replace(origin + '?' + params.toString());
      });
    };
  };

  const renderPopoverChildren = ({ hide }: IRenderAPI) => {
    const profileIdentifier = id;
    const fullProfile = isFullProfile(profileType);
    const intermediateProfile = isIntermediateProfile(profileType);
    const blogProfile = isBlogProfile(profileType);
    const profileLink = profileIdentifier && UrlUtils.getProfileFrontendPath(profileIdentifier, profileType);

    const sourceComponent = MAIN_MENU.CATEGORY;

    return (
      <nav className={cls}>
        <ul>

          {/* profile */}
          {fullProfile &&
            <MenuItemSegment
              avatar={{ badge: sponsorLevel && getSponsorBadge(sponsorLevel), imageUrl: avatar?.url || '' }}
              navigation={{ onClick: callAll(hide, closeMap), url: profileLink }}
              label={name || ''}
              noBorder
              showArrow={false}
              smallMargin
              height={SMALL_ITEM_SEGMENT}
              hover={true}
            />
          }

          {blogProfile && <MenuItemSegment
            navigation={{ onClick: showBlogProfileAdminModal }}
            label={textResources.manageBlogProfileAdmin.menuName}
            noBorder
            smallMargin
            height={SMALL_ITEM_SEGMENT}
            hover={true}
          />}
          <FeatureFlag active={getConfigValue('featureFlags.startMenu.createProfile')}>
            {intermediateProfile
              ? <UserOnboardingCTA active={true} gtmSourceName='profileMenu--createProfile'>
                <MenuItemSegment
                  navigation={{ onClick: hide, url: FRONTEND_CREATE_PROFILE }}
                  label={labelsMainMenu.createBlog}
                  noBorder
                  smallMargin
                  height={SMALL_ITEM_SEGMENT}
                  hover={true}
                />
              </UserOnboardingCTA>
              : permissions && (permissions.createBlogProfile || permissions.createPrivateProfile)
                ? <MenuItemSegment
                  navigation={{ onClick: hide, url: FRONTEND_CREATE_PROFILE }}
                  label={labelsMainMenu.createBlog}
                  noBorder
                  smallMargin
                  height={SMALL_ITEM_SEGMENT}
                  hover={true}
                />
                : null
            }
          </FeatureFlag>
          <li className={clsSeparator} />

          {/* additional profiles */}
          {renderAdditionalProfiles()}

          {/* feed links */}
          <div className={`${cls}__feed-links`}>
            <URLConsumer>
              {({ getEventsfeedRoute, getNewsfeedRoute }) => (
                <>
                  <FeatureFlag active={navigationFeatureFlags.get(NavigationFeatureFlags.NEWS)}>
                    <MenuItemSegment
                      navigation={{
                        onClick: callAll(hide, closeMap),
                        url: {
                          pathname: getNewsfeedRoute(),
                          state: {
                            sourceComponent,
                          },
                        },
                      }}
                      label={labelsHeader.mainPage}
                      noBorder
                      smallMargin
                      height={SMALL_ITEM_SEGMENT}
                      hover={true}
                    />
                  </FeatureFlag>
                  <FeatureFlag active={navigationFeatureFlags.get(NavigationFeatureFlags.EVENT)}>
                    <MenuItemSegment
                      navigation={{
                        onClick: callAll(hide, closeMap),
                        url: {
                          pathname: getEventsfeedRoute(),
                          state: {
                            sourceComponent,
                          },
                        },
                      }}
                      label={labelsHeader.events}
                      noBorder
                      smallMargin
                      height={SMALL_ITEM_SEGMENT}
                      hover={true}
                    />
                  </FeatureFlag>
                  <FeatureFlag active={navigationFeatureFlags.get(NavigationFeatureFlags.GROUP)}>
                    {permissions?.joinGroups && <MenuItemSegment
                      navigation={{
                        onClick: callAll(hide, closeMap),
                        url: {
                          pathname: FRONTEND_GROUP_OVERVIEW_PATH,
                          state: {
                            sourceComponent,
                          },
                        },
                      }}
                      label={labelsHeader.groups}
                      noBorder
                      smallMargin
                      height={SMALL_ITEM_SEGMENT}
                      hover={true}
                    />}
                  </FeatureFlag>
                </>
              )}
            </URLConsumer>
            <li className={clsSeparator} />
          </div>

          {/* mailbox */}
          {(loggedIn && permissions && permissions.readMessages) && isMessengerEnabled &&
            <MenuItemSegment
              navigation={{ onClick: hide, url: FRONTEND_CONVERSATION_PATH }}
              label={labelsMainMenu.conversations}
              badge={{ color: 'gray', type: 'message', value: mailboxCount > 0 ? mailboxCount : undefined }}
              noBorder
              smallMargin
              height={SMALL_ITEM_SEGMENT}
              hover={true}
            />
          }

          {/* setting logout */}
          {loggedIn && <MenuItemSegment
            navigation={{ onClick: hide, url: FRONTEND_SETTINGS_PATH }}
            label={labelsShared.settings}
            noBorder
            smallMargin
            height={SMALL_ITEM_SEGMENT}
            hover={true}
          />}
          {loggedIn && <MenuItemSegment
            navigation={{ onClick: callAll(hide, logOut) }}
            label={labelsShared.logout}
            noBorder
            smallMargin
            height={SMALL_ITEM_SEGMENT}
            hover={true}
          />}

          {/* login register */}
          {!loggedIn && <MenuItemSegment
            navigation={{ onClick: callAll(hide, showLoginModal) }}
            label={labelsShared.login}
            noBorder
            smallMargin
            height={SMALL_ITEM_SEGMENT}
            hover={true}
          />}
          {!loggedIn && <MenuItemSegment
            navigation={{ onClick: callAll(hide, showRegistrationModal) }}
            label={labelsShared.register}
            noBorder
            smallMargin
            height={SMALL_ITEM_SEGMENT}
            hover={true}
          />}

          <li className={clsSeparator} />

          {/* actions */}
          {loggedIn &&
            <MenuItemSegment
              navigation={{ onClick: callAll(hide, showSpreadModal) }}
              label={labelsMainMenu.spread}
              noBorder
              smallMargin
              height={SMALL_ITEM_SEGMENT}
              hover={true}
            />
          }
          <MenuItemSegment
            navigation={{ onClick: callAll(hide, showFeedbackModal) }}
            label={textResources.shared.feedback}
            noBorder
            smallMargin
            height={SMALL_ITEM_SEGMENT}
            hover={true}
          />
          <li className={clsStatic}><StaticPages /></li>
        </ul>
      </nav>
    );
  };

  return (
    <>
      <PopoverMenu
        className={className}
        position={'bottom'}
        align={popupPosition || 'left'}
        trigger={<MenuButton image={avatar?.url || ''} />}
        onShow={onShow}
        isFullHeight={false}
      >{renderPopoverChildren}</PopoverMenu>
      {feedbackModalShown && (
        <Feedback loggedInUserEmail={email} close={hideFeedbackModal} />
      )}
      {spreadModalShown && (
        <Spread loggedInUserName={name || ''} profileType={profileType} close={hideSpreadModal} />
      )}
      {blogProfileAdminModalShown && name !== undefined &&
        <BlogProfileAdminModal
          profileName={name}
          onClose={hideBlogProfileAdminModal}
        />
      }
    </>
  );
};

export default MainMenu;
