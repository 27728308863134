import { throttle } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { CLASS_PREFIX } from '../../../constants';
import { useResizeListener } from '../../../hooks_shared/use_event_listener';
import { readColumnCountFromDOM } from '../../../utils/column_count';
import { getConfigValue } from '../../../utils/config/config';
import PostRecommendation from '../post_recommendation/post_recommendation';
import ShapeSponsorsInfo from '../shape_sponsors/shape_sponsors_info/shape_sponsors_info';
import './extra_elements.scss';

const cls = CLASS_PREFIX + 'extra-elements';

const ExtraElements = () => {
  const [columnCount, setColumnCount] = useState(() => readColumnCountFromDOM());
  const listener = useCallback(
    throttle(() => {
      setColumnCount(readColumnCountFromDOM());
    }, 100),
    [],
  );

  useEffect(() => {
    setColumnCount(readColumnCountFromDOM());
  }, []);

  useResizeListener(listener, { passive: true });

  const shouldShowPostRecommendation = getConfigValue<boolean>(
    'featureFlags.feed.showRecommendedPost'
  ) && columnCount > 1;

  return <div className={cls}>
    {shouldShowPostRecommendation && <PostRecommendation key='post_recommendation'/>}
    <ShapeSponsorsInfo key='shape_sponsors_info'/>
  </div>;
};

export default ExtraElements;
