// interfaces
import { IAction } from 'src/actions/index';
import { ItemType } from 'src/interfaces/posts';
import { IGlobalProfiles, IProfilesListCategory } from 'src/interfaces/profiles_list';

// helpers
import { ThunkAction } from 'redux-thunk';
import api, { IGetProfilesParams, IGetCategorizedProfilesParams } from 'src/api';
import { Id } from 'src/interfaces';
import { IShapeSponsor } from 'src/interfaces/shape_sponsors';
import { IRootState } from 'src/reducers/interface';

const PROFILES_LIST_GET_SECONDARY_LIST_PROFILES = 'PROFILES_LIST_GET_SECONDARY_LIST_PROFILES';

export const PROFILES_LIST_CLEAN = 'PROFILES_LIST_CLEAN';
export const PROFILES_LIST_CLEAR_ALL = 'PROFILES_LIST_CLEAR_ALL';

export const PROFILES_LIST_SET_HASH = 'PROFILES_LIST_SET_HASH';
export const PROFILES_LIST_SET_SECONDARY_HASH = 'PROFILES_LIST_SET_SECONDARY_HASH';

export const PROFILES_LIST_GET_PROFILES = 'PROFILES_LIST_GET_PROFILES';
export const PROFILES_LIST_GET_PROFILES_PENDING = 'PROFILES_LIST_GET_PROFILES_PENDING';
export const PROFILES_LIST_GET_PROFILES_SUCCESS = 'PROFILES_LIST_GET_PROFILES_SUCCESS';
export const PROFILES_LIST_GET_PROFILES_ERROR = 'PROFILES_LIST_GET_PROFILES_ERROR';

export const PROFILES_LIST_GET_SECONDARY_LIST_PROFILES_PENDING = 'PROFILES_LIST_GET_SECONDARY_LIST_PROFILES_PENDING';
export const PROFILES_LIST_GET_SECONDARY_LIST_PROFILES_SUCCESS = 'PROFILES_LIST_GET_SECONDARY_LIST_PROFILES_SUCCESS';
export const PROFILES_LIST_GET_SECONDARY_LIST_PROFILES_ERROR = 'PROFILES_LIST_GET_SECONDARY_LIST_PROFILES_ERROR';

export const PROFILES_LIST_GET_SPONSORS = 'PROFILES_LIST_GET_SPONSORS';
export const PROFILES_LIST_GET_SPONSORS_PENDING = 'PROFILES_LIST_GET_SPONSORS_PENDING';
export const PROFILES_LIST_GET_SPONSORS_SUCCESS = 'PROFILES_LIST_GET_SPONSORS_SUCCESS';
export const PROFILES_LIST_GET_SPONSORS_ERROR = 'PROFILES_LIST_GET_SPONSORS_ERROR';

export const PROFILES_LIST_GET_GLOBAL_PROFILES = 'PROFILES_LIST_GET_GLOBAL_PROFILES';
export const PROFILES_LIST_GET_GLOBAL_PROFILES_PENDING = 'PROFILES_LIST_GET_GLOBAL_PROFILES_PENDING';
export const PROFILES_LIST_GET_GLOBAL_PROFILES_SUCCESS = 'PROFILES_LIST_GET_GLOBAL_PROFILES_SUCCESS';
export const PROFILES_LIST_GET_GLOBAL_PROFILES_ERROR = 'PROFILES_LIST_GET_GLOBAL_PROFILES_ERROR';

type GetProfilesListThunk = ThunkAction<Promise<void> | undefined, IRootState, {}, IAction>;
type GetProfilesSecondaryListThunk = ThunkAction<Promise<void> | undefined, IRootState, {}, IAction>;

interface IProfilesListClearAll {
  meta: {
    storeKeys: [typeof PROFILES_LIST, typeof PROFILES_LIST_SECONDARY];
  };
  type: typeof PROFILES_LIST_CLEAR_ALL;
}

export interface IProfilesListSetHash {
  meta: {
    storeKey: typeof PROFILES_LIST;
  };
  type: typeof PROFILES_LIST_SET_HASH;
  payload: string;
}

export interface IProfilesListSetSecondaryHash {
  meta: {
    storeKey: typeof PROFILES_LIST_SECONDARY;
  };
  type: typeof PROFILES_LIST_SET_SECONDARY_HASH;
  payload: string;
}

export interface IGetProfilesListErrorAction {
  type: typeof PROFILES_LIST_GET_PROFILES_ERROR;
}

export interface IGetProfilesListPendingAction {
  type: typeof PROFILES_LIST_GET_PROFILES_PENDING;
}

export interface IGetProfilesListSuccessAction {
  meta: {
    storeKey: typeof PROFILES_LIST;
  };
  type: typeof PROFILES_LIST_GET_PROFILES_SUCCESS;
  payload: IProfilesListCategory | null;
}

export interface IGetProfilesListSecondaryListErrorAction {
  type: typeof PROFILES_LIST_GET_SECONDARY_LIST_PROFILES_ERROR;
}

export interface IGetProfilesListSecondaryListPendingAction {
  type: typeof PROFILES_LIST_GET_SECONDARY_LIST_PROFILES_PENDING;
}

export interface IGetProfilesListSecondaryListSuccessAction {
  meta: {
    storeKey: typeof PROFILES_LIST_SECONDARY;
  };
  type: typeof PROFILES_LIST_GET_SECONDARY_LIST_PROFILES_SUCCESS;
  payload: IProfilesListCategory | null;
}

export interface IGetSponsorsListErrorAction {
  type: typeof PROFILES_LIST_GET_SPONSORS_ERROR;
}

export interface IGetSponsorsListPendingAction {
  type: typeof PROFILES_LIST_GET_SPONSORS_PENDING;
}

export interface IGetSponsorsListSuccessAction {
  meta: {
    storeKey: typeof PROFILES_LIST;
  };
  type: typeof PROFILES_LIST_GET_SPONSORS_SUCCESS;
  payload: (IProfilesListCategory & {sponsors: IShapeSponsor[]}) | null;
}

export interface IGetGlobalProfilesListPendingAction {
  type: typeof PROFILES_LIST_GET_GLOBAL_PROFILES_PENDING;
}

export interface IGetGlobalProfilesListAction {
  meta: {
    storeKey: typeof PROFILES_LIST;
  };
  type: typeof PROFILES_LIST_GET_GLOBAL_PROFILES;
  payload: (IGlobalProfiles | null);
}

export interface IGetGlobalProfilesListSuccessAction {
  meta: {
    storeKey: typeof PROFILES_LIST;
  };
  type: typeof PROFILES_LIST_GET_GLOBAL_PROFILES_SUCCESS;
  payload: (IGlobalProfiles | null);
}

export type Actions =
  IProfilesListClean |
  IProfilesListClearAll |
  IProfilesListSetHash |
  IProfilesListSetSecondaryHash |
  IGetProfilesListErrorAction |
  IGetProfilesListPendingAction |
  IGetProfilesListSuccessAction |
  IGetProfilesListSecondaryListErrorAction |
  IGetProfilesListSecondaryListPendingAction |
  IGetProfilesListSecondaryListSuccessAction |
  IGetProfilesListSecondaryListSuccessAction |
  IGetSponsorsListErrorAction |
  IGetSponsorsListPendingAction |
  IGetSponsorsListSuccessAction |
  IGetGlobalProfilesListAction |
  IGetGlobalProfilesListSuccessAction |
  IGetGlobalProfilesListPendingAction
;

export interface IProfilesListClean {
  meta: {
    storeKey: typeof PROFILES_LIST_SECONDARY;
  };
  type: typeof PROFILES_LIST_CLEAN;
}

export const clean = (): IProfilesListClean => ({
  meta: {
    storeKey: PROFILES_LIST_SECONDARY,
  },
  type: PROFILES_LIST_CLEAN,
});

const PROFILES_LIST = 'profileList';
const PROFILES_LIST_SECONDARY = 'profileListSecondary';

export const clearAllProfiles = (): IProfilesListClearAll => ({
  meta: {
    storeKeys: [PROFILES_LIST, PROFILES_LIST_SECONDARY],
  },
  type: PROFILES_LIST_CLEAR_ALL,
});

export const setHash = (hash: string): IProfilesListSetHash => ({
  meta: {
    storeKey: PROFILES_LIST,
  },
  payload: hash,
  type: PROFILES_LIST_SET_HASH,
});

export const setSecondaryHash = (hash: string): IProfilesListSetSecondaryHash => ({
  meta: {
    storeKey: PROFILES_LIST_SECONDARY,
  },
  payload: hash,
  type: PROFILES_LIST_SET_SECONDARY_HASH,
});

export const getProfilesList = (params: IGetProfilesParams): GetProfilesListThunk => {
  return async(dispatch, getState) => {
    const state = getState();
    const isLoading = state.profilesList.secondaryList.isLoading;

    if (isLoading) {
      return;
    }

    dispatch(getProfilesListRequest(params));
  };
};

const getProfilesListRequest = (params: IGetProfilesParams) => {
  const controller = new AbortController();
  return {
    meta: {
      abort: controller.abort.bind(controller),
      storeKey: PROFILES_LIST_SECONDARY,
    },
    payload: api.profiles.getProfiles(params, controller.signal),
    type: PROFILES_LIST_GET_SECONDARY_LIST_PROFILES,
  };
};

export const getProfilesListCategorized = (params: IGetCategorizedProfilesParams): IAction => ({
  meta: {
    storeKey: PROFILES_LIST,
  },
  payload: api.profiles.getCategorizedProfiles(params),
  type: PROFILES_LIST_GET_PROFILES,
});

const loadFolloweeList = () => ({ type: PROFILES_LIST_GET_SECONDARY_LIST_PROFILES_PENDING });
const finishedLoadingFolloweeList = (payload: IProfilesListCategory | null) => ({
  meta: { storeKey: PROFILES_LIST_SECONDARY },
  payload,
  type: PROFILES_LIST_GET_SECONDARY_LIST_PROFILES_SUCCESS,
});
const failedLoadingFolloweeList = (error: Error) => ({
  error,
  meta: { storeKey: PROFILES_LIST_SECONDARY },
  type: PROFILES_LIST_GET_SECONDARY_LIST_PROFILES_ERROR,
});

export const getFolloweesList = (page: number): GetProfilesSecondaryListThunk => {
  return async(dispatch, getState) => {
    const state = getState();

    if (state.profilesList.secondaryList.isLoading || state.profilesList.secondaryList.pagination.currentPage >= page) {
      return;
    }

    dispatch(loadFolloweeList());

    await api.follow.followeesList({ orderDirection: 'desc', page })
      .then((result) => {
        dispatch(finishedLoadingFolloweeList(result));
      })
      .catch((error) => {
        dispatch(failedLoadingFolloweeList(error));
      });
  };
};

const loadFollowersList = () => ({ type: PROFILES_LIST_GET_SECONDARY_LIST_PROFILES_PENDING });
const finishedLoadingFollowersList = (payload: IProfilesListCategory | null) => ({
  meta: { storeKey: PROFILES_LIST_SECONDARY },
  payload,
  type: PROFILES_LIST_GET_SECONDARY_LIST_PROFILES_SUCCESS,
});
const failedLoadingFollowersList = (error: Error) => ({
  error,
  meta: { storeKey: PROFILES_LIST_SECONDARY },
  type: PROFILES_LIST_GET_SECONDARY_LIST_PROFILES_ERROR,
});

export const getFollowersList = (page: number): GetProfilesSecondaryListThunk => {
  return async(dispatch, getState) => {
    const state = getState();

    if (state.profilesList.secondaryList.isLoading || state.profilesList.secondaryList.pagination.currentPage >= page) {
      return;
    }

    dispatch(loadFollowersList());

    await api.follow.followersList({ orderDirection: 'desc', page })
      .then((result) => {
        dispatch(finishedLoadingFollowersList(result));
      })
      .catch((error) => {
        dispatch(failedLoadingFollowersList(error));
      });
  };
};

export const getReactedProfiles = (itemType: ItemType, postId: Id): IAction => {
  const controller = new AbortController();
  return {
    meta: {
      abort: controller.abort.bind(controller),
    },
    payload: api.reaction.reactedProfiles(itemType, postId, controller.signal),
    type: PROFILES_LIST_GET_SECONDARY_LIST_PROFILES,
  };
};

export const getShapeSponsors = (): IAction => ({
  meta: {
    storeKey: PROFILES_LIST,
  },
  payload: api.profiles.getShapeSponsors(),
  type: PROFILES_LIST_GET_SPONSORS,
});

export const getGlobalProfiles = (): IAction => ({
  meta: {
    storeKey: PROFILES_LIST,
  },
  payload: api.profiles.getGlobalProfiles(),
  type: PROFILES_LIST_GET_GLOBAL_PROFILES,
});
