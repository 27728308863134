// libs
import classNames from 'classnames';
import * as React from 'react';

// helpers
import { CLASS_PREFIX } from 'src/constants/';
import { toggleBodyScrolllock } from 'src/utils/scrolllock/scrolllock';
import './popover_lib.scss';

const cls = CLASS_PREFIX + 'popoverlib__';
const clsPopover = `${cls}popover`;
const clsToggle = `${cls}toggler`;

export interface IProps {
  /** render as div(block) or span */
  isRenderedAsInlineHtml?: boolean;
  /** when we provide a className we put it onto the wrapper */
  className?: string;
  /** show open popover by default */
  isOpen?: boolean;
  /** close popover on outside click */
  closeOnClickOutside?: boolean;
  /** should the bottom of the popover automatically align to the viewport bottom */
  isFullHeight?: boolean;
  /** position of the popover */
  position: typeof POPOVER_POSITION_TOP
  | typeof POPOVER_POSITION_BOTTOM
  | typeof POPOVER_POSITION_LEFT
  | typeof POPOVER_POSITION_RIGHT;
  /** position of the popover */
  align: typeof POPOVER_ALIGN_TOP
  | typeof POPOVER_ALIGN_BOTTOM
  | typeof POPOVER_ALIGN_LEFT
  | typeof POPOVER_ALIGN_RIGHT;
  /** callback when popover is hidden */
  onHide?: EventCallbackType;
  /** callback when popover is shown */
  onShow?: EventCallbackType;
  /** children can be react nodes or a render function */
  children?: JSX.Element | RenderPropFunctionType;
  disabled?: boolean;
}

interface IState {
  isOpen: boolean;
}

/** position types */
export const POPOVER_POSITION_TOP = 'top';
export const POPOVER_POSITION_BOTTOM = 'bottom';
export const POPOVER_POSITION_LEFT = 'left';
const POPOVER_POSITION_RIGHT = 'right';

/** align types */
const POPOVER_ALIGN_TOP = 'top';
const POPOVER_ALIGN_BOTTOM = 'bottom';
export const POPOVER_ALIGN_LEFT = 'left';
export const POPOVER_ALIGN_RIGHT = 'right';

/** this function executes all functions you give to it, we use any type here to pass through all params */
type funcType = (...arg: any[]) => void;
type callAllType = (f: funcType, f2?: funcType, f3?: funcType) => (...e: any[]) => void;
export const callAll = (...fns: any[]) => (...params: any[]): void => fns.forEach((fn) => fn && fn(...params));

/** render props api */
export interface IRenderAPI {
  hide: EventCallbackType;
  show: EventCallbackType;
  toggle: EventCallbackType;
  isOpen: boolean;
  callAll: callAllType;
  getPopoverProps: popupPropsFunc;
  getTogglerProps: togglerPropsFunc;
  renderProps: renderPropsType;
}

/** general event / event callback types */
type EventType = React.SyntheticEvent<Element> | React.MouseEvent<HTMLElement> | Event;
type EventCallbackType = (e?: EventType) => void;

/** types for getTogglerProps */
interface ITogglerInputProps {
  className?: string;
  onClick?: EventCallbackType;
}

type togglerPropsFunc = (props?: ITogglerInputProps) => ITogglerInputProps;

/** types for getPopoverProps */
interface IPopoverInputProps {
  className?: string;
  ref?: React.RefObject<HTMLDivElement>;
}

type popupPropsFunc = (props?: IPopoverInputProps) => IPopoverInputProps;

/** reusable renderProp function so that each popover doesnt need to implement it */
type RenderPropFunctionType = (props: IRenderAPI) => JSX.Element | null;
export type renderPropsElementType = React.ReactNode | RenderPropFunctionType;
type renderPropsType = (element: renderPropsElementType, props?: IRenderAPI) => JSX.Element | null;

const renderProps: renderPropsType = (element, props) => {
  if (typeof element === 'function') {
    if (!props) {
      return null;
    }

    return (element as RenderPropFunctionType)(props);
  }

  if (!React.isValidElement(element)) {
    return null;
  }

  return element;
};

/** partial apply props, so that components doesnt have to pass the renderprops by itself */
const renderPropsPartial = (props?: IRenderAPI) => (element: renderPropsElementType) => renderProps(element, props);

/**
 * a Popover React component
 */
class PopOver extends React.Component<IProps, IState> {
  public popoverRef = React.createRef<HTMLDivElement>();
  public anchorRef = React.createRef<HTMLDivElement>();

  public constructor(props: IProps) {
    super(props);

    const { isOpen } = this.props;

    this.state = {
      isOpen: isOpen || false,
    };

    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.hide = this.hide.bind(this);
    this.show = this.show.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.toggle = this.toggle.bind(this);
    this.updatePopover = this.updatePopover.bind(this);

    isOpen && this.handleShow();
  }

  public componentDidMount() {
    window.addEventListener('resize', this.updatePopover, false);
    this.updatePopover();
  }

  public componentWillUnmount() {
    const { closeOnClickOutside, isFullHeight } = this.props;
    closeOnClickOutside && document.removeEventListener('click', this.handleOutsideClick, false);
    window.removeEventListener('resize', this.updatePopover, false);
    // we lost the ref of popoverRef at this point so its anyway null and we cant access the children
    if (this.state.isOpen && isFullHeight) {
      toggleBodyScrolllock(false, null);
    }
  }

  public render() {
    const {
      position = POPOVER_POSITION_TOP,
      align = POPOVER_ALIGN_LEFT,
      children,
      className,
      isRenderedAsInlineHtml,
    } = this.props;

    /** helper function to return all needed props for a popover */
    const getPopoverProps: popupPropsFunc = (props = {}) => ({
      className: classNames({
        [clsPopover]: true,
        [`${clsPopover}--pos-${position}`]: true,
        [`${clsPopover}--align-${align}`]: !!align,
        [`${props.className}`]: !!props.className,
      }),
      ref: this.popoverRef,
    });

    /** helper function to return all needed props for a togggler */
    const getTogglerProps: togglerPropsFunc = (props = {}) => ({
      className: classNames({
        [`${clsToggle}`]: true,
        [props.className!]: !!props.className,
      }),
      onClick: this.props.disabled ? undefined : callAll(this.toggle, props.onClick!),
    });

    /** renderprops api */
    const renderPropsConfig = {
      callAll,
      getPopoverProps,
      getTogglerProps,
      hide: this.hide,
      isOpen: this.state.isOpen,
      renderProps,
      show: this.show,
      toggle: this.toggle,
    };

    /** renderprops partial api */
    const partialrp = {
      ...renderPropsConfig,
      renderProps: renderPropsPartial(renderPropsConfig),
    };

    const clsAnchor = classNames({
      [`${cls}anchor`]: true,
      [`${cls}anchor--inline`]: !!isRenderedAsInlineHtml,
    });

    return (
      <div
        className={classNames(className, clsAnchor)}
        ref={this.anchorRef}
      >
        {renderProps(children, partialrp)}
      </div>
    );
  }

  private show(e?: EventType) {
    this.setState({ isOpen: true }, () => this.handleShow(e));
  }

  private hide = (e?: EventType) => {
    this.setState({ isOpen: false }, () => this.handleHide(e));
  }

  private toggle = (e?: EventType) => {
    const { isOpen } = this.state;
    isOpen ? this.hide(e) : this.show(e);
  }

  private handleShow(e?: EventType) {
    const { closeOnClickOutside = true, onShow, isFullHeight } = this.props;
    if (this.popoverRef.current && isFullHeight) {
      // alows scrolling on IOs of lp_popovermenu__layout--inner when body-scrollock is active
      toggleBodyScrolllock(true, this.popoverRef.current.children[0]);
    }
    closeOnClickOutside && document.addEventListener('click', this.handleOutsideClick, false);
    this.updatePopover();
    onShow && onShow(e);
  }

  private handleHide(e?: EventType) {
    const { closeOnClickOutside = true, onHide } = this.props;
    toggleBodyScrolllock(false, null);
    closeOnClickOutside && document.removeEventListener('click', this.handleOutsideClick, false);
    onHide && onHide(e);
  }

  /**
   * dynamic popover behvior
   * - calculate the height of the popover when autoHeight is set
   */
  private updatePopover() {
    if (!this.popoverRef.current || !this.anchorRef.current) {
      return;
    }

    if (!this.props.isFullHeight) {
      return;
    }

    const popover = this.popoverRef.current;
    const anchor = this.anchorRef.current;
    const anchorRect = anchor.getBoundingClientRect();
    const style = window.getComputedStyle(anchor);

    if (style.position === 'static') {
      popover.style.top = anchorRect.height + 'px';
    }

    popover.style.height = window.innerHeight - anchorRect.top - anchorRect.height + 'px';
  }

  /**
   * when clicked outside the popover then we want to clode the popover
   */
  private handleOutsideClick(e: EventType) {
    if (!this.popoverRef || !this.popoverRef.current) {
      return;
    }

    if (this.popoverRef.current.contains(e.target as Node)) {
      return;
    }

    this.hide(e);
  }
}

export default PopOver;
