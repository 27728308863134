import React, { FunctionComponent, useCallback } from 'react';
import api from '../../../api';
import { textResources } from '../../../lang/de';
import Icon, { IconSize } from '../../forms/icon/icon';
import './remove_link.scss';

const sharedLabels = textResources.shared;

interface RemoveLinkProps {
  linkId: number | undefined;
  onDelete: () => void;
  size?: IconSize;
}

const RemoveLink: FunctionComponent<RemoveLinkProps> = ({ linkId, onDelete, size }) => {
  const deleteLink = useCallback(() => {
    api.factSheet.deleteLink(`${linkId as number}`).then(() => {
      onDelete();
    });
  }, [linkId, onDelete]);

  return linkId ? <div onClick={deleteLink} className={'remove-link'}>
    <span className={'remove-link__title'}>{sharedLabels.delete}</span>
    <Icon
      name={'delete'}
      size={size}
      description={sharedLabels.delete}
    />
  </div> : null;
};

export default RemoveLink;
