import { useSelectCurrentProfile } from 'src/reducers/user/user_hooks';
import { IUserProfile } from '../../../../../../interfaces/user';
import { getMessengerToggle, isMessengerFlagEnabled, isUserMessagable } from './helpers';

export const useMessenger = (user?: IUserProfile) => {
  const currentProfile = useSelectCurrentProfile();
  const enabled = isMessengerFlagEnabled() && isUserMessagable(user || currentProfile);

  return {
    enabled,
    showToggle: getMessengerToggle(currentProfile.type),
  };
};
