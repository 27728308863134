// libs
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants';
import { IMapTogglerProps } from 'src/containers/map_toggler';
import { COLOR_TYPE_GRAY_TINT_9, COLOR_TYPE_PRIMARY } from 'src/utils/color';

// components
import Icon from 'src/components/forms/icon/icon';

// helpers
import { textResources } from 'src/lang/de';
import './map_toggler.scss';

const cls = CLASS_PREFIX + 'map-toggler';
const labels = textResources.screenReaderText;

const MapToggler: React.FC<IMapTogglerProps> = ({ isMapShown, hideMap, showMap }) => {
  const onClickAction = isMapShown ? hideMap : showMap;
  const label = isMapShown ? labels.openMap : labels.closeMap;
  const color = isMapShown ? COLOR_TYPE_PRIMARY : COLOR_TYPE_GRAY_TINT_9;
  const description = isMapShown ? labels.closeMap : labels.openMap;
  return (
    <button className={cls + '__button'} onClick={onClickAction}>
      <Icon name='map' size={26} color={color} aria-label={label} description={description} />
    </button>
  );
};

export default MapToggler;
