// libs
import { History } from 'history';

// interfaces / constants
import { IAction } from 'src/actions/';
import {
  PROFILES_LIST_GET_PROFILES_PENDING,
  PROFILES_LIST_GET_PROFILES_SUCCESS,
  PROFILES_LIST_GET_PROFILES_ERROR,
} from 'src/actions/profiles_list/profiles_list';
import { IErrorResponse } from 'src/api/interfaces/errors';
import { RECORD_NOT_FOUND } from 'src/constants/api_error_codes';
import {
  GROUP_MEMBERS_DEFAULT_PER_PAGE,
  GROUPS_DEFAULT_PER_PAGE,
  JOINED_GROUPS_DEFAULT_PER_PAGE,
} from 'src/constants/groups';
import {
  FRONTEND_NOT_FOUND_PATH,
} from 'src/constants/urls';
import { IGroup, IGroupList } from 'src/interfaces/group';

// helpers
import { ThunkAction } from 'redux-thunk';
import api from 'src/api/';
import { IProfilesInGroupListCategory } from 'src/interfaces/profiles_list';
import { IRootState } from 'src/reducers/interface';
import { OrderDirection } from '../../api/interfaces/responses/shared';

const GROUPS_FETCH_MORE_GROUPS = 'GROUPS_FETCH_MORE_GROUPS';

const GROUPS_FETCH_JOINED_GROUPS = 'GROUPS_FETCH_JOINED_GROUPS';

const GROUPS_FETCH_GROUP = 'GROUPS_FETCH_GROUP';
const GROUPS_FETCH_GROUP_PENDING = 'GROUPS_FETCH_GROUP_PENDING';
const GROUPS_FETCH_GROUP_ERROR = 'GROUPS_FETCH_GROUP_ERROR';

export const GROUPS_FETCH_MORE_GROUPS_PENDING = 'GROUPS_FETCH_MORE_GROUPS_PENDING';
export const GROUPS_FETCH_MORE_GROUPS_ERROR = 'GROUPS_FETCH_MORE_GROUPS_ERROR';
export const GROUPS_FETCH_MORE_GROUPS_SUCCESS = 'GROUPS_FETCH_MORE_GROUPS_SUCCESS';

export const GROUPS_FETCH_JOINED_GROUPS_PENDING = 'GROUPS_FETCH_JOINED_GROUPS_PENDING';
export const GROUPS_FETCH_JOINED_GROUPS_ERROR = 'GROUPS_FETCH_JOINED_GROUPS_ERROR';
export const GROUPS_FETCH_JOINED_GROUPS_SUCCESS = 'GROUPS_FETCH_JOINED_GROUPS_SUCCESS';

export const GROUP_ADD_JOINED = 'GROUPS_ADD_JOINED_GROUP';
export const GROUP_CLEAR_ALL = 'GROUP_CLEAR_ALL';
export const GROUPS_FETCH_GROUP_SUCCESS = 'GROUPS_FETCH_GROUP_SUCCESS';

export interface IFetchMoreGroupsActionPending {
  meta: {
    page?: number;
  };
  type: typeof GROUPS_FETCH_MORE_GROUPS_PENDING;
}

export interface IFetchMoreGroupsActionError {
  meta: {
    page?: number;
  };
  payload: IErrorResponse | Error;
  type: typeof GROUPS_FETCH_MORE_GROUPS_ERROR;
}

export interface IFetchMoreGroupsActionSuccess {
  meta: {
    page?: number;
  };
  payload: IGroupList;
  type: typeof GROUPS_FETCH_MORE_GROUPS_SUCCESS;
}

export interface IFetchJoinedGroupsActionPending {
  type: typeof GROUPS_FETCH_JOINED_GROUPS_PENDING;
}

export interface IFetchJoinedGroupsActionError {
  payload: IErrorResponse | Error;
  type: typeof GROUPS_FETCH_JOINED_GROUPS_ERROR;
}

export interface IFetchJoinedGroupsActionSuccess {
  payload: IGroupList;
  type: typeof GROUPS_FETCH_JOINED_GROUPS_SUCCESS;
}

export interface IFetchGroupActionPending {
  type: typeof GROUPS_FETCH_GROUP_PENDING;
}

export interface IFetchGroupActionError {
  type: typeof GROUPS_FETCH_GROUP_ERROR;
}

export interface IFetchGroupActionSuccess {
  payload: IGroup;
  type: typeof GROUPS_FETCH_GROUP_SUCCESS;
}

export interface IAddJoinedGroupAction {
  payload: IGroup;
  type: typeof GROUP_ADD_JOINED;
}

export interface IClearAllGroupsAction {
  type: typeof GROUP_CLEAR_ALL;
}

export enum GroupsSorting {
  NAME = 'name',
  LATEST_POST = 'latest_post'
}

export interface GroupsSortingSettings {
  sortBy: GroupsSorting;
  orderBy: OrderDirection;
}

export const fetchMoreGroups = (sortingSettings?: GroupsSortingSettings, page?: number): IAction => ({
  meta: {
    page,
  },
  payload: api.group.more({ page, perPage: GROUPS_DEFAULT_PER_PAGE, ...sortingSettings }),
  type: GROUPS_FETCH_MORE_GROUPS,
});

export const fetchJoinedGroups = (sortingSettings?: GroupsSortingSettings): IAction => ({
  payload: api.group.joined({ page: 1, perPage: JOINED_GROUPS_DEFAULT_PER_PAGE, ...sortingSettings }),
  type: GROUPS_FETCH_JOINED_GROUPS,
});

export const joinGroup = (group: IGroup): IAddJoinedGroupAction => ({
  payload: group,
  type: GROUP_ADD_JOINED,
});

export const clearAllGroups = (): IClearAllGroupsAction => ({
  type: GROUP_CLEAR_ALL,
});

const loadGroupMembers = () => ({ type: PROFILES_LIST_GET_PROFILES_PENDING });

const finishedLoadingGroupMembers = (payload: IProfilesInGroupListCategory | null) => ({
  meta: { storeKey: 'profileList' },
  payload,
  type: PROFILES_LIST_GET_PROFILES_SUCCESS,
});

const failedLoadingGroupMembers = (error: Error) => ({
  error,
  meta: { storeKey: 'profileList' },
  type: PROFILES_LIST_GET_PROFILES_ERROR,
});

type GetMembersThunk = ThunkAction<Promise<void> | undefined, IRootState, {}, IAction>;

interface FetchMembersConfig {
  path: string;
  groupIdentifier: string;
  page?: number;
  refresh?: boolean;
}

export const fetchMembers = (params: FetchMembersConfig): GetMembersThunk => {
  return async(dispatch, getState) => {
    const state = getState();

    if (!params.refresh) {
      if (state.profilesList.isLoading || state.profilesList.pagination.currentPage >= (params.page ?? 1)) {
        return;
      }
    }

    dispatch(loadGroupMembers());

    await api.groupMember.list({ perPage: GROUP_MEMBERS_DEFAULT_PER_PAGE, ...params })
      .then((result) => {
        dispatch(finishedLoadingGroupMembers(result));
      })
      .catch((error) => {
        dispatch(failedLoadingGroupMembers(error));
      });
  };
};

export const getGroup = (identifier: string, history: History): IAction => ({
  payload: api.group.get(identifier)
    .catch((error: IErrorResponse) => {
      if (error.code === RECORD_NOT_FOUND) {
        history.push(FRONTEND_NOT_FOUND_PATH);
      }
      throw error;
    }),
  type: GROUPS_FETCH_GROUP,
});
