// libs
import classNames from 'classnames';
import { History } from 'history';
import * as React from 'react';
// interfaces / constants
import { THUMBNAIL_SIZE_TYPE_XLARGE } from 'src/components/thumbnail/thumbnail';
import { PRIVATE_PROFILE_TYPE } from 'src/constants/profile';
import { BRONZE, SILVER } from 'src/constants/shape_sponsors';
import {
  FOLLOWERS_PARTIAL, FRONTEND_EDIT_ABOUT_ADD_AUDIO_LINK, FRONTEND_EDIT_ABOUT_ADD_VIDEO_LINK,
  FRONTEND_EDIT_DESCRIPTION_PATH,
  FRONTEND_EDIT_IMPRESSUM_PATH,
  FRONTEND_EDIT_INTERNET_SITE_PATH,
  FRONTEND_EDIT_TAGS_PATH,
  FRONTEND_SETTINGS_PATH,
} from 'src/constants/urls';
import { ILocationShape, IPosition } from 'src/interfaces/location';
import { IProfile, ProfileType } from 'src/interfaces/profile';
import { IUserProfile, IUserProfileType } from 'src/interfaces/user';
import { COLOR_TYPE_GRAY_TINT_9, COLOR_TYPE_PRIMARY } from 'src/utils/color';

// classes
import Avatar from 'src/components/avatar/avatar';
import Button, { BUTTON_TYPE_CONTAINED } from 'src/components/forms/button/button';
import Icon from 'src/components/forms/icon/icon';
import NavigationItem from 'src/components/navigation_item/navigation_item';
import Tag from 'src/components/tag/tag';
import FollowButton from 'src/containers/smart_components/follow_button/follow_button';
import UserOnboardingCTA from 'src/containers/smart_components/user_onboarding_cta/user_onboarding_cta';

// helpers
import Translate, { textResources } from 'src/lang/de';
import { getSponsorBadge } from 'src/utils/badges';
import { createBemBlock } from 'src/utils/bem_helper/bem_helper';
import { getConfigValue } from 'src/utils/config/config';
import { isAuthorityProfile, isBlogProfile, isPressProfile, isPrivateProfile } from 'src/utils/profile';
import { makeHTMLLinksFromText } from 'src/utils/string/string';
import { UrlUtils } from 'src/utils/url/url';

import { AudioPreview } from '@lokalportal/frontends-shared/lib/sharedComponents/AudioPreview/AudioPreview';
import { VideoPreview } from '@lokalportal/frontends-shared/lib/sharedComponents/VideoPreview/VideoPreview';

import 'src/components/profile/profile_info.scss';
import {
  isMessengerFlagEnabled,
  isUserMessagable,
} from '../settings/settings_wizard/steps/settings_overview/messenger_toggle/helpers';
import PrivateAnswers from './answers/private/private';
import PublicAnswers from './answers/public/public';
import RemoveLink from './remove_link/remove_link';

interface IProps {
  position?: IPosition;
  changePosition: (locationShape: ILocationShape) => void;
  profile: IProfile;
  history: History;
  userType: IUserProfileType;
  isMessengerEnabled: boolean;
}

interface IState {
  profile?: IProfile;
  showImprint: boolean;
  showMenu: boolean;
  showAudioLink: boolean;
  showVideoLink: boolean;
}

const cls = createBemBlock('profile-info');
const labels = textResources.profile;
const sharedLabels = textResources.shared;

const getProfileLabel = (
  profile: IProfile,
  locationShapeName: string | undefined,
  showLocation: boolean | undefined,
): string | null => {
  const profileType = profile.type;

  if (profile.sponsorLevel) {
    switch (profile.sponsorLevel) {
      case BRONZE:
        return textResources.shapeSponsors.bronzePartner;
      case SILVER:
        return textResources.shapeSponsors.silverPartner;
    }
  }

  if (isAuthorityProfile(profileType)) {
    return labels.authorityProfile;
  }

  return getLabelWithLocation(profileType, locationShapeName, showLocation);
};

const getLabelWithLocation = (profileType: ProfileType, locationShapeName?: string, showLocation?: boolean) => {
  if (isPrivateProfile(profileType)) {
    return showLocation ? `${labels.neighborFromMale} ${locationShapeName}` : labels.neighborMale;
  }
  if (isPressProfile(profileType)) {
    return showLocation ? `${labels.pressProfile} ${locationShapeName}` : labels.press;
  }
  if (isBlogProfile(profileType)) {
    return showLocation ? `${labels.blogProfile} ${locationShapeName}` : labels.blog;
  }
  return Translate.authorType[profileType];
};

class ProfileInfo extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showAudioLink: true,
      showImprint: false,
      showMenu: false,
      showVideoLink: true,
    };

    this.onMessage = this.onMessage.bind(this);
    this.toggleImprint = this.toggleImprint.bind(this);
    this.renderEditIcon = this.renderEditIcon.bind(this);
    this.renderVerifiedIcon = this.renderVerifiedIcon.bind(this);
    this.renderAnswers = this.renderAnswers.bind(this);
    this.renderOwnAnswers = this.renderOwnAnswers.bind(this);
  }

  public componentDidMount() {
    this.setAppLocation();
  }

  public componentDidUpdate(prevProps: IProps) {
    const { profile } = this.props;
    if (prevProps.profile !== profile) {
      this.setState({ profile });
    }

    this.setAppLocation();
  }

  public render() {
    const { profile } = this.props;
    const { imageUrl, name, locationShape, sponsorLevel } = profile;
    const { name: locationShapeName, slug } = locationShape || {};

    return (
      <div className={cls()}>
        <div className={cls('tile')}>
          <div className={cls('header')}>
            <Avatar
              rounded={false}
              curved={true}
              image={imageUrl}
              size={THUMBNAIL_SIZE_TYPE_XLARGE}
              badge={sponsorLevel && getSponsorBadge(sponsorLevel)}
            />
            <div className={cls('title')}>
              <h1 className={cls('name')}>{name}</h1>
              {slug && <p className={cls('description')}>
                {getProfileLabel(profile, locationShapeName, getConfigValue('featureFlags.map.enabled'))}
                {this.renderVerifiedIcon()}
              </p>}
            </div>
          </div>
          {this.renderContent()}
          {this.renderAnswers()}
          {this.renderPublicInfo()}
          {this.renderImprint()}
          {this.renderTags()}
          {/* TODO enable when backend is fixed {this.renderMyGroups()} */}
          {this.renderLinks()}
          {this.renderToolbar()}
        </div>
      </div>
    );
  }

  private renderLinks() {
    if (getConfigValue('featureFlags.factSheet')) {
      return <>
          {this.renderAudioLink()}
          {this.renderVideoLink()}
        </>;
    }
    return null;
  }

  private renderAudioLink() {
    const { profile: { profileLinks, meta } } = this.props;
    const { showAudioLink } = this.state;
    const audioLink = profileLinks.find(({ platform }) => platform === 'audio');

    if (!audioLink?.url && !meta.isOwn) {
      return null;
    }

    const onDelete = () => {
      this.setState({
        showAudioLink: false,
      });
    };

    return (
      <div className={classNames(cls('content'), cls('content', 'no-border'))}>
        <div>
          <span className={cls('content-title')}>
            {meta.isOwn ? textResources.onboardingSettings.addAudioLink : textResources.onboardingSettings.myAudioLink}
          </span>
          {meta.isOwn && this.renderEditIcon(FRONTEND_EDIT_ABOUT_ADD_AUDIO_LINK) }
        </div>
        {showAudioLink &&
          <>
            <div className={cls('profile-links')}>
              <AudioPreview url={audioLink?.url} />
            </div>
            {meta.isOwn && <RemoveLink linkId={audioLink?.id} onDelete={onDelete} size={12} />}
          </>
        }
      </div>
    );
  }

  private renderVideoLink() {
    const { profile: { profileLinks, meta } } = this.props;
    const { showVideoLink } = this.state;
    const videoLink = profileLinks.find(({ platform }) => platform === 'video');

    if (!videoLink?.url && !meta.isOwn) {
      return null;
    }

    const onDelete = () => {
      this.setState({
        showVideoLink: false,
      });
    };

    return (
      <div className={classNames(cls('content'), cls('content', 'no-border'))}>
        <div>
          <span className={cls('content-title')}>
            {meta.isOwn ? textResources.onboardingSettings.addVideoLink : textResources.onboardingSettings.myVideoLink}
          </span>
          {meta.isOwn && this.renderEditIcon(FRONTEND_EDIT_ABOUT_ADD_VIDEO_LINK) }
        </div>
        {showVideoLink &&
          <>
            <div className={cls('profile-links')}>
              <VideoPreview url={videoLink?.url}/>
            </div>
            {meta.isOwn && <RemoveLink linkId={videoLink?.id} onDelete={onDelete} size={12} />}
          </>
        }
      </div>
    );
  }

  private renderAnswers() {
    if (getConfigValue('featureFlags.factSheet')) {
      const { profile } = this.props;
      if (profile.meta.isOwn) {
        return this.renderOwnAnswers();
      }
      return this.renderPublicAnswers();
    }
    return null;
  }

  private renderOwnAnswers() {
    const { profile } = this.props;
    return <PrivateAnswers
      answers={profile.questionsAnswers}
      editIcon={this.renderEditIcon}
    />;
  }

  private renderPublicAnswers() {
    const { profile } = this.props;
    return <PublicAnswers answers={profile.questionsAnswers}/>;
  }

  private renderToolbar(): JSX.Element | null {
    const { profile } = this.props;

    // for the right aligned design we styled this in reversed order from right to left
    if (profile.meta.isOwn) {
      return this.renderToolbarWithContent(this.renderOwnProfilesToolbar(profile));
    }

    return this.renderOtherProfilesToolbar(profile);
  }

  private renderToolbarWithContent(content: JSX.Element | null) {
    return <div className={cls('toolbar')}>
      {content}
    </div>;
  }

  private renderOwnProfilesToolbar(profile: IProfile): JSX.Element | null {
    const { isFollowable } = profile.meta;

    return (
      <>
        <NavigationItem
          target={'_self'}
          url={FRONTEND_SETTINGS_PATH}
          className={cls('edit-button')}
        >
          {textResources.profile.editProfile}
        </NavigationItem>
        {isFollowable && <NavigationItem
          target={'_self'}
          url={UrlUtils.getProfileFrontendPath(profile.id, profile.type) + FOLLOWERS_PARTIAL}
        >
          {textResources.follow.showFollowers}
        </NavigationItem>}
      </>
    );
  }

  private renderOtherProfilesToolbar(profile: IProfile) {
    const { isMessagable, isFollowable } = profile.meta;
    const { canMessage } = profile.permissions;

    let enableMessenger = isMessagable;
    if (isMessengerFlagEnabled()) {
      if (isUserMessagable(profile as unknown as IUserProfile)) {
        enableMessenger = this.props.isMessengerEnabled;
      } else {
        enableMessenger = false;
      }
    }

    if (isFollowable) {
      return this.renderToolbarWithContent(
        <>
          {enableMessenger && <UserOnboardingCTA active={!canMessage}>
            <NavigationItem target={'_self'} onClick={this.onMessage}>
              {sharedLabels.writeMessage}
            </NavigationItem>
          </UserOnboardingCTA>}
          <FollowButton
            followableId={profile.id}
          />
        </>
      );
    }

    if (enableMessenger) {
      return this.renderToolbarWithContent(
        <UserOnboardingCTA active={!canMessage} className={cls('feature-blocker')}>
          <Button
            onClick={this.onMessage}
            label={sharedLabels.sayHello}
            variant={BUTTON_TYPE_CONTAINED}
            fullWidth
            color={COLOR_TYPE_GRAY_TINT_9}
            lowerCase
            primary={true}
          />
        </UserOnboardingCTA>
      );
    }

    return null;
  }

  private renderContent(): JSX.Element | null {
    const { profile: { description, meta } } = this.props;

    if (!description && !meta.isOwn) {
      return null;
    }
    const message =
      makeHTMLLinksFromText(description) || textResources.onboardingSettings.profileDescriptionPlaceholder;
    return (
      <div className={cls('content')}>
        <p className={cls('content-title')}>{labels.aboutMe}</p>
        {meta.isOwn
          ? <p>
            <span className={cls('content-body')} dangerouslySetInnerHTML={{ __html: message }}/>
            {this.renderEditIcon(FRONTEND_EDIT_DESCRIPTION_PATH)}
          </p>
          : <p className={cls('content-body')} dangerouslySetInnerHTML={{ __html: message }}/>
        }
      </div>
    );
  }

  private renderVerifiedIcon(): JSX.Element | null {
    if (!isAuthorityProfile(this.props.profile.type)) {
      return null;
    }

    return <Icon
      className={cls('icon-verified')}
      name={'checkmark'}
      size={14}
      description={labels.authorityProfile}
      color={COLOR_TYPE_PRIMARY}
    />;
  }

  private renderEditIcon(url: string): JSX.Element | null {
    if (!this.props.profile.meta.isOwn) {
      return null;
    }
    return (
      <NavigationItem url={url} target='_self' className={cls('icon')}>
        <Icon
          className={cls('icon', 'right')}
          name={'pencil'}
          size={12}
          description={sharedLabels.edit}
        />
      </NavigationItem>
    );
  }

  private renderPublicInfo(): JSX.Element | null {
    const { profile: { type, meta, siteNotice, website } } = this.props;
    if (type === PRIVATE_PROFILE_TYPE) {
      return null;
    }

    const clsActive = {
      [cls('item', 'active')]: this.state.showImprint,
    };

    return (
      <ul className={cls('public')}>
        {(website || meta.isOwn) &&
          <li className={cls('item')}>
            <NavigationItem url={website} target={'_blank'} className={cls('navigation')}>
              <Icon name={'world'} className={cls('icon', 'left')} size={16} color={COLOR_TYPE_GRAY_TINT_9}/>
              <span>{website || 'Webseite'}</span>
            </NavigationItem>
            {this.renderEditIcon(FRONTEND_EDIT_INTERNET_SITE_PATH)}
          </li>
        }
        {(siteNotice || meta.isOwn) &&
          <li className={classNames(cls('item'), clsActive)}>
            <button onClick={this.toggleImprint} disabled={!siteNotice} className={cls('navigation')}>
              <Icon name={'imprint'} className={cls('icon', 'left')} size={16} color={COLOR_TYPE_GRAY_TINT_9}/>
              {sharedLabels.imprint}
            </button>
            {this.renderEditIcon(FRONTEND_EDIT_IMPRESSUM_PATH)}
          </li>
        }
      </ul>
    );
  }

  private renderImprint(): JSX.Element | null {
    const { profile } = this.props;
    if (!this.state.showImprint || !profile.siteNotice) {
      return null;
    }

    const siteNoticeWithClickableLinks = makeHTMLLinksFromText(profile.siteNotice);

    return (
      <div className={cls('imprint')}>
        <p dangerouslySetInnerHTML={{ __html: siteNoticeWithClickableLinks }}/>
      </div>
    );
  }

  private renderTags(): JSX.Element | null {
    const { profile: { tags, meta } } = this.props;

    if (!tags || tags.length === 0 && !meta.isOwn) {
      return null;
    }

    return (
      <div className={classNames(cls('content'), cls('content', 'no-border'))}>
        <p className={cls('content-title')}>{labels.myInterests}</p>
        {tags.length > 0 &&
          <div className={cls('tags')}>
            {tags.map(tag => (<Tag text={tag.name} key={tag.name} className={cls('tag')} isClickable/>))}
          </div>
        }
        {tags.length === 0 && meta.isOwn &&
          <p className={cls('add-tags-cta')}>
            {labels.giveYourInterests}
          </p>
        }
        {meta.isOwn &&
          <NavigationItem url={FRONTEND_EDIT_TAGS_PATH}>
            <Icon
              name='plus-circle'
              description={sharedLabels.addInterests}
              size={20}
              color={COLOR_TYPE_GRAY_TINT_9}
              className={cls('add-tag-icon')}
            />
          </NavigationItem>
        }
      </div>
    );
  }

  // private renderMyGroups() {
  //   if (getConfigValue('featureFlags.factSheet')) {
  //     const { profile: { member_of } } = this.props;
  //     if (!member_of || member_of.length === 0) {
  //       return null;
  //     }
  //
  //     return (
  //       <div className={classNames(cls('content'), cls('content', 'no-border'))}>
  //         <p className={cls('content-title')}>{labels.myGroups}</p>
  //         <div className={cls('tags')}>
  //           {member_of.map(tag => (
  //             <Tag
  //               url={UrlUtils.groupPage(tag.slug)}
  //               text={tag.name}
  //               key={tag.slug}
  //               className={cls('tag')} isClickable
  //             />))
  //           }
  //         </div>
  //       </div>);
  //   }
  //
  //   return null;
  // }

  private toggleImprint() {
    this.setState({
      showImprint: !this.state.showImprint,
    });
  }

  private onMessage() {
    const { profile, history } = this.props;
    const { id, type } = profile;
    history.push(UrlUtils.sendMessageConversationPage(type, id));
  }

  private setAppLocation() {
    const { changePosition, profile, position } = this.props;
    // only set the app-position to the seen profile in deeplinking when no position from other sources
    if (!position && profile.locationShape) {
      changePosition(profile.locationShape);
    }
  }
}

export default ProfileInfo;
