import classNames from 'classnames';
import * as React from 'react';
import { RouteComponentProps, match } from 'react-router-dom';

// components
import Button, { BUTTON_TYPE_CONTAINED } from 'src/components/forms/button/button';
import MapButton from 'src/components/map/map_controls/buttons/map_button/map_button';
import ZoomButton from 'src/components/map/map_controls/buttons/zoom_button/zoom_button';
import SnackBar from 'src/components/snack_bar/snack_bar';
import AppLocationPicker from 'src/containers/smart_components/app_location_picker/app_location_picker';

// interfaces
import { IMapControlsProps } from 'src/containers/map_controls';
import { IAddress } from 'src/interfaces/address';
import { ILocationShape, IBoundingBox, IPosition } from 'src/interfaces/location';

// constants
import { CLASS_PREFIX } from 'src/constants/';
import { getConfigValue } from 'src/utils/config/config';

// helpers
import { textResources } from 'src/lang/de';
import { COLOR_TYPE_PRIMARY } from 'src/utils/color';
import initTracker, { MAP_CONTROLS } from 'src/utils/reporting/events_tracking/events_tracking';
import { reportError } from 'src/utils/reporting/report-errors';

// assets
import './map_controls.scss';

const cls = CLASS_PREFIX + 'map-controls';
const clsButtonGroup = cls + '__button-group';
const trackMapControls = initTracker(MAP_CONTROLS);

export interface IState {
  error: boolean;
  showLocationPicker: boolean;
}

interface IProps extends IMapControlsProps, RouteComponentProps {
  navigateToShape: (match: match, locationShape: ILocationShape) => void;
  navigateToPositionURL: (match: match, position: IPosition, boundingBox?: IBoundingBox) => Promise<Object>;
}

class MapControls extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      error: false,
      showLocationPicker: false,
    };

    this.toggleLocationPicker = this.toggleLocationPicker.bind(this);
    this.handleOnCloseLocationPicker = this.handleOnCloseLocationPicker.bind(this);
    this.handleTakeMeHome = this.handleTakeMeHome.bind(this);
    this.handleSearchMapPosition = this.handleSearchMapPosition.bind(this);
    this.showTakeMeHome = this.showTakeMeHome.bind(this);
    this.closeError = this.closeError.bind(this);
    this.onPickLocation = this.onPickLocation.bind(this);
  }

  private closeError() {
    this.setState({ error: false });
  }

  public componentDidUpdate(prevProps: IMapControlsProps) {
    if (prevProps.activePopupMenu === 'map' && this.props.activePopupMenu !== 'map' &&
      this.state.showLocationPicker !== false) {
      this.setState({ showLocationPicker: false });
    }
  }

  public render() {
    const { isLocked } = this.props;

    if (isLocked) {
      return this.renderLocked();
    }

    const {
      showSearchAreaButton,
      zoomActions: {
        zoomIn,
        zoomOut,
      },
    } = this.props;

    const { error, showLocationPicker } = this.state;

    const showToggleLocationPicker = !getConfigValue<IAddress>('featureFlags.location.defaultAddress');

    return (
      <div className={cls}>
        <div className={cls + '__top-button-group'}>
          {showToggleLocationPicker && <MapButton type='search' onClick={this.toggleLocationPicker} hideOnMobile />}
          {showSearchAreaButton &&
          <div className={cls + '__search-area'}>
            <Button
              color={COLOR_TYPE_PRIMARY}
              label={textResources.map.searchThisArea}
              variant={BUTTON_TYPE_CONTAINED}
              onClick={this.handleSearchMapPosition}
              lowerCase
            />
          </div>
          }
          {error &&
          <SnackBar
            showClose
            message={textResources.map.failedGetLocationError}
            onClose={this.closeError}
          />
          }
          <div/>
        </div>

        <div className={classNames(clsButtonGroup, clsButtonGroup + '--right')}>
          {this.showTakeMeHome() && <MapButton type='home' onClick={this.handleTakeMeHome} />}
          <ZoomButton
            zoomIn={zoomIn}
            zoomOut={zoomOut}
            hideOnMobile
          />
          {showToggleLocationPicker && <MapButton type='search' onClick={this.toggleLocationPicker} hideOnDesktop />}
        </div>

        {showLocationPicker && (
          <div className={`${cls}__location-picker-container`}>
            <AppLocationPicker
              onPick={this.onPickLocation}
              tracking={{ tracker: trackMapControls, trackingObj: MAP_CONTROLS }}
              onClose={this.handleOnCloseLocationPicker}
              outlined={true}
            />
          </div>
        )}
      </div>
    );
  }

  private onPickLocation() {
    this.setState({ showLocationPicker: false });
    this.props.hideMap();
  }

  private handleOnCloseLocationPicker() {
    this.setState({ showLocationPicker: false });
  }

  private handleTakeMeHome() {
    this.setState({ showLocationPicker: false });
    const { hideMap, profile: { address }, history, navigateToShape, match } = this.props;
    if (!address) {
      // TODO check if this is the correct fallback
      history.push('/');
      return;
    }
    navigateToShape(match, address.locationShape);
    trackMapControls(MAP_CONTROLS.ACTIONS.TAKE_ME_HOME, MAP_CONTROLS.LABELS.START);
    hideMap();
  }

  private handleSearchMapPosition() {
    const { hideMap, match, mapPosition, mapBoundingBox, navigateToPositionURL } = this.props;
    if (!mapPosition || !mapBoundingBox) {
      reportError('search map position called with no mapPosition', this.props);
      return;
    }
    navigateToPositionURL(match, mapPosition, mapBoundingBox).catch(() => {
      this.setState({ error: true });
      this.props.backToPrevious();
    });
    trackMapControls(MAP_CONTROLS.ACTIONS.SHOW_POSTS_FROM_HERE, MAP_CONTROLS.LABELS.START);
    hideMap();
  }

  private renderLocked() {
    return (
      <div/>
    );
  }

  private toggleLocationPicker() {
    const { showLocationPicker } = this.state;

    this.setState({
      showLocationPicker: !showLocationPicker,
    });

    trackMapControls(MAP_CONTROLS.ACTIONS.OPEN_LOCATION_PICKER, MAP_CONTROLS.LABELS.START);
  }

  private showTakeMeHome(): IAddress | undefined {
    const { profile } = this.props;
    if (!profile.meta.isHome) {
      return profile.address;
    }
    return undefined;
  }
}

export default MapControls;
