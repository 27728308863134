// libs
import React, { useCallback } from 'react';

// components
import FloatingButtonMenu from 'src/components/floating_button_menu/floating_button_menu';

// interfaces & constants
import { IContentItemType } from 'src/interfaces/posts';
import { textResources } from 'src/lang/de';

// helpers
import { useChatTopicTargetsShape } from 'src/components/chat_page/utils';

// assets
import { useCreatePostWizard } from '../../../components/post/create/useCreatePostWizard';
import addImagePrimary from './add.svg';

interface IProps {
  postType?: IContentItemType;
  remAboveBottom?: number;
  featureBlocked: boolean;
  initiallyExpanded?: boolean;
}

const labels = textResources.postCreate;

const FloatingPostCreate: React.FC<IProps> = ({
  remAboveBottom,
  featureBlocked,
  initiallyExpanded,
  postType,
}) => {
  const openCreatePostWizard = useCreatePostWizard();

  const handleClick = useCallback(() => {
    openCreatePostWizard(true, postType);
  }, [openCreatePostWizard, postType]);

  // part of topic-chat POC test
  // checks if there is currently a chatTopic running for the current Shape
  const chatTopicForCurrentShape = useChatTopicTargetsShape();
  const remAboveBottomWithChatConsidered =
    chatTopicForCurrentShape && chatTopicForCurrentShape.open ? 4 : remAboveBottom;

  return (
    <FloatingButtonMenu
      image={addImagePrimary}
      title={labels.createPostTitle}
      onClick={handleClick}
      remAboveBottom={remAboveBottomWithChatConsidered}
      initiallyExpanded={initiallyExpanded}
      featureBlocked={featureBlocked}
    />
  );
};

export default FloatingPostCreate;
