// libs
import classNames from 'classnames';
import * as React from 'react';

// interfaces / constants
import { itemSegmentCls } from 'src/components/item_segment/base/item_segment_base';

// classes
import Badge, { BadgeColor, BadgeType } from 'src/components/badge/badge';

// helpers
import './segment_badge.scss';

interface IProps {
  value: number;
  className: string;
  type: BadgeType;
  color?: BadgeColor;
}

const cls = itemSegmentCls + 'badge';

const SegmentBadge: React.FC<IProps> = ({ value, className, type, color }) => (
  <Badge
    value={value}
    size='large'
    className={classNames(cls, className)}
    type={type}
    color={color}
  />
);

export default SegmentBadge;
