// libs
import React, { useContext, useState } from 'react';

// interfaces / constants
import { IRenderAPI } from 'src/components/_libs/popover_lib/popover_lib';
import { NavigationFeatureFlags } from 'src/components/feature_flag/navigation_feature_flag/model';
import { CLASS_PREFIX } from 'src/constants/';
import { FRONTEND_GROUP_OVERVIEW_PATH } from 'src/constants/urls';

// classes / components
import { NavigationFeatureFlag } from 'src/components/feature_flag/navigation_feature_flag/navigation_feature_flag';
import Feedback from 'src/components/feedback/feedback';
import Icon from 'src/components/forms/icon/icon';
import MenuItemSegment from 'src/components/item_segment/layouts/menu/menu';
import PopoverMenu, { callAll } from 'src/components/popover_menu/popover_menu';
import StaticPages from 'src/components/static_pages/static_pages';
import UserOnboardingCTA from 'src/containers/smart_components/user_onboarding_cta/user_onboarding_cta';

// helpers
import { URLContext } from 'src/containers/url_provider/url_provider';
import { textResources } from 'src/lang/de';
import { COLOR_TYPE_GRAY_TINT_9 } from 'src/utils/color';
import initTracker, { MAIN_MENU } from 'src/utils/reporting/events_tracking/events_tracking';

import { SMALL_ITEM_SEGMENT } from '../../../item_segment/base/item_segment_base';
import './logged_out_menu.scss';

const cls = `${CLASS_PREFIX}logged-out-menu`;
const clsSeparator = `${cls}__separator`;
const clsStatic = `${cls}__static`;
const clsList = `${cls}__list`;
const clsPopover = `${cls}__popover`;
const clsIcon = `${cls}__icon`;

interface IProps {
  onLogIn?: () => void;
  onRegistration?: () => void;
}

const tracker = initTracker(MAIN_MENU);

/**
 * (header) logged out menu component
 */
const LoggedOutMenu: React.FC<IProps> = ({
  onLogIn,
  onRegistration,
}) => {
  const [show, setShown] = useState<boolean>(false);

  const { getEventsfeedRoute, getNewsfeedRoute } = useContext(URLContext);

  const renderPopoverChildren = ({ hide }: IRenderAPI) => {
    const labelHeader = textResources.header;
    return (
      <nav className={cls}>
        <ul className={clsList}>

          {/* login register */}
          <MenuItemSegment
            navigation={{ onClick: callAll(hide, onLogIn) }}
            label={ textResources.shared.login }
            noBorder
            smallMargin
            height={SMALL_ITEM_SEGMENT}
            hover={true}
          />
          <MenuItemSegment
            navigation={{ onClick: callAll(hide, onRegistration) }}
            label={textResources.shared.register}
            noBorder
            smallMargin
            height={SMALL_ITEM_SEGMENT}
            hover={true}
          />

          <li className={clsSeparator} />

          <div className={`${cls}__feed-links`}>
            <NavigationFeatureFlag flag={NavigationFeatureFlags.NEWS}>
              <MenuItemSegment
                navigation={{
                  onClick: hide,
                  url: {
                    pathname: getNewsfeedRoute(),
                    state: {
                      sourceComponent: MAIN_MENU.CATEGORY,
                    },
                  },
                }}
                label={labelHeader.mainPage}
                noBorder
                smallMargin
                height={SMALL_ITEM_SEGMENT}
                hover={true}
              />
            </NavigationFeatureFlag>
            <NavigationFeatureFlag flag={NavigationFeatureFlags.EVENT}>
              <MenuItemSegment
                navigation={{
                  onClick: hide,
                  url: {
                    pathname: getEventsfeedRoute(),
                    state: {
                      sourceComponent: MAIN_MENU.CATEGORY,
                    },
                  },
                }}
                label={labelHeader.events}
                noBorder
                smallMargin
                height={SMALL_ITEM_SEGMENT}
                hover={true}
              />
            </NavigationFeatureFlag>
            <NavigationFeatureFlag flag={NavigationFeatureFlags.GROUP}>
              <MenuItemSegment
                navigation={{
                  onClick: hide,
                  url: {
                    pathname: FRONTEND_GROUP_OVERVIEW_PATH,
                    state: {
                      sourceComponent: MAIN_MENU.CATEGORY,
                    },
                  },
                }}
                label={labelHeader.groups}
                noBorder
                smallMargin
                height={SMALL_ITEM_SEGMENT}
                hover={true}
              />
            </NavigationFeatureFlag>
            <li className={clsSeparator} />
          </div>
          {/* actions */}
          <UserOnboardingCTA active={true} >
            <MenuItemSegment
              navigation={{ onClick: callAll(hide, onRegistration) }}
              label={textResources.mainMenu.spread}
              noBorder
              smallMargin
              height={SMALL_ITEM_SEGMENT}
              hover={true}
            />
          </UserOnboardingCTA>
          <MenuItemSegment
            navigation={{ onClick: callAll(hide, showFeedbackModal) }}
            label={textResources.shared.feedback}
            noBorder
            smallMargin
            height={SMALL_ITEM_SEGMENT}
            hover={true}
          />
          <li className={clsStatic}><StaticPages /></li>
        </ul>
      </nav>
    );
  };

  const showFeedbackModal = () => {
    setShown(true);
  };

  const hideFeedbackModal = () => {
    setShown(false);
  };

  const onShow = () => {
    tracker(MAIN_MENU.ACTIONS.OPEN, MAIN_MENU.LABELS.FINISH);
  };

  return (
    <>
      <PopoverMenu
        className={clsPopover}
        position={'bottom'}
        align={'left'}
        onShow={onShow}
        trigger={<Icon
          name='menu'
          className={clsIcon}
          size={32}
          color={COLOR_TYPE_GRAY_TINT_9}
          description={textResources.screenReaderText.openMenu}
        />}
        isFullHeight={false}
      >{renderPopoverChildren}</PopoverMenu>
      {show && (
        <Feedback close={hideFeedbackModal} />
      )}
    </>
  );
};

export default LoggedOutMenu;
