// libs
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { IPost, POST_DISPLAY_MODE_PREVIEW } from 'src/interfaces/posts';
import { textResources } from 'src/lang/de';
import { POST_PREVIEW, PostPreviewObjType } from 'src/utils/reporting/events_tracking/events_tracking';

// components
import NavigationItem from 'src/components/navigation_item/navigation_item';
import Author from 'src/components/post/shared/author/author';
import Ingredients from 'src/components/post/shared/ingredients/ingredients';
import ReactionOverview from 'src/components/post/shared/reactions/reaction_overview/reaction_overview';
import SharePopupHorizontal from 'src/components/share/share_popup_horizontal/share_popup_horizontal';
import { ReactionsButtonContainer } from 'src/containers/reactions';
import FollowButton from 'src/containers/smart_components/follow_button/follow_button';

// helpers
import Icon from 'src/components/forms/icon/icon';
import SurveyPreview from 'src/components/survey/preview/survey_preview';
import { ITrackingProps, withTrackerProps } from 'src/high_order_components/with_tracker_props';
import './post_preview.scss';

const cls = CLASS_PREFIX + 'post__preview';
const labels = textResources.comments;

export interface IPostPreview extends IPost {
  hideFooter?: boolean;
}

const PostPreview: React.FC<IPostPreview & ITrackingProps<PostPreviewObjType>> = (props) => {
  const {
    authorId,
    commentable,
    comments,
    hideFooter,
    id,
    ingredientIds,
    ingredients,
    meta,
    publishedAt,
    urls,
    tracking,
    survey,
    surveyUrl,
    children,
  } = props;
  const { fenced } = meta;
  const { canBookmark, canShare } = meta.permissions;
  const commentsCount = comments.number;

  // If there is no comment, we don't display 0 but just "Comment"
  const commentText = commentsCount
    ? labels.commentCount(commentsCount)
    : labels.comment;

  const postIngredients = ingredientIds.map((ingredientId) => ingredients[ingredientId]!);

  if (survey && surveyUrl) {
    return <article className={cls}>
      <div className={cls + '__tile'}>
        <SurveyPreview url={surveyUrl} id={id}/>
      </div>
    </article>;
  }

  return (
    <article className={cls}>
      <div className={cls + '__tile'}>
        <Ingredients
          ingredients={postIngredients}
          displayMode={POST_DISPLAY_MODE_PREVIEW}
          postDetailsURL={urls.frontend}
          blurred={fenced ? 'full' : undefined}
          postId={id}
        />
        <div className={cls + '__author-more-section '}>
          <Author
            publishedAt={publishedAt}
            displayMode={POST_DISPLAY_MODE_PREVIEW}
            id={authorId}
          />
          <FollowButton
            followableId={authorId}
          />
        </div>
        <ReactionOverview
          postId={id}
          displayMode={POST_DISPLAY_MODE_PREVIEW}
          basePath={urls.frontend}
          disabled={!canBookmark}
        />
        {hideFooter ? null
          : <div className={cls + '__tile__footer'}>
            <ReactionsButtonContainer id={id} disabled={!canBookmark}/>
            {
              commentable !== false &&
              <NavigationItem className={cls + '__comments-btn'} target='_self' url={`${urls.frontend}#comments`}>
                <Icon aria-hidden='true' name={'comment'} size={20}/>
                <div className={cls + '__comments-text'}>{commentText}</div>
              </NavigationItem>
            }
            {canShare && <SharePopupHorizontal
              utmSource='nf'
              url={urls.frontend}
              tracking={tracking}
            />}
          </div>
        }
      </div>
      {children}
    </article>
  );
};

export default withTrackerProps<typeof POST_PREVIEW, IPostPreview>(POST_PREVIEW)(PostPreview);
