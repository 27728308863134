import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { CLASS_PREFIX } from 'src/constants';
import { FRONTEND_EDIT_ABOUT_ME_PATH } from 'src/constants/urls';
import { textResources } from 'src/lang/de';
import { IProfileAnswer } from '../../../../interfaces/profile';
import AnswersProgressBar from '../progressbar/progressbar';
import './private.scss';

const cls = `${CLASS_PREFIX}profile-info__content`;

interface IProps {
  answers: IProfileAnswer[];
  editIcon: (url: string) => JSX.Element | null;
}

const PrivateAnswers: FunctionComponent<IProps> = ({ answers, editIcon }) =>
  <div className={classNames(cls, `${cls}--no-border`)}>
    <div className={`${cls}-title`}>{textResources.profile.answerQuestions}</div>
    <div className={`${cls}-body-container`}>
      <div className={`${cls}-body`}>{textResources.profile.answerQuestionsTeaser}</div>
      {editIcon(FRONTEND_EDIT_ABOUT_ME_PATH)}
    </div>
    <AnswersProgressBar answers={answers}/>
  </div>;

export default PrivateAnswers;
