import React, { FunctionComponent } from 'react';
import { CLASS_PREFIX } from '../../../../constants';
import { textResources } from '../../../../lang/de';
import { useSelectCurrentProfile } from '../../../../reducers/user/user_hooks';

import Icon from '../../../forms/icon/icon';

import { COLOR_TYPE_GRAY_TINT_10 } from '../../../../utils/color';
import './create_with_salutation.scss';
import { getFontSize } from './font-size.calculator';

const clsBlock: string = CLASS_PREFIX + 'create-salutation';
const labels = textResources.postCreate;

const CreateWithSalutation: FunctionComponent = () => {
  const { contact } = useSelectCurrentProfile();
  const text = contact?.firstName;
  const fontSize = getFontSize(text);
  return <div className={clsBlock}>
    <div className={clsBlock + '__title'} style={{
      fontSize: fontSize,
    }}>
      {text ? labels.createPostSalutation(text) : labels.createPostSalutationLoggedOut}
      <Icon name={'flower'} size={30} color={COLOR_TYPE_GRAY_TINT_10}/>
    </div>
    <div className={clsBlock + '__subtitle'}>{labels.createPostSalutationSubtitle}</div>
  </div>;
};
export default CreateWithSalutation;
