// libs
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX, ICON_CLASS_PREFIX } from 'src/constants/';

// classes / components
import NavigationItem, { TargetType } from 'src/components/navigation_item/navigation_item';
import { textResources } from 'src/lang/de';

// helpers
import './link_preview.scss';

export interface IProps {
  author?: string;
  description: string;
  displayUrl: string;
  imageUrl?: string;
  large?: boolean;
  onRemove?: () => void;
  showRemoveIcon?: boolean;
  target: TargetType;
  title: string;
  url: string;
}

export const cls = `${CLASS_PREFIX}link-preview`;

const LinkPreview: React.FC<IProps> = ({
  author,
  description,
  displayUrl,
  imageUrl,
  large,
  onRemove,
  showRemoveIcon,
  target,
  title,
  url,
}) => {
  const className = `${cls}${large ? '--large' : ''}`;
  return (
    <NavigationItem
      url={url}
      target={target}
      noFollow={true}
    >
      <div className={`${className}__container`}>
        <div className={`${className}__url`}>{displayUrl}</div>
        <div className={className}>
          {imageUrl && <img
            alt={textResources.imageAlt.linkPreview}
            className={`${className}__image`}
            src={imageUrl} />}
          <div className={`${className}__text`}>
            <p className={`${className}__author`}>{author || displayUrl}</p>
            <div className={`${className}__title-ellipsis`}>
              <div>
                <p className={`${className}__title`}>
                  {title}
                </p>
              </div>
            </div>
            {large ? (
              <div className={`${className}__description-ellipsis`}>
                <div>
                  <p className={`${className}__description`}>
                    {description}
                  </p>
                </div>
              </div>
            ) : null }
          </div>
          {showRemoveIcon && onRemove && (
            <i
              className={`${ICON_CLASS_PREFIX}x-circle ${className}__remove`}
              onClick={onRemove}
              title={textResources.postCreate.removePreviewLink}
            />
          )}
        </div>
      </div>
    </NavigationItem>
  );
};
export default LinkPreview;
