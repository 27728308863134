// libs
import moment, { DurationInputArg1, DurationInputArg2 } from 'moment';

// helpers
import DateFormatter, { ProcessableDate } from 'src/utils/date_formatter/date_formatter';

export default class DateHelper {
  public static addTime(time: Object): Date {
    return moment().add(time).toDate();
  }

  public static defaultEventDuration() {
    return moment.duration(1, 'hour');
  }

  public static differenceInYears(date: ProcessableDate): number {
    return moment().diff(date, 'years');
  }

  public static isAAfterB(
    dateA: ProcessableDate,
    timeA: string = '00:00',
    dateB: ProcessableDate,
    timeB: string = '00:00',
  ): boolean {
    if (!dateA || !dateB) {
      return false;
    }

    if (typeof dateA !== 'string') {
      dateA = moment(dateA).toISOString(true).split('T')[0];
    }
    if (typeof dateB !== 'string') {
      dateB = moment(dateB).toISOString(true).split('T')[0];
    }

    const dateTimeA = moment(DateFormatter.toDate(dateA + ' ' + timeA));
    const dateTimeB = moment(DateFormatter.toDate(dateB + ' ' + timeB));

    return dateTimeA.isAfter(dateTimeB);
  }

  public static isAfter(date1: ProcessableDate, date2: ProcessableDate) {
    return moment(date1).isAfter(date2);
  }

  public static isAfterNow(date: ProcessableDate, time: string): boolean {
    if (typeof date !== 'string') {
      const items = moment(date).toISOString(true).split('T');
      date = items[0];
    }
    const dateTime = moment(DateFormatter.toDate(date + ' ' + time));
    const now = moment();
    return dateTime.isAfter(now);
  }

  public static isBefore(date1: ProcessableDate, date2: ProcessableDate) {
    return moment(date1).isBefore(date2);
  }

  public static isInRange(currentDate: ProcessableDate, rangeStart: ProcessableDate, rangeEnd: ProcessableDate) {
    return moment(currentDate).isAfter(rangeStart) && moment(currentDate).isBefore(rangeEnd);
  }

  public static isMoreThanThreeDays(date1: string, date2: string): boolean {
    return moment(date2).diff(moment(date1), 'days') > 2;
  }

  public static addThreeDaysToDate(date: string) {
    const startDate = moment(new Date(date));
    return startDate.add(2, 'days');
  }

  public static isSame(date1: ProcessableDate, date2?: ProcessableDate) {
    return moment(date1).isSame(date2);
  }

  public static isSameMonth(date1: ProcessableDate, date2: ProcessableDate) {
    return moment(date1).isSame(date2, 'month');
  }

  public static isSameOrAfter(date1: ProcessableDate, date2: ProcessableDate) {
    return moment(date1).isSameOrAfter(date2);
  }

  public static isToday(date: ProcessableDate) {
    const now = moment();
    return now.isSame(date, 'day');
  }

  public static isTodayOrBefore(date: ProcessableDate) {
    const now = moment();
    return now.isSameOrBefore(date, 'day');
  }

  public static isTomorrow(date: ProcessableDate): boolean {
    const now = moment();
    return now.add(1, 'days').isSame(date, 'day');
  }

  public static isSameDay(date1: ProcessableDate, date2: ProcessableDate) {
    const dateA = new Date(date1);
    const dateB = new Date(date2);

    return dateA.getDate() === dateB.getDate() &&
      dateA.getMonth() === dateB.getMonth() &&
      dateA.getFullYear() === dateB.getFullYear();
  }

  public static isTodayOrTomorrow(date: ProcessableDate): boolean {
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    return this.isSameDay(today, date) || this.isSameDay(tomorrow, date);
  }

  public static isUpcomingWeekend(date: ProcessableDate): boolean {
    const weekendStart = moment(DateFormatter.getNextWeekendStart());
    const weekendEnd = moment(DateFormatter.getNextWeekendEnd());
    return moment(date).isBetween(weekendStart, weekendEnd, undefined, '[]');
  }

  public static isYesterday(date: ProcessableDate): boolean {
    const now = moment();
    return now.subtract(1, 'days').isSame(date, 'day');
  }

  public static isValid(date: ProcessableDate): boolean {
    return moment(date).isValid();
  }

  public static isOlderThan(date: ProcessableDate, value: DurationInputArg1, unit: DurationInputArg2): boolean {
    const dateThan = moment().subtract(value, unit);
    const diff = moment(date).diff(dateThan, unit);
    return diff < 0;
  }
}
