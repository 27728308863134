// libs
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { mapDisplayModeToConst, PostDisplayMode } from 'src/interfaces/posts';

// classes
import Icon from 'src/components/forms/icon/icon';

// styles
import { COLOR_TYPE_GRAY_TINT_9 } from '../../../../utils/color';
import './ingredient_row.scss';

export const baseCls = `${CLASS_PREFIX}ingredient-row__`;

interface IProps {
  icon: string;
  title?: string;
  description?: string;
  displayMode: PostDisplayMode;
}

const IngredientRow: React.FC<IProps> = ({ displayMode, icon, title, description }) => {
  const cls = baseCls + mapDisplayModeToConst[displayMode];
  const locationIcon = 'pin-standard';
  const ingredientCls = icon === locationIcon ? cls + '__location' : cls;
  const locationCls = icon === locationIcon ? cls + '__location-icon' : cls + '__icon';

  return (
    <div className={ingredientCls}>
      <div className={cls + '__rows'}>
        <div className={cls + '__ingredients'}>
          <Icon className={cls + '__ingredients-icon ' + locationCls}
            name={icon}
            size={20}
            color={COLOR_TYPE_GRAY_TINT_9}
          />
          <div className={cls + '__ingredients-text'}>
            <span className={cls + '__ingredients-title'}>{title}</span>
            <span className={cls + '__ingredients-description'}>{description && description}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IngredientRow;
