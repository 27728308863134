// libs
import { Action, LocationState } from 'history';
import React, { useContext } from 'react';
import { Redirect, RouteComponentProps, Switch } from 'react-router-dom';

// constants, interfaces
import {
  AUTHORITY_PROFILE_TYPE,
  BLOG_PROFILE_TYPE,
  PRESS_PROFILE_TYPE,
  PRIVATE_PROFILE_TYPE,
  REPORTER_PROFILE_TYPE,
} from 'src/constants/profile';
import {
  CHAT_ROOM_PATTERN,
  CREATE_POST,
  EVENTS_FEED_PARTIAL,
  FILTER_PATTERN,
  FOLLOWERS_PARTIAL,
  FRONTEND_APP_REGISTRATION,
  FRONTEND_CHAT_PATH,
  FRONTEND_CONVERSATION_PATH,
  FRONTEND_CREATE_PROFILE,
  FRONTEND_EVENT_PARTICIPANTS_PATH,
  FRONTEND_EVENT_PARTICIPATING_PATH,
  FRONTEND_EVENT_PATH,
  FRONTEND_FOLLOWEES_RECENT_FEED_PATH,
  FRONTEND_GROUP_FEED_PATH,
  FRONTEND_GROUP_PATH,
  FRONTEND_LOCALIZED_EVENTS,
  FRONTEND_LOCALIZED_NEWS,
  FRONTEND_LOCATION_PICKER_PATH,
  FRONTEND_LOGIN,
  FRONTEND_NEW_PASSWORD,
  FRONTEND_NEWSFEED_PATH,
  FRONTEND_NOTIFICATIONS_PATH,
  FRONTEND_POST_PATH,
  FRONTEND_POST_REACTIONS_PATH,
  FRONTEND_PROFILE_FEED_PATH,
  FRONTEND_PROFILES_DIRECTORY,
  FRONTEND_REDIRECT,
  FRONTEND_REGISTRATION,
  FRONTEND_SEND_MESSAGE_CONVERSATION_PATH,
  FRONTEND_SETTINGS_PATH,
  LOCATION_SLUG_PATTERN,
  NEWS_FEED_PARTIAL,
  PROFILES_DIRECTORY_CATEGORY_PATTERN,
  SHAPE_SPONSORS,
} from 'src/constants/urls';
// components
import {
  ContentLocationTopLevelRoute,
  LoginRequiredRoute,
  LogoutRequiredRoute,
  TopLevelRoute,
} from 'src/containers/routes';

import { URLContext } from 'src/containers/url_provider/url_provider';
import { ILopoCore } from 'src/interfaces/';
import CategorizedEventFeed from 'src/pages/categorized_eventfeed';
import CreateProfilePage from 'src/pages/create_profile';
import EventCategoryFeedPage from 'src/pages/event_categoryfeed';
import EventDetailsPage from 'src/pages/event_details';
import EventParticipatingFeedPage from 'src/pages/event_participating_feed';
import EventParticipationsPage from 'src/pages/event_participations';
import FolloweesRecentFeed from 'src/pages/followees_recent_feed';
import GroupFeedPage from 'src/pages/group_feed';
import GroupMembersPage from 'src/pages/groups_members';
import GroupsOverviewPage from 'src/pages/groups_overview';
import GroupsRecentFeed from 'src/pages/groups_recent_feed';
import LandingPage from 'src/pages/landing_page';
import LoginPage from 'src/pages/login';
import MailboxPage from 'src/pages/mailbox';
import NewsfeedPage from 'src/pages/newsfeed';
import NotFoundPage from 'src/pages/not_found';
import NotificationsPage from 'src/pages/notifications';
import PostDetailsPage from 'src/pages/post_details';
import PostReactionsPage from 'src/pages/post_reactions';
import ProfileFeedPage from 'src/pages/profile_feed';
import ProfileFollowersPage from 'src/pages/profile_followers';
import ProfilesDirectoryCategorizedPage from 'src/pages/profiles_directory_categorized';
import ProfilesDirectoryCategoryPage from 'src/pages/profiles_directory_category';
import RedirectPage from 'src/pages/redirect/redirect';
import RegistrationPage from 'src/pages/registration';
import ResetPasswordPage from 'src/pages/reset_password';
import SettingsPage from 'src/pages/settings';
import ShapeSponsorsPage from 'src/pages/shape_sponsors';
import TopicChatPage from 'src/pages/topic_chat';
import { NavigationFeatureFlags } from './components/feature_flag/navigation_feature_flag/model';
import {
  useMessenger,
} from './components/settings/settings_wizard/steps/settings_overview/messenger_toggle/useMessenger';
import LocationPickerPage from './pages/location_picker/location_picker';

// helpers

(window as unknown as ILopoCore).lopo_core = {
  isFirstNavigationPage: true,
};

const profileTypeRegex = 'authority|blog|reporter|private|press';
const userTypeRegex = `${PRIVATE_PROFILE_TYPE}|${REPORTER_PROFILE_TYPE}|` +
                      `${PRESS_PROFILE_TYPE}|${AUTHORITY_PROFILE_TYPE}|${BLOG_PROFILE_TYPE}`;

const renderRedirectTo = (getPath: () => string) => () => {
  return (
    <Redirect to={getPath()} />
  );
};

export const Routes = (router: RouteComponentProps) => {
  const lopo_core = (window as unknown as ILopoCore).lopo_core;
  if (lopo_core.isFirstNavigationPage) {
    const killTopLevelRouteListener = router.history.listen((_location: LocationState, action: Action) => {
      /* #4871 Where history.push is called is considered as a first entry page.
      It makes prevent to kick users out to the external page when calling `urlUtils.goback`. */
      if (action === 'PUSH') {
        lopo_core.isFirstNavigationPage = false;
        killTopLevelRouteListener();
      }
    });
  }

  const { enabled: shouldEnableMailboxRoute } = useMessenger();

  const {
    getEventsfeedRoute,
    getNewsfeedRoute,
  } = useContext(URLContext);

  return (
    <Switch>
      <TopLevelRoute path='/' exact component={LandingPage} />
      {/* legacy routes that might be still linked to from other places and users
      static routes will redirect to there dynamic localized counterpart */}
      <TopLevelRoute
        path={FRONTEND_NEWSFEED_PATH}
        render={renderRedirectTo(getNewsfeedRoute)}
        featureFlag={NavigationFeatureFlags.NEWS}
      />
      <TopLevelRoute
        path={FRONTEND_EVENT_PATH}
        exact
        render={renderRedirectTo(getEventsfeedRoute)}
        featureFlag={NavigationFeatureFlags.EVENT} />
      <TopLevelRoute
        path={FRONTEND_GROUP_FEED_PATH}
        component={GroupsRecentFeed}
        featureFlag={NavigationFeatureFlags.GROUP} />
      <TopLevelRoute path={FRONTEND_FOLLOWEES_RECENT_FEED_PATH} component={FolloweesRecentFeed} />
      {/* legacy localized feeds are using new infrastructure */}
      <TopLevelRoute
        path={`${FRONTEND_LOCALIZED_NEWS}/${LOCATION_SLUG_PATTERN}`}
        component={NewsfeedPage}
        featureFlag={NavigationFeatureFlags.NEWS}
      />
      <TopLevelRoute
        path={`${FRONTEND_LOCALIZED_EVENTS}/${LOCATION_SLUG_PATTERN}`}
        component={CategorizedEventFeed}
        featureFlag={NavigationFeatureFlags.EVENT}
      />
      {/* end of legacy routes */}
      <ContentLocationTopLevelRoute
        path={`${FRONTEND_PROFILE_FEED_PATH}/:profileType(${profileTypeRegex})/:profileId${FOLLOWERS_PARTIAL}`}
        component={ProfileFollowersPage}
      />
      <ContentLocationTopLevelRoute
        path={`${FRONTEND_PROFILE_FEED_PATH}/:profileType(${profileTypeRegex})/:profileId`}
        component={ProfileFeedPage}
      />
      <LoginRequiredRoute
        path={`${FRONTEND_POST_PATH}/:id${FRONTEND_POST_REACTIONS_PATH}`}
        component={PostReactionsPage}
      />
      <ContentLocationTopLevelRoute
        path={`${FRONTEND_POST_PATH}/:id`}
        component={PostDetailsPage}
      />
      <LoginRequiredRoute
        path={`${FRONTEND_EVENT_PATH}/${FRONTEND_EVENT_PARTICIPATING_PATH}`}
        component={EventParticipatingFeedPage}
        featureFlag={NavigationFeatureFlags.EVENT}
      />
      <LoginRequiredRoute
        path={`${FRONTEND_EVENT_PATH}/:id${FRONTEND_EVENT_PARTICIPANTS_PATH}`}
        component={EventParticipationsPage}
        featureFlag={NavigationFeatureFlags.EVENT}
      />
      <LoginRequiredRoute
        path={`${FRONTEND_EVENT_PATH}/:id${FRONTEND_POST_REACTIONS_PATH}`}
        component={PostReactionsPage}
        featureFlag={NavigationFeatureFlags.EVENT}
      />
      <ContentLocationTopLevelRoute
        path={`${FRONTEND_EVENT_PATH}/:id`}
        component={EventDetailsPage}
        featureFlag={NavigationFeatureFlags.EVENT}
      />
      <TopLevelRoute
        path={`/${LOCATION_SLUG_PATTERN}${NEWS_FEED_PARTIAL}`}
        component={NewsfeedPage}
        featureFlag={NavigationFeatureFlags.NEWS}
      />
      <TopLevelRoute
        path={`/${LOCATION_SLUG_PATTERN}${EVENTS_FEED_PARTIAL}`}
        component={CategorizedEventFeed}
        featureFlag={NavigationFeatureFlags.EVENT}
        exact
      />
      <TopLevelRoute
        path={`/${LOCATION_SLUG_PATTERN}${EVENTS_FEED_PARTIAL}${CREATE_POST}`}
        component={CategorizedEventFeed}
        featureFlag={NavigationFeatureFlags.EVENT}
      />
      <TopLevelRoute
        path={`/${LOCATION_SLUG_PATTERN}${EVENTS_FEED_PARTIAL}/${FILTER_PATTERN}`}
        component={EventCategoryFeedPage}
        featureFlag={NavigationFeatureFlags.EVENT}
      />
      <TopLevelRoute
        path={`/${LOCATION_SLUG_PATTERN}${SHAPE_SPONSORS}`}
        component={ShapeSponsorsPage}
      />
      <LoginRequiredRoute
        path={FRONTEND_NOTIFICATIONS_PATH}
        component={NotificationsPage}
        featureFlag={NavigationFeatureFlags.NOTIFICATIONS}
      />
      <TopLevelRoute
        path={`/${LOCATION_SLUG_PATTERN}${FRONTEND_PROFILES_DIRECTORY}`}
        component={ProfilesDirectoryCategorizedPage}
        featureFlag={NavigationFeatureFlags.USER_DIRECTORY}
        exact
      />
      <TopLevelRoute
        path={`/${LOCATION_SLUG_PATTERN}${FRONTEND_PROFILES_DIRECTORY}${PROFILES_DIRECTORY_CATEGORY_PATTERN}`}
        component={ProfilesDirectoryCategoryPage}
        featureFlag={NavigationFeatureFlags.USER_DIRECTORY}
      />
      {shouldEnableMailboxRoute &&
        <LoginRequiredRoute path={FRONTEND_CONVERSATION_PATH} exact component={MailboxPage} />}
      {shouldEnableMailboxRoute && <LoginRequiredRoute
        path={`${FRONTEND_SEND_MESSAGE_CONVERSATION_PATH}:profileType(${userTypeRegex})/:profileId`}
        component={MailboxPage}
      />}
      {shouldEnableMailboxRoute &&
        <LoginRequiredRoute path={`${FRONTEND_CONVERSATION_PATH}:conversationId(\\d+)`} component={MailboxPage} />}

      <TopLevelRoute
        path={`${FRONTEND_GROUP_PATH}:id/members`}
        component={GroupMembersPage}
        featureFlag={NavigationFeatureFlags.GROUP}
      />
      <TopLevelRoute
        path={`${FRONTEND_GROUP_PATH}:id`}
        component={GroupFeedPage}
        featureFlag={NavigationFeatureFlags.GROUP}
      />
      <TopLevelRoute
        path={FRONTEND_GROUP_PATH}
        component={GroupsOverviewPage}
        featureFlag={NavigationFeatureFlags.GROUP}
      />
      <TopLevelRoute path={FRONTEND_NEW_PASSWORD} component={ResetPasswordPage} />
      <TopLevelRoute path={FRONTEND_SETTINGS_PATH} component={SettingsPage} />
      <TopLevelRoute path={`${FRONTEND_CHAT_PATH}/${CHAT_ROOM_PATTERN}`} component={TopicChatPage} />
      <TopLevelRoute path={FRONTEND_REDIRECT} component={RedirectPage} />
      <TopLevelRoute path={FRONTEND_LOCATION_PICKER_PATH} component={LocationPickerPage} />
      <LoginRequiredRoute
        path={FRONTEND_CREATE_PROFILE}
        featureFlag={NavigationFeatureFlags.CREATE_PROFILE}
        component={CreateProfilePage} />
      <LogoutRequiredRoute path={FRONTEND_REGISTRATION} component={RegistrationPage} layout={'registration'} />
      <LogoutRequiredRoute path={FRONTEND_APP_REGISTRATION} component={RegistrationPage} layout={'appRegistration'}/>
      <LogoutRequiredRoute path={FRONTEND_LOGIN} component={LoginPage} />
      <TopLevelRoute component={NotFoundPage} />
    </Switch>
  );
};
