// libs
import React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';

// components
import FloatingButton, { IProps as IFloatingButtonProps } from 'src/components/floating_button/floating_button';

const cls = CLASS_PREFIX + 'floating-button-menu';

interface IProps extends Omit<IFloatingButtonProps, 'ref'> {
  onClick: () => void;
  initiallyExpanded?: boolean;
  featureBlocked?: boolean;
}

const FloatingButtonMenu: React.FC<IProps> = ({
  image,
  title,
  remAboveBottom,
  onClick,
  featureBlocked,
}) =>
  (
    <div className={cls}>
      <FloatingButton
        remAboveBottom={remAboveBottom}
        image={image}
        onClick={onClick}
        title={title}
        featureBlocked={featureBlocked}
      />
    </div>
  );

export default FloatingButtonMenu;
