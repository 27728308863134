// libs
import classNames from 'classnames';
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { COLOR_TYPE_WHITE, ColorType } from 'src/utils/color';

// classes
import Icon, { IconSize } from 'src/components/forms/icon/icon';

// helpers
import './round_icon.scss';

const cls = CLASS_PREFIX + 'round-icon';

interface IProps {
  backgroundColor?: ColorType;
  borderColor?: ColorType;
  size?: IconSize;
  backgroundSize?: IconSize;
  name: string;
  iconColor?: ColorType;
  description?: string;
  isLightRadius?: boolean;
}

const RoundIcon: React.FC<IProps> = ({
  name,
  backgroundColor,
  backgroundSize,
  iconColor,
  size,
  description,
  borderColor,
  isLightRadius,
}) => {
  const clses = {
    [cls]: true,
    [`${cls}--${backgroundColor}`]: !!backgroundColor,
    [`${cls}--border`]: !!borderColor,
    [`${cls}--border--${borderColor}`]: !!borderColor,
    [`${cls}--border-thick`]: borderColor && size && size >= 44,
    [`${cls}--light-radius`]: isLightRadius,
  };
  const roundIconSize = backgroundSize || size;
  const iconClasses = classNames(
    `${cls}__icon`,
    roundIconSize && `${cls}__icon--size-${roundIconSize}`,
  );
  return (
    <div className={classNames(clses)}>
      <Icon
        className={iconClasses}
        color={iconColor || COLOR_TYPE_WHITE}
        name={name}
        size={size}
        description={description}
      />
    </div>
  );
};

export default RoundIcon;
