// interfaces
import * as Response from 'src/api/interfaces/responses/group';
import { IGroup, IGroupList } from 'src/interfaces/group';
import { IProfilesInGroupListCategory } from 'src/interfaces/profiles_list';
import { IPrivatePostTopic } from 'src/interfaces/user';

// decoders
import { $pagination } from 'src/api/decoders/pagination';
import { $profilesInGroup } from 'src/api/decoders/profile';

export const $topic = (json: Response.ITopic): IPrivatePostTopic => ({
  id: json.global_friendly_identifier,
  internalType: json.internal_type,
  name: json.name,
  permanent: json.permanent,
  urls: {
    assignable: json.meta.urls.assignable,
    posts: json.meta.urls.posts,
    resource: json.meta.urls.resource,
  },
});

export const $groups = (json: Response.IGroupsResponse): IGroupList => ({
  data: json.data.map(group),
  meta: $pagination(json.meta),
});

export const $moreGroups = (json: Response.IGroupsResponse): IGroupList => ({
  data: json.data.map(group).filter((group) => !group.isMember),
  meta: $pagination(json.meta),
});

export const $group = (json: Response.IGroupResponse): IGroup =>
  group(json.data);

const group = (json: Response.IGroup): IGroup => ({
  accessibility: json.accessibility,
  description: json.information || undefined,
  globalStableIdentifier: json.global_stable_identifier,
  id: json.identifier,
  isAdmin: json.meta.is_admin,
  isMember: json.meta.is_member,
  isParticipating: json.meta.is_participating,
  lastPostAt: json.last_post_at,
  memberCount: json.meta.member_count,
  name: json.name,
  notification: json.meta.notification,
  permissions: {
    canJoin: json.meta.permissions.can_join,
    canReadMemberList: json.meta.permissions.can_read_member_list,
    canSeePosts: json.meta.permissions.can_view_topics,
  },
  urls: {
    categorized_participations: json.urls.categorized_participations,
    initialTopic: json.initial_topic?.meta.urls.posts,
    participate: json.urls.participate,
    resource: json.urls.resource,
    topics: json.urls.topics,
  },
});

export const $groupParticipation = (json: Response.IGroupParticipationResponse): IGroup =>
  $group({ data: json.data.group });

const groupedGroupParticipations = (json: Response.IGroupParticipation[] | undefined): Response.IGroupParticipation[] =>
  json || [];

export const $groupParticipations = (json: Response.IGroupParticipationsResponse): IProfilesInGroupListCategory =>
  $profilesInGroup({
    data: groupedGroupParticipations(json.data.admin).concat(groupedGroupParticipations(json.data.member)),
    meta: json.meta,
  });
