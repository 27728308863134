import classNames from 'classnames';
import React from 'react';

// components
import GroupItem from 'src/components/groups/group_overview/group_list_items/group_item';
import ItemGroupHeader from 'src/components/item_group_header/item_group_header';
import LoadingSpinner from 'src/components/loading_spinner/loading_spinner';
import {
  useGroupJoinConfirmation,
} from 'src/containers/smart_components/group_member_list/hooks/useGroupJoinConfirmation';

// interfaces
import { IGroup, IGroupMap } from 'src/interfaces/group';

// constants
import { CLASS_PREFIX } from 'src/constants/';

import { GroupsSortingSettings } from '../../../../actions/groups/groups';
import GroupSort from '../group_sort/group_sort';
import './group_list.scss';

const cls = CLASS_PREFIX + 'group_list';

interface IGroupListProps {
  groupIds?: string[];
  label?: string;
  isLoading?: boolean;
  className?: string;
  joinGroup?: (group: IGroup) => Promise<void>;
  groupMap: IGroupMap;
  onSortingChange?: (sortingSettings: GroupsSortingSettings) => void;
  sortingSettings?: GroupsSortingSettings;
  displayLastPostAt: boolean;
}

const GroupList: React.FC<IGroupListProps> = ({
  groupIds,
  label,
  isLoading,
  className,
  joinGroup,
  groupMap,
  sortingSettings,
  onSortingChange,
  displayLastPostAt,
}) => {
  const { onGroupJoin, ConfirmationModal } = useGroupJoinConfirmation(joinGroup);
  return (
    <>
      {label && <ItemGroupHeader title={label} >
        {
          sortingSettings &&
          onSortingChange &&
          <GroupSort sortingSettings={sortingSettings} onChange={onSortingChange}/>
        }
      </ItemGroupHeader>}
      <div className={classNames(cls, className)}>
        {groupIds?.map((groupId) =>
          groupMap[groupId] &&
          <GroupItem
            key={groupId}
            group={groupMap[groupId]!}
            joinGroup={onGroupJoin}
            displayLastPostAt={displayLastPostAt}
          />
        )}
      </div>
      <LoadingSpinner shown={!!isLoading} />
      {ConfirmationModal && <ConfirmationModal />}
    </>
  );
};

export default GroupList;
