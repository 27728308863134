// libs
import * as React from 'react';

import Icon from 'src/components/forms/icon/icon';

import { CLASS_PREFIX } from 'src/constants/';

import { textResources } from 'src/lang/de';
import { COLOR_TYPE_GRAY_TINT_9 } from '../../../../../utils/color';
import './backbutton.scss';

const cls: string = CLASS_PREFIX + 'back-button';

interface IProps {
  onClick?: () => void;
  description?: string;
}

/**
 * (header) back button component
 */
const BackButton: React.FC<IProps> = ({ onClick, description = textResources.shared.back }) => (
  <div className={cls} onClick={onClick}>
    <Icon name={'arrow-left'} size={32} description={description} color={COLOR_TYPE_GRAY_TINT_9} />
  </div>
);

/* set displayname to not have a component without a name */
BackButton.displayName = 'BackButton';

export default BackButton;
