import { ICheckAuthResponse } from 'src/api/concerns/authentication';
import { decode, Decoder } from 'src/api/decode';
import { build as buildRequestUrl } from 'src/api/url';
import { reportError } from 'src/utils/reporting/report-errors';

// middleware
import authenticationMiddleware, {
  ABORT,
  CONTINUE,
  RETRY,
} from 'src/api/middleware/authentication';
import positionMiddleware from 'src/api/middleware/position';
import profileMiddleware from 'src/api/middleware/profile';
import checkOutdatedRevisionMiddleware from 'src/api/middleware/revisionCheck';

// interfaces
import { IErrorResponse } from 'src/api/interfaces/errors';
import { IQueryParams } from 'src/api/interfaces/requests';

// constants
import { NETWORK_ERROR } from 'src/constants/api_error_codes';
import { API_REVISION } from 'src/constants/http-headers';

export { create, destroy, read, update };

// user defined type-guard https://basarat.gitbooks.io/typescript/docs/types/typeGuard.html
const isErrorResponse = (response: Response, json: any): json is IErrorResponse => {
  return json.errors !== undefined || json.status === 'error' || response.status >= 400;
};

const alterRequest = async(r: Request): Promise<Request> => {
  return authenticationMiddleware.alterAuthRequestHeaders(r)
    .then(positionMiddleware.alterRequest)
    .then(profileMiddleware.alterRequest);
};

async function send<ResponseInterface, ExpectedResult>(
  req: Request,
  decoder: Decoder<ResponseInterface, ExpectedResult>,
  retryCount = 1,
): Promise<ExpectedResult | null> {
  // we need to clone the request *before* actually sending it out (to be able to retry the request
  // if it failed for the first time), as requests that have a `body` (like POST or PUT requests)
  // cannot be re-used after they were sent out
  const clone = req.clone();

  if (retryCount > 2) {
    const error = new Error('user tried to go to the same api call and rejected twice');
    reportError(error, req);
    throw error;
  }

  const alteredRequest: Request = await alterRequest(req);

  let response: Response;
  try {
    response = await fetch(alteredRequest);
  } catch (e) {
    throw Error(NETWORK_ERROR);
  }

  checkOutdatedRevisionMiddleware(response.headers.get(API_REVISION));

  const authenticationResult: ICheckAuthResponse = await authenticationMiddleware.checkAuthenticationState(response);

  switch (authenticationResult.step) {
    case ABORT:
      throw new Error(authenticationResult.reason);
    case RETRY:
      return send<ResponseInterface, ExpectedResult>(clone, decoder, retryCount++);
    case CONTINUE:
      await positionMiddleware.saveLocationToStore(response);

      /* HTTP 201 - Created
       * The request has been fulfilled, resulting in the creation of a new resource and there is no
       * additional content to send in the response payload body.
       * This happens e.g. on a successful registration request for users.
       * HTTP 202 - Accepted
       * The request has been accepted for processing,
       * but the processing has not been completed.
       * The request might or might not be eventually acted upon,
       * and may be disallowed when processing occurs.
       * This happens e.g. on a successful CREATE requests for a password-forgot request.
       * HTTP 204 - No Content
       * The server has successfully fulfilled the request and there is no
       * additional content to send in the response payload body.
       * This happens e.g. on a successful DELETE request for a Post resource.
       */
      if (response.status === 201 || response.status === 202 || response.status === 204) {
        return null;
      }

      let json;
      try {
        json = await response.json();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        const formattedError: IErrorResponse = {
          code: '' + response,
          status: 'error',
        };
        throw formattedError;
      }
      if (isErrorResponse(response, json)) {
        throw json;
      }

      return decode<ResponseInterface, ExpectedResult>(decoder, json);
  }
}

function request(
  method: string,
  url: string,
  body?: object,
  signal?: AbortSignal,
): Request {
  const headers = new Headers({
    'Accept': 'application/json',
    // Note: On July 2019 we deployed a version that told the browser that the expire date of this endpoint is 2037
    // there is not way to invalidate this but to change header to no cache any api call,
    // please do not remove the querystring on the me endpoint!
    'Cache-Control': 'max-age=0',
    'Content-Type': 'application/json',
  });
  // more boring Request-options at https://developer.mozilla.org/en-US/docs/Web/API/Request/Request
  return new Request(url, { body: JSON.stringify(body), credentials: 'same-origin', headers, method, signal });
}

/* `APIResponseInterface`
 * it typechecks the decoder you write, to match the given interface.
 * it represents the expected response from the server.
 *
 * `ExpectedResult`
 * is the model you want to work with after request and decoding.
 */
const create = <ExpectedResult, APIResponseInterface>(
  url: string,
  body: object,
  decoder: Decoder<ExpectedResult, APIResponseInterface>,
) => send(request('POST', url, body), decoder);

const read = <ExpectedResult, APIResponseInterface>(
  url: string,
  decoder: Decoder<ExpectedResult, APIResponseInterface>,
  params?: IQueryParams,
  signal?: AbortSignal,
) => send(request('GET', buildRequestUrl(url, params), undefined, signal), decoder, undefined);

const update = <ExpectedResult, APIResponseInterface>(
  url: string,
  body: object,
  decoder: Decoder<ExpectedResult, APIResponseInterface>,
) => send(request('PUT', url, body), decoder);

const destroy = <ExpectedResult, APIResponseInterface>(
  url: string,
  body: object,
  decoder: Decoder<ExpectedResult, APIResponseInterface>,
  params?: IQueryParams,
) => send(request('DELETE', buildRequestUrl(url, params), body), decoder);
