import React from 'react';

// interfaces & constants
import { CLASS_PREFIX } from 'src/constants';
import { AuthModalType } from 'src/interfaces/app-state';

// components
import Modal from 'src/components/modal/modal';
import AuthUI from 'src/containers/auth_ui';

import { createBemBlock } from '../../../utils/bem_helper/bem_helper';
import './auth_modal.scss';

interface IProps {
  onClose: () => void;
  layout: AuthModalType;
}

const cls = CLASS_PREFIX + 'auth-modal';

const AuthModal: React.FC<IProps> = ({ onClose, layout }) => (
  <Modal autoSize={true} onClose={onClose} noBorderRadius customClassNames={createBemBlock('auth-modal')('body')}>
    <div className={cls}>
      <AuthUI
        onClose={onClose}
        layout={layout}
        hasTabs
        hasCancelButton
        hasStaticLinks
      />
    </div>
  </Modal>
);

export default AuthModal;
