import * as React from 'react';

import {
  AGB,
  CreatePasswordField,
  DefaultButton,
  EMailField,
  FacebookButton,
  PrimaryButton,
  RepeatedPasswordField,
} from 'src/components/authentication/auth_form_elements';
import Checkbox from 'src/components/forms/checkbox/checkbox';
import FieldSet, { FIELDSET_TYPE_SMALL_SPACE } from 'src/components/forms/field_set/field_set';
import LoadingSpinner from 'src/components/loading_spinner/loading_spinner';
import LocationPicker from 'src/components/location_picker/location_picker';
import NavigationItem from 'src/components/navigation_item/navigation_item';
import SnackBar from 'src/components/snack_bar/snack_bar';

// intefaces & constants
import { IErrorResponse } from 'src/api/interfaces/errors';
import { IAuthProps } from 'src/components/authentication/auth_ui';
import { AUTH_UI_CLASS } from 'src/constants/';
import { FRONTEND_DATA_AGB_PATH, FRONTEND_SETTINGS_PATH } from 'src/constants/urls';
import { IAddress } from 'src/interfaces/address';
import { IPosition } from 'src/interfaces/location';
import Translate, { textResources } from 'src/lang/de';

// helpers
import api, { IRegistrationParams } from 'src/api/';
import { AuthenticationHelper } from 'src/utils/authentication/authentication';
import { createBemBlock } from 'src/utils/bem_helper/bem_helper';
import { getConfigValue } from 'src/utils/config/config';
import { RegistrationHelper } from 'src/utils/registration';
import initTracker, { REGISTRATION } from 'src/utils/reporting/events_tracking/events_tracking';
import { reportError } from 'src/utils/reporting/report-errors';
import { UrlUtils } from 'src/utils/url/url';
import { validateEmail, validatePassword, validateRepeatedPassword } from 'src/utils/validation';

const cls = createBemBlock(AUTH_UI_CLASS);
const ERROR_NOT_CREATED = 'not_created';
const ERROR_CODE_INVALID_GOOGLE_PLACE_ID = 'invalid_google_place_id';
const ERROR_INVALID_GRANT = 'invalid_grant';
const ERROR_AREA_NOT_RELEASED = 'area_not_released';
const labels = textResources.authentication;
const unknownError = textResources.shared.errorUnknown;

const registerTracker = initTracker(REGISTRATION);

interface IState {
  hasConfirmedMinimumAge: boolean;
  errorMessageEmail?: string;
  isLoading: boolean;
  password: string;
  repeatedPassword: string;
  showCheckboxErrorMessage: boolean;
  showErrorMessage: boolean;
  showSuccessMessage: boolean;
  validEmail: boolean;
  validAddress: boolean;
  validPassword: boolean;
  validRepeatedPassword: boolean;
  locationPickerOpen: boolean;
  address?: IAddress;
  missingGooglePlaceId: boolean;
  defaultAddress?: string;
}

type ProviderHelper = typeof RegistrationHelper.registerWithFacebook | typeof RegistrationHelper.registerWithFacebook;
type ProviderTrackingAction = typeof REGISTRATION.ACTIONS.APPLE | typeof REGISTRATION.ACTIONS.FACEBOOK;

class Register extends React.Component<IAuthProps, IState> {
  private googlePlaceIdPromise: Promise<string | undefined>;
  constructor(props: IAuthProps) {
    super(props);
    this.state = {
      errorMessageEmail: textResources.shared.errorEmail,
      hasConfirmedMinimumAge: false,
      isLoading: false,
      locationPickerOpen: false,
      missingGooglePlaceId: false,
      password: '',
      repeatedPassword: '',
      showCheckboxErrorMessage: false,
      showErrorMessage: false,
      showSuccessMessage: false,
      validAddress: true,
      validEmail: true,
      validPassword: true,
      validRepeatedPassword: true,
    };
    this.submit = this.submit.bind(this);
    this.registerWith = this.registerWith.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.changeRepeatedPassword = this.changeRepeatedPassword.bind(this);
    this.toggleConfirmedMinimumAge = this.toggleConfirmedMinimumAge.bind(this);
    this.onClose = this.onClose.bind(this);
    this.redirectToLandingPage = this.redirectToLandingPage.bind(this);
    this.closeErrorMessage = this.closeErrorMessage.bind(this);
    this.onChangeLocation = this.onChangeLocation.bind(this);
    this.onLocationInput = this.onLocationInput.bind(this);
    this.closeLocationPicker = this.closeLocationPicker.bind(this);
  }

  public componentDidUpdate() {
    if (!this.props.position || this.googlePlaceIdPromise) {
      return;
    }
    this.setGooglePlaceId(this.props.position);
  }

  public componentDidMount() {
    const defaultAddress = getConfigValue<IAddress>('featureFlags.location.defaultAddress');
    if (defaultAddress) {
      this.onChangeLocation(defaultAddress);
    }

    const { position } = this.props;
    if (!position) {
      return;
    }
    this.setGooglePlaceId(position);
  }

  public render() {
    const { showErrorMessage, isLoading, missingGooglePlaceId } = this.state;
    return (
      <>
        {showErrorMessage && (
          <SnackBar message={unknownError} onClose={this.closeErrorMessage} />
        )}
        {isLoading ? <div className={cls('loading')}><LoadingSpinner shown /></div>
          : missingGooglePlaceId ? this.renderError() : this.renderForm()}
      </>
    );
  }

  public renderError() {
    return (
      <>
        <p className={cls('text')}>{Translate.settingsPageError.invalid_google_place_id}</p>
        <PrimaryButton onClick={this.redirectToLandingPage} label={textResources.header.mainPage} />
      </>
    );
  }

  public renderForm() {
    const { email, goToLogin, position, showAlreadyRegistered } = this.props;
    const { validEmail, locationPickerOpen, validAddress, password, validPassword, address,
      repeatedPassword, validRepeatedPassword, errorMessageEmail, hasConfirmedMinimumAge,
      showCheckboxErrorMessage, defaultAddress } = this.state;
    return (
      <>
        <form noValidate onSubmit={this.submit}>
          {!defaultAddress &&
            !position &&
          <div className={cls('location-picker')} >
            <LocationPicker
              onChange={this.onChangeLocation}
              autoFocusSearchInput={false}
              forceShowOnlyInput
              onQueryChange={this.onLocationInput}
              close={this.closeLocationPicker}
              clear={this.closeLocationPicker}
              errorText={labels.errorAddress}
              valid={validAddress}
              defaultAddress={address && address.description}
            />
          </div>
          }
          { !locationPickerOpen &&
          <>
            <FieldSet>
              <EMailField
                name='email'
                onChange={this.changeEmail}
                value={email}
                valid={validEmail}
                errorText={errorMessageEmail}
              />
              <CreatePasswordField
                name='password'
                onChange={this.changePassword}
                value={password}
                valid={validPassword}
              />
              <RepeatedPasswordField
                name='repeatedPassword'
                label={labels.repeatPassword}
                value={repeatedPassword}
                onChange={this.changeRepeatedPassword}
                valid={validRepeatedPassword}
              />
              <div className={cls('flex-row')}>
                <div className={cls('checkbox')}>
                  <Checkbox
                    checked={hasConfirmedMinimumAge}
                    onClick={this.toggleConfirmedMinimumAge}
                  />
                </div>
                <p className={cls('checkbox-label')} onClick={this.toggleConfirmedMinimumAge}>
                  {labels.ageConfirmation1}
                  <NavigationItem url={FRONTEND_DATA_AGB_PATH} className={cls('link')} target={'_blank'}>
                    {labels.ageConfirmation2}
                  </NavigationItem>
                  {labels.ageConfirmation3}
                </p>
              </div>
              <p className={cls('checkbox-error')}>
                {!hasConfirmedMinimumAge && showCheckboxErrorMessage && labels.ageConfirmationError}
              </p>
            </FieldSet>
            <FieldSet type={FIELDSET_TYPE_SMALL_SPACE}>
              <PrimaryButton onClick={this.submit} label={textResources.shared.register}/>
              <FacebookButton
                onClick={this.registerWith(RegistrationHelper.registerWithFacebook, REGISTRATION.ACTIONS.FACEBOOK)}
                label={labels.registerWithFacebook}
              />
              {showAlreadyRegistered &&
                <DefaultButton
                  className={cls + '__default-button'}
                  onClick={goToLogin} label={labels.alreadyRegistered}
                />
              }
            </FieldSet>
          </>
          }
        </form>
        <AGB />
      </>
    );
  }

  private closeLocationPicker() {
    this.setState({ locationPickerOpen: false });
  }

  private onLocationInput() {
    this.setState({ locationPickerOpen: true, validAddress: true });
  }

  private onChangeLocation(address: IAddress) {
    this.setState({ address, locationPickerOpen: false });
    registerTracker(REGISTRATION.ACTIONS.CHANGE_LOCATION, REGISTRATION.LABELS.START);
  }

  private pushError(msg: string) {
    const { pushError } = this.props;
    pushError && pushError(msg);
  }

  private setGooglePlaceId(position: IPosition) {
    this.googlePlaceIdPromise =
      api.address.getAddressBy({ onlyReleased: true, position, streetRequired: false })
        .then((addresses: IAddress[]) => addresses[0].googlePlaceId);
  }

  private getGooglePlaceId() {
    const { address } = this.state;
    if (this.googlePlaceIdPromise) {
      return this.googlePlaceIdPromise;
    } else if (address) {
      return address.googlePlaceId;
    } else {
      this.setState({ validAddress: false });
      return undefined;
    }
  }

  private redirectToLandingPage() {
    UrlUtils.setLocationHref('/');
  }

  private changePassword(value: string) {
    this.setState({ password: value, validPassword: true });
  }

  private changeRepeatedPassword(value: string) {
    this.setState({ repeatedPassword: value, validRepeatedPassword: true });
  }

  private changeEmail(value: string) {
    const { changeEmail } = this.props;
    this.setState({ validEmail: true });
    changeEmail && changeEmail(value);
  }

  private toggleConfirmedMinimumAge() {
    this.setState({
      hasConfirmedMinimumAge: !this.state.hasConfirmedMinimumAge,
    });
  }

  private hasNotEnteredAddressToLocationPicker(googlePlaceId?: string) {
    return !googlePlaceId && !this.state.address;
  }

  private isUserInputValid(): boolean {
    const { email = '' } = this.props;
    const { password, repeatedPassword } = this.state;

    registerTracker(REGISTRATION.ACTIONS.EMAIL, REGISTRATION.LABELS.START);

    const validEmail = validateEmail(email);
    const validPassword = validatePassword(password);
    const validRepeatedPassword = validateRepeatedPassword(password, repeatedPassword);

    const errorMessageEmail = !validEmail ? textResources.shared.errorEmail : '';

    this.setState({
      errorMessageEmail,
      validEmail,
      validPassword,
      validRepeatedPassword,
    });

    return validEmail && validPassword && validRepeatedPassword;
  }

  private hasConfirmedMinimumAge(): boolean {
    const { hasConfirmedMinimumAge } = this.state;
    if (!hasConfirmedMinimumAge) {
      this.setState({ showCheckboxErrorMessage: true });
      return false;
    }
    return true;
  }

  private async submit(e: React.SyntheticEvent<HTMLElement>) {
    e.preventDefault();

    const { email = '', logIn, history } = this.props;
    const { password } = this.state;

    if (!this.hasConfirmedMinimumAge()) {
      return;
    }

    if (this.isUserInputValid()) {
      const googlePlaceId = await this.getGooglePlaceId();
      if (this.hasNotEnteredAddressToLocationPicker(googlePlaceId)) {
        return;
      }

      if (!googlePlaceId) {
        this.noGooglePlaceId();
        return;
      }
      const params: IRegistrationParams = {
        email,
        password,
      };
      this.setState({ isLoading: true });

      RegistrationHelper.register(params, googlePlaceId)
        .then(() => {
          const callback = () => AuthenticationHelper.login(params)
            .then(() => {
              logIn && logIn();
              history.push(FRONTEND_SETTINGS_PATH);
              this.setState({ isLoading: false }, this.onClose);
            })
            .catch((error: IErrorResponse) => {
              this.setState({ isLoading: false });
              if (error.code === ERROR_INVALID_GRANT) {
                this.pushError(labels.errorLogin);
              } else {
                this.pushError(unknownError);
              }
            });
          registerTracker(REGISTRATION.ACTIONS.EMAIL, REGISTRATION.LABELS.FINISH, callback);
        })
        .catch((error: IErrorResponse) => {
          this.setState({ isLoading: false });
          switch (error.code) {
            case ERROR_AREA_NOT_RELEASED:
              this.pushError(textResources.locationChanger.areaNotReleasedShort);
              break;
            case ERROR_CODE_INVALID_GOOGLE_PLACE_ID:
              this.pushError(textResources.locationChanger.areaNotReleasedShort);
              reportError('ERROR_CODE_INVALID_GOOGLE_PLACE_ID registration error', { error, googlePlaceId });
              break;
            case ERROR_NOT_CREATED:
              const { ...formErrors } = error.errors;
              this.setState({
                errorMessageEmail: textResources.onboardingSettings.cannotUseEmailAddress,
                validEmail: !formErrors.email,
                validPassword: !formErrors.password,
              });
              break;
            default:
              this.pushError(unknownError);
              reportError('unknown registration error', { error, googlePlaceId });
          }
          registerTracker(REGISTRATION.ACTIONS.EMAIL, REGISTRATION.LABELS.FAIL);
        });
    }
  }

  private registerWith(providerHelper: ProviderHelper, trackingAction: ProviderTrackingAction) {
    return async(e: React.SyntheticEvent<HTMLElement>) => {
      e.preventDefault();

      if (!this.hasConfirmedMinimumAge()) {
        return;
      }

      registerTracker(trackingAction, REGISTRATION.LABELS.START);

      const googlePlaceId = await this.getGooglePlaceId();
      if (this.hasNotEnteredAddressToLocationPicker(googlePlaceId)) {
        return;
      }

      if (googlePlaceId) {
        providerHelper.call(RegistrationHelper, googlePlaceId)
          .catch(() => {
            this.pushError(unknownError);
          });
      } else {
        this.noGooglePlaceId();
      }
    };
  }

  private closeErrorMessage() {
    this.setState({ showErrorMessage: false });
  }

  private onClose() {
    const { onClose } = this.props;
    onClose && onClose();
  }

  private noGooglePlaceId() {
    // this hapens when the app-position or the address from the location picker doesen't return an googleplaceId
    this.setState({ missingGooglePlaceId: true });
    reportError(
      'Registration attempted without googlePlaceId',
      {
        address: this.state.address,
        position: this.props.position,
        reason: 'User tryed to register but the googlePlaceID resolved in undefined',
      },
    );
  }
}

export default Register;
