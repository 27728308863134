import { IProfileAnswer } from '../../interfaces/profile';

export const SET_QUESTIONS_ANSWERS = 'SET_QUESTIONS_ANSWERS';
export const SET_SINGLE_ANSWER = 'SET_SINGLE_ANSWER';

export interface ISetQuestionsAnswers {
  payload: IProfileAnswer[];
  type: typeof SET_QUESTIONS_ANSWERS;
}

export interface ISetSingleAnswer {
  payload: Pick<IProfileAnswer, 'answer' | 'questionId'>;
  type: typeof SET_SINGLE_ANSWER;
}

export const setQuestionsAnswers = (questionsAnswers: IProfileAnswer[] = []): ISetQuestionsAnswers => ({
  payload: questionsAnswers,
  type: SET_QUESTIONS_ANSWERS,
});

export const setSingleAnswer = (answer: Pick<IProfileAnswer, 'answer' | 'questionId'>): ISetSingleAnswer => ({
  payload: answer,
  type: SET_SINGLE_ANSWER,
});
