// libs
import classnames from 'classnames';
import * as React from 'react';

// classes
import LoadingSpinner from 'src/components/loading_spinner/loading_spinner';
import MessageBox, { cls, IMessageBoxProps } from 'src/components/message_box/message_box';
import Modal from 'src/components/modal/modal';

// helpers
import './message_box.scss';

interface IOwnProps extends IMessageBoxProps {
  isLoading?: boolean;
  onClose?: () => void;
  whiteBackground?: boolean;
}

const clsModal: string = cls + '__modal';

const MessageBoxWithModal: React.FC<IOwnProps> = ({
  bodyTitle,
  buttons,
  children,
  isLoading,
  onClose,
  title,
  whiteBackground,
  light,
  type,
}) => {
  const clsModalColor = {
    [clsModal]: true,
    [clsModal + '--white']: whiteBackground,
  };

  const renderLoadingSpinner = () => {
    return (
      <div className={cls + '__loading'}>
        <LoadingSpinner shown />
      </div>
    );
  };
  return (
    <Modal
      onClose={onClose}
      autoSize={true}
    >
      <div className={classnames(clsModalColor)}>
        <MessageBox
          buttons={buttons}
          bodyTitle={bodyTitle}
          title={title}
          light={light}
          type={type}
        >
          {isLoading
            ? renderLoadingSpinner()
            : children}
        </MessageBox>
      </div>
    </Modal>
  );
};

export default MessageBoxWithModal;
