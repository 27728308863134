import produce from 'immer';
import {
  ISetQuestionsAnswers,
  ISetSingleAnswer,
  SET_QUESTIONS_ANSWERS,
  SET_SINGLE_ANSWER,
} from '../../actions/questions_answers/questions_answers';
import { IProfileAnswer } from '../../interfaces/profile';

export const INITIAL_STATE: IProfileAnswer[] = [];

type Actions = ISetQuestionsAnswers | ISetSingleAnswer

const QuestionsAnswersReducer = (state = INITIAL_STATE, action: Actions) => produce(state, draft => {
  switch (action.type) {
    case SET_QUESTIONS_ANSWERS:
      return action.payload;
    case SET_SINGLE_ANSWER:
      return draft.map(answer => {
        if (answer.questionId === action.payload.questionId) {
          return {
            ...answer,
            ...action.payload,
          };
        } else {
          return answer;
        }
      });
  }
});

export default QuestionsAnswersReducer;
