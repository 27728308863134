// libs
import * as React from 'react';

// interfaces / constants
import Share, { IShareCompProps, IShareProps } from 'src/components/share/lib/share';
import { CLASS_PREFIX } from 'src/constants/';
import {
  ColorType,
} from 'src/utils/color';

// components
import RoundIcon from 'src/components/forms/round_icon/round_icon';
import ShareSegmentConfigurable from 'src/components/share/lib/share_segment_configurable';

import './share_segment.scss';

const cls = CLASS_PREFIX + 'share-segment';

interface IShareElementProps {
  caption: string;
  iconName: string;
  backgroundColor: ColorType;
  iconColor: ColorType;
}

const ShareElement: React.FC<IShareElementProps> = ({ iconName, caption, backgroundColor, iconColor }) => (
  <>
    <RoundIcon
      name={iconName}
      backgroundSize={40}
      backgroundColor={backgroundColor}
      iconColor={iconColor}
    />
    <span key='caption' className={cls + '__link-caption'}>{caption}</span>
  </>
);

const ShareSegmentComponent: React.FC<IShareCompProps> = (props) => (
  <ShareSegmentConfigurable {...props} component={ShareElement} />
);

const ShareSegment = (props: IShareProps) => (
  <Share
    {...props}
    component={ShareSegmentComponent}
  />
);

export default ShareSegment;
