import classNames from 'classnames';
import { throttle } from 'lodash';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useResizeListener } from '../../../hooks_shared/use_event_listener';
import { createBemBlock } from '../../../utils/bem_helper/bem_helper';
import { readColumnCountFromDOM } from '../../../utils/column_count';
import './fixed_height.scss';

const cls = createBemBlock('fixed-height');

interface IProps {
  className?: string;
}

const FixedHeight: FunctionComponent<IProps> = ({ children, className }) => {
  const [columnCount, setColumnCount] = useState(() => readColumnCountFromDOM());
  const listener = useCallback(
    throttle(() => {
      setColumnCount(readColumnCountFromDOM());
    }, 100),
    [],
  );
  useEffect(() => {
    setColumnCount(readColumnCountFromDOM());
  }, []);

  useResizeListener(listener, { passive: true });

  return <div className={classNames(cls(), cls('', columnCount === 1 ? 'mobile' : ''), className)}>{children}</div>;
};

export default FixedHeight;
