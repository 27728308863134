// libs
import React, { useState, useEffect, useMemo, useCallback, FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';

// components
import MasonryFeed from 'src/components/feed/layouts/masonry_feed/masonry_feed';
import GroupInfo from 'src/components/groups/group_info/group_info';
import NotAllowedToSeePostsCTA
  from 'src/components/groups/not_allowed_to_see_posts_cta/not_allowed_to_see_posts_cta';
import LoadingSpinnerWrapper from 'src/components/loading_spinner/loading_spinner_wrapper';
import PostCreateBanner from 'src/components/post/create/create_banner';
import SnackBar from 'src/components/snack_bar/snack_bar';
import { FRONTEND_GROUP_OVERVIEW_PATH } from 'src/constants/urls';
import {
  useGroupJoinConfirmation,
} from 'src/containers/smart_components/group_member_list/hooks/useGroupJoinConfirmation';
import FeedWrapper from 'src/containers/smart_components/list_wrapper/feed_wrapper';

// constants
import {
  FEED_TYPE_GROUP,
  FEED_POST_TYPE_PRIVATE_POST,
} from 'src/constants/feed';
import { IGroup } from 'src/interfaces/group';

// helpers
import { useShowLoginModal, useShowRegistrationModal } from 'src/actions/app-state/app-state_hooks';
import {
  useGetGroup,
  useFetchGroupFeedPage,
  useFetchGroupFeedNextPage,
  useJoinGroup,
} from 'src/actions/feed/group_feed/group_feed_hooks';
import api from 'src/api';
import { useGetGroupIdFromUrlParams } from 'src/hooks_shared/use_url_params';
import {
  useSelectFeedIsLoading,
  useSelectFeedGroups,
  useSelectPosts,
  useSelectFeedHash,
} from 'src/reducers/feed/feed_hooks';
import { useGetGroupById } from 'src/reducers/groups/groups_hooks';
import {
  useSelectIsUserLoggedIn,
  useSelectProfilePermissions,
  useIsIntermediateProfile,
} from 'src/reducers/user/user_hooks';

import PostCreate from 'src/containers/post/create';
import { textResources } from 'src/lang/de';

const labels = textResources.groups;

interface IProps {
  postCreateOpen: boolean;
}

const useGetRenderPostCreate = (isPostCreateOpen: boolean, joinGroup: () => void, group?: IGroup) => {
  const loggedIn = useSelectIsUserLoggedIn();
  const isIntermediate = useIsIntermediateProfile();

  if (!group) {
    return (): JSX.Element => {
      return <div key="-1"/>;
    };
  }

  return (): JSX.Element => (
    <React.Fragment key='-1'>
      <GroupInfo
        group={group}
        joinGroup={joinGroup}
        intermediateOrNotLoggedIn={isIntermediate || !loggedIn}
      />
      {group.isMember && (
        <>
          <PostCreateBanner
            callToActionText={textResources.postCreate.createPostTitle}
            postType={FEED_POST_TYPE_PRIVATE_POST}
            featureBlocked={!group.isMember}
            tileSize
            forceWizard
          />
          <PostCreate
            open={isPostCreateOpen}
            postType={FEED_POST_TYPE_PRIVATE_POST}
            showsBanner={false}
            featureBlocked={!group.isMember}
            groupId={group.id}
            groupName={group.name}
            topicPostPath={group.urls.initialTopic}
          />
        </>
      )}
    </React.Fragment>);
};

const NotAllowedToSeePosts: FunctionComponent<{ group: IGroup | undefined; joinGroup?: () => Promise<void> }> =
  ({ group, joinGroup }) => {
    const permissions = useSelectProfilePermissions();
    const dispatchShowLoginModal = useShowLoginModal();
    const dispatchShowRegistrationModal = useShowRegistrationModal();
    const history = useHistory();
    const onConfirm = () => {
      history.push(FRONTEND_GROUP_OVERVIEW_PATH);
    };
    const { onGroupJoin, ConfirmationModal } = useGroupJoinConfirmation(joinGroup, onConfirm);

    if (!group || group.permissions.canSeePosts) {
      return <></>;
    }

    return (
    <>
      {ConfirmationModal && <ConfirmationModal />}
      <NotAllowedToSeePostsCTA
        canJoinGroups={permissions?.joinGroups}
        canJoinThisGroup={group?.permissions.canJoin}
        group={group}
        dispatchShowLoginModal={dispatchShowLoginModal}
        dispatchShowRegistrationModal={dispatchShowRegistrationModal}
        joinGroup={onGroupJoin}
      />
    </>
    );
  };

const GroupFeed: React.FC<IProps> = ({ postCreateOpen }) => {
  const [snackbarMessage, setSnackbarMessage] = useState<string | undefined>(undefined);
  const id = useGetGroupIdFromUrlParams();
  const isLoading = useSelectFeedIsLoading(FEED_TYPE_GROUP);
  const feedHash = useSelectFeedHash(FEED_TYPE_GROUP);
  const group = useGetGroupById(id);
  const groups = useSelectFeedGroups(FEED_TYPE_GROUP);
  const posts = useSelectPosts();
  const getGroup = useGetGroup();
  const getGroupFeedNextPage = useFetchGroupFeedNextPage();
  const getGroupFeedPage = useFetchGroupFeedPage();
  const joinGroupAction = useJoinGroup();

  const joinGroup = useCallback(() => {
    const errorMessage = group && labels.joinGroupError(group.name);
    if (group) {
      return api.group.join(group.urls.participate)
        .then((joinedGroup) => {
          if (joinedGroup) {
            setSnackbarMessage(labels.joinClosedGroupSuccess(group.name));
            joinGroupAction(joinedGroup);
          } else {
            setSnackbarMessage(errorMessage);
          }
        }).catch(() => {
          setSnackbarMessage(errorMessage);
        });
    } else {
      return Promise.resolve();
    }
  }, [group, joinGroupAction]);

  const renderPostCreate = useGetRenderPostCreate(postCreateOpen, joinGroup, group);

  const getFeedNextPage = () => {
    group && getGroupFeedNextPage(group);
  };

  const closeSnackbar = () => {
    setSnackbarMessage(undefined);
  };

  useEffect(() => {
    if (!group) {
      getGroup(id);
    }
  }, [group, getGroup, id]);

  useEffect(() => {
    group && getGroupFeedPage(group);
  }, [group, getGroupFeedPage, feedHash]);

  const extraElements = useMemo(() => [
    <NotAllowedToSeePosts group={group} joinGroup={joinGroup} key='not-allowed' />,
  ], [group, joinGroup]);

  return (
    <LoadingSpinnerWrapper isLoading={isLoading}>
      <FeedWrapper
        getNextPage={getFeedNextPage}
        feedType={FEED_TYPE_GROUP}
        initialLoad={false}
      >
        <MasonryFeed
          extraElements={extraElements}
          groups={groups}
          isLoading={isLoading}
          posts={posts}
          renderPostCreate={renderPostCreate}
          showEmptyFeed={!!group?.permissions.canSeePosts}
        />
      </FeedWrapper>
      {snackbarMessage && (
        <SnackBar message={snackbarMessage} onClose={closeSnackbar} />
      )}
    </LoadingSpinnerWrapper>
  );
};

GroupFeed.displayName = 'GroupFeed';

export default GroupFeed;
