import React, { FunctionComponent, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { textResources } from 'src/lang/de';
import { COLOR_TYPE_GRAY_TINT_9 } from '../../utils/color';
import Icon from '../forms/icon/icon';
import './expandable_content.scss';

interface IProps {
  maxHeight: number;
  showMoreLabel?: string;
}

const ExpandableContent: FunctionComponent<IProps> = ({
  maxHeight,
  showMoreLabel = textResources.shared.showMore,
  children,
}) => {
  const childrenRef = useRef<HTMLDivElement>(null);

  const [expanded, setExpanded] = useState(false);
  const [showToggle, setShowToggle] = useState(false);

  useLayoutEffect(() => {
    const height = childrenRef.current?.offsetHeight;
    setShowToggle(height ? height > maxHeight : false);
  }, [maxHeight]);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const contentStyle: React.CSSProperties = useMemo(() => ({
    maxHeight: expanded ? 'none' : `${maxHeight}px`,
  }), [expanded, maxHeight]);

  return (
    <div className={'expandable-content__container'}>
      <div className={'expandable-content'} style={contentStyle}>
        <div ref={childrenRef} >{children}</div>
      </div>
      {showToggle && <button onClick={toggleExpanded}>
        {expanded
          ? <>
              <Icon name={'arrow-up'} size={16} color={COLOR_TYPE_GRAY_TINT_9} />
              <div>{textResources.shared.showLess}</div>
            </>
          : <>
          <div>{showMoreLabel}</div>
            <Icon name={'arrow-down'} size={16} color={COLOR_TYPE_GRAY_TINT_9} />
          </>}
      </button>}
    </div>
  );
};

export default ExpandableContent;
