// libs
import classNames from 'classnames';
import * as React from 'react';

// components
import Popover from 'src/components/popover/popover';
import ShareSegment from 'src/components/share/share_segment/share_segment';

// interfaces / constants
import { IShareProps } from 'src/components/share/lib/share';
import { CLASS_PREFIX } from 'src/constants/';
import { textResources } from 'src/lang/de';

// helpers
import { nativeShare } from 'src/components/share/lib/utils';

import Icon from 'src/components/forms/icon/icon';
import './share_popup_horizontal.scss';

const cls = CLASS_PREFIX + 'share-popup-horizontal';
const shareText = textResources.share.share;

interface IShareMenuButtonProps {
  onClick?: () => void;
  className?: string;
}
const SharePopupButton: React.FC<IShareMenuButtonProps> = ({ className, onClick }) => (
  <button onClick={onClick} className={className} >
    <Icon aria-hidden='true' name={'share-thick'} size={22} />
    <span className={cls + '__text'}>{shareText}</span>
  </button>
);

const SharePopupHorizontal: React.FC<IShareProps> = ({ className, url, utmSource, tracking, ...props }) => {
  // @ts-ignore
  if (navigator.share) {
    return (
      <div className={classNames(cls + '__wrapper', className)}>
        <SharePopupButton onClick={nativeShare(url, utmSource, tracking)} className={cls + '__button'} />
      </div>
    );
  }
  return (
    <div className={classNames(cls + '__wrapper', className)}>
      <Popover
        position={'top'}
        align={'relative'}
        layout={'round'}
        trigger={
          <SharePopupButton className={cls + '__button'} />
        }
      >
        <ShareSegment utmSource={utmSource} url={url} className={cls} tracking={tracking} {...props} />
      </Popover>
    </div>
  );
};

export default SharePopupHorizontal;
