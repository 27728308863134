// libs
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';

// helpers
import './field_set.scss';

const FIELDSET_TYPE_BIG_SPACE = 'big-space';
export const FIELDSET_TYPE_SMALL_SPACE = 'small-space';
export const FIELDSET_TYPE_XSMALL_SPACE = 'xsmall-space';

const cls = CLASS_PREFIX + 'field-set';

interface IProps {
  type?: FieldSetType;
}

export type FieldSetType = typeof FIELDSET_TYPE_BIG_SPACE
  | typeof FIELDSET_TYPE_SMALL_SPACE
  | typeof FIELDSET_TYPE_XSMALL_SPACE;

const FieldSet: React.FC<IProps> = ({ children, type = FIELDSET_TYPE_BIG_SPACE }) => {
  return (
    <React.StrictMode>
      <fieldset className={`${cls}--${type}`}>
        {children}
      </fieldset>
    </React.StrictMode>
  );
};

export default FieldSet;
