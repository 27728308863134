// libs
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';

// interfaces
import { IGroup } from 'src/interfaces/group';
import { IUserProfileType } from 'src/interfaces/user';

// constants
import { CLASS_PREFIX } from 'src/constants';

// components
import GroupList from 'src/components/groups/group_overview/group_list/group_list';
import GroupEmptyItem from 'src/components/groups/group_overview/group_list_items/group_empty_item/group_empty_item';
import GroupRecommendation from 'src/components/groups/group_overview/group_recommendation/group_recommendation';
import ItemGroupHeader from 'src/components/item_group_header/item_group_header';
import LoadingSpinner from 'src/components/loading_spinner/loading_spinner';
import SnackBar from 'src/components/snack_bar/snack_bar';

// helpers
import { hasEnoughToShow } from 'src/actions/app-state/utils';
import { useFetchJoinedGroups, useFetchMoreGroups, useJoinGroup } from 'src/actions/feed/group_feed/group_feed_hooks';
import { useResetNewsFeed } from 'src/actions/feed/news_feed/news_feed_hooks';
import api from 'src/api';
import { useScrollListener } from 'src/hooks_shared/use_event_listener';
import { textResources } from 'src/lang/de';
import { useGetGroupMap, useGetJoinedGroupsRequest, useGetMoreGroupsRequest } from 'src/reducers/groups/groups_hooks';
import { hasData, isAppending, isLoading } from 'src/reducers/groups/types/remote_data';
import {
  useSelectCurrentProfile,
  useSelectIsUserLoggedIn,
  useSelectProfilePermissions,
} from 'src/reducers/user/user_hooks';
import { isFullProfile } from 'src/utils/profile';

import { GroupsSorting, GroupsSortingSettings } from '../../../actions/groups/groups';
import CreateItem from '../../post/create/create_item/create_item';
import './group_overview.scss';

const cls = CLASS_PREFIX + 'group-overview';

const labels = textResources.groups;

interface IProps {
  showModal: () => void;
  profileType: IUserProfileType;
}

const GroupRecommendationButton: React.FC<IProps> = ({ showModal, profileType }) =>
  (
    <div className={cls + '__create-group'}>
      <CreateItem active={!isFullProfile(profileType)} onClick={showModal} label={labels.recommendationTitle} />
    </div>
  );

const GroupsOverview: React.FC = () => {
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [showRecommendModal, setShowRecommendModal] = useState(false);
  const [groupsSorting, setGroupsSorting] = useState<GroupsSortingSettings>({
    orderBy: 'desc',
    sortBy: GroupsSorting.LATEST_POST,
  });
  const [joinedGroupsSorting, setJoinedGroupsSorting] = useState<GroupsSortingSettings>({
    orderBy: 'desc',
    sortBy: GroupsSorting.LATEST_POST,
  });

  const loggedIn = useSelectIsUserLoggedIn();
  const joinGroupAction = useJoinGroup();
  const fetchMoreGroups = useFetchMoreGroups();
  const fetchJoinedGroups = useFetchJoinedGroups();
  const profileType = useSelectCurrentProfile().type;
  const groupMap = useGetGroupMap();
  const joinedGroupsRequest = useGetJoinedGroupsRequest();
  const moreGroupsRequest = useGetMoreGroupsRequest();
  const canJoinGroups = useSelectProfilePermissions()?.joinGroups;

  const closeSnackBar = () => {
    setSnackBarMessage('');
  };

  const showModal = () => {
    setShowRecommendModal(true);
  };

  const hideModal = () => {
    setShowRecommendModal(false);
  };

  useEffect(() => {
    if (loggedIn && canJoinGroups) {
      fetchJoinedGroups(joinedGroupsSorting);
    }
  }, [joinedGroupsSorting, fetchJoinedGroups, loggedIn, canJoinGroups]);

  useEffect(() => {
    fetchMoreGroups(groupsSorting);
  }, [groupsSorting, fetchMoreGroups, canJoinGroups]);

  // needed since the loadingState in the store would be set to late
  // and the next throttled event-lister-callback might be called before
  // which would cause multiple repeated api-calls
  const savedIsAppending = useRef(false);
  useEffect(() => {
    savedIsAppending.current = isAppending(moreGroupsRequest);
  }, [moreGroupsRequest]);

  const listener = useCallback(() => {
    if (hasData(moreGroupsRequest)) {
      const { lastPage, currentPage } = moreGroupsRequest.data.pagination;
      if (!savedIsAppending.current && !hasEnoughToShow() && !lastPage) {
        fetchMoreGroups(groupsSorting, currentPage + 1);
        savedIsAppending.current = true;
      }
    }
  }, [moreGroupsRequest, fetchMoreGroups, groupsSorting]);

  useScrollListener(listener, { passive: true });

  const resetNewsfeed = useResetNewsFeed();

  const getJoinSuccessMessage = (group: IGroup) => {
    if (group.accessibility === 'closed') {
      return labels.joinClosedGroupSuccess(group.name);
    }

    return labels.joinGroupSuccess(group.name);
  };

  const joinGroup = useCallback((group: IGroup) =>
    api.group.join(group.urls.participate)
      .then((joinedGroup) => {
        if (joinedGroup) {
          // since groupPosts of joined groups are appearing on the news_feed
          resetNewsfeed();
          joinGroupAction(joinedGroup);
          setSnackBarMessage(getJoinSuccessMessage(group));
        } else {
          setSnackBarMessage(labels.joinGroupError(group.name));
        }
      }).catch((_error) => {
        setSnackBarMessage(labels.joinGroupError(group.name));
      }), [joinGroupAction, resetNewsfeed]);

  if (isLoading(joinedGroupsRequest) || isLoading(moreGroupsRequest)) {
    return <LoadingSpinner shown />;
  }

  if (isEmpty(groupMap)) {
    return (
      <div className={cls}>
        <ItemGroupHeader title={labels.moreGroups} />
        <GroupEmptyItem />
      </div>
    );
  }

  const joinedGroupIds = hasData(joinedGroupsRequest) ? joinedGroupsRequest.data.joinedGroupIds : [];
  const pendingGroupIds = hasData(joinedGroupsRequest) ? joinedGroupsRequest.data.pendingGroupIds : [];
  const moreGroupIds = hasData(moreGroupsRequest) ? moreGroupsRequest.data.groupIds : [];

  const restGroupIds = moreGroupIds
    .filter(groupId => !joinedGroupIds.includes(groupId) && !pendingGroupIds.includes(groupId));

  return (
    <div className={cls}>
      <GroupRecommendationButton showModal={showModal} profileType={profileType} />
      {pendingGroupIds.length > 0 &&
        <GroupList
          groupIds={pendingGroupIds}
          groupMap={groupMap}
          isLoading={isLoading(joinedGroupsRequest)}
          label={labels.pendingGroups}
          sortingSettings={joinedGroupsSorting}
          onSortingChange={setJoinedGroupsSorting}
          displayLastPostAt={false}
        />
      }
      {joinedGroupIds.length > 0 &&
        <GroupList
          groupIds={joinedGroupIds}
          groupMap={groupMap}
          isLoading={isLoading(joinedGroupsRequest)}
          label={labels.myGroups}
          sortingSettings={joinedGroupsSorting}
          onSortingChange={setJoinedGroupsSorting}
          displayLastPostAt={true}
        />
      }
      <GroupList
        groupIds={restGroupIds}
        groupMap={groupMap}
        isLoading={isLoading(moreGroupsRequest)}
        joinGroup={joinGroup}
        label={
          restGroupIds.length > 0
            ? labels.moreGroups
            : undefined
        }
        sortingSettings={groupsSorting}
        onSortingChange={setGroupsSorting}
        displayLastPostAt={false}
      />
      {snackBarMessage.length > 0 && (
        <SnackBar message={snackBarMessage} showClose onClose={closeSnackBar} />
      )}
      {showRecommendModal &&
        <GroupRecommendation hideModal={hideModal}/>
      }
    </div>
  );
};

export default GroupsOverview;
