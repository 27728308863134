// libs
import * as React from 'react';

// components
import ShareItemSegment from 'src/components/item_segment/layouts/share/share';
import Share, { IShareCompProps, IShareProps } from 'src/components/share/lib/share';

// interfaces / constants
import { textResources } from 'src/lang/de';
import { COLOR_TYPE_FACEBOOK, COLOR_TYPE_PRIMARY } from 'src/utils/color';

const ShareItemListComponent: React.FC<IShareCompProps> = ({ shareLinks, shareToClipboard, track }) => {
  return (
    <>
      {shareLinks.map((shareLink) => {
        if (shareLink.iconColor === COLOR_TYPE_FACEBOOK) {
          return <ShareItemSegment
            key={shareLink.name}
            icon={{
              color: shareLink.iconColor,
              name: shareLink.iconName,
            }}
            size={56}
            label={shareLink.caption}
            navigation={{ onClick: track(shareLink.trackingAction), target: '_blank', url: shareLink.shareUrl }}
            noBackground={true}
          />;
        }
        return <ShareItemSegment
          key={shareLink.name}
          icon={{
            color: shareLink.backgroundColor,
            name: shareLink.iconName,
          }}
          label={shareLink.caption}
          navigation={{ onClick: track(shareLink.trackingAction), target: '_blank', url: shareLink.shareUrl }}
        />;
      })}
      <ShareItemSegment
        key='link'
        navigation={{ onClick: shareToClipboard }}
        icon={{
          color: COLOR_TYPE_PRIMARY,
          name: 'clip',
        }}
        label={textResources.share.link}
      />
    </>);
};

const ShareItemList = (props: IShareProps) => (
  <Share
    {...props}
    component={ShareItemListComponent}
  />
);

export default ShareItemList;
