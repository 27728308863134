import { FeedFilterContentItemType } from 'src/api/interfaces/requests';
import { IIdentifiable, Id } from 'src/interfaces/';
import { IAttachment } from 'src/interfaces/attachment';
import { IEventCategory } from 'src/interfaces/categories';
import { ILocationShape, IPosition } from 'src/interfaces/location';
import {
  ILinkPreview,
  IIngredientMap,
  BodyMarkdownIngredientType,
  BodyTextIngredientType,
  EventLocationIngredientType,
  EventShortTimeIngredientType,
  EventStartEndIngredientType,
  TitleIngredientType,
  LinkPreviewIngredientType,
  VideoIngredientType,
  MapIngredientType,
  ParticipantsIngredientType,
  ImageIngredientType,
  ImageSnapshotIngredientType,
  ImageWithDateIngredientType,
  InclusionReasonIngredientType,
} from 'src/interfaces/post_ingredients';

import { FEED_POST_TYPE_POST, FEED_POST_TYPE_EVENT } from 'src/constants/feed';
import { IMarker } from 'src/interfaces/marker';
import { IProfilePayload } from 'src/interfaces/profile';
import { IReactions } from 'src/interfaces/reactions';
import { IAddress } from './address';
import { ISurveyRequest } from './survey';

const POST_DISPLAY_MODE_ADMIN = 'admin';
export const POST_DISPLAY_MODE_BANNER = 'banner';
export const POST_DISPLAY_MODE_COMPACT = 'compact';
export const POST_DISPLAY_MODE_DETAIL = 'detail';
export const POST_DISPLAY_MODE_PREVIEW = 'preview';

export const mapDisplayModeToConst: { [key in PostDisplayMode]: PostDisplayMode } = {
  admin: POST_DISPLAY_MODE_ADMIN,
  banner: POST_DISPLAY_MODE_BANNER,
  compact: POST_DISPLAY_MODE_COMPACT,
  detail: POST_DISPLAY_MODE_DETAIL,
  preview: POST_DISPLAY_MODE_PREVIEW,
};

export type PostDisplayMode = typeof POST_DISPLAY_MODE_ADMIN |
  typeof POST_DISPLAY_MODE_BANNER |
  typeof POST_DISPLAY_MODE_COMPACT |
  typeof POST_DISPLAY_MODE_DETAIL |
  typeof POST_DISPLAY_MODE_PREVIEW;

export type PostSchema = 'NewsArticle' | 'Event';

export interface IComments {
  number: number;
  urls: {
    create: string;
    get: string;
  };
}

interface ISharedCreateData {
  images?: string[];
  linkPreviewId?: string;
  expires_at?: string;
  survey?: boolean;
  surveyDetails?: ISurveyRequest;
}

export interface IPostCreateData extends ISharedCreateData {
  body: string;
  campaignAmount?: string;
  commentable: boolean;
  googlePlaceId?: string;
  contentItemType?: string;
  title: string;
}

export interface IPostSurveyCreateData extends IPostCreateData {
  expires_at: string;
  surveyDetails: ISurveyRequest;
}

export interface IEventCreateData extends ISharedCreateData {
  body: string;
  googlePlaceId: string;
  startTime: string;
  endTime?: string;
  categoryId: string;
  title: string;
}

export interface ISnapshotCreateData extends ISharedCreateData {
  body?: string;
  commentable?: boolean;
  campaignAmount?: string;
  googlePlaceId?: string;
  contentItemType?: string;
  title?: string;
}

export type IngredientId =
  BodyMarkdownIngredientType |
  BodyTextIngredientType |
  EventLocationIngredientType |
  EventShortTimeIngredientType |
  EventStartEndIngredientType |
  TitleIngredientType |
  LinkPreviewIngredientType |
  VideoIngredientType |
  MapIngredientType |
  ParticipantsIngredientType |
  ImageIngredientType |
  ImageSnapshotIngredientType |
  ImageWithDateIngredientType |
  InclusionReasonIngredientType;

export interface IIngredientsState {
  ingredientIds: IngredientId[];
  ingredients: IIngredientMap;
}

export interface IPost extends IIngredientsState, IIdentifiable {
  address: string;
  authorId: Id;
  city: string;
  commentable?: boolean;
  featuredImage: string;
  survey: boolean;
  externalUrl?: string;
  global: boolean;
  locationShape: ILocationShape;
  meta: IPostMeta;
  markers: IMarker[];
  position: IPosition;
  publishedAt: string;
  updatedAt: string;
  comments: IComments;
  urls: IPostUrls;
  startTime?: string;
  surveyUrl?: string;
  surveyTitle?: string | null;
  subjectOfTheDay?: boolean;
}

export interface IPostResponse extends IProfilePayload {
  post: IPost;
  reactions: IReactions;
}

export interface IPostUrls {
  api: string;
  frontend: string;
}

export type IContentItemType = 'publicPost' | 'privatePost' | 'event' | 'survey' | FeedFilterContentItemType;

export type ItemType = typeof FEED_POST_TYPE_POST | typeof FEED_POST_TYPE_EVENT;

export interface IPostMeta {
  advertised: boolean;
  fenced: boolean;
  contentItemType: IContentItemType;
  currently_advertised: boolean;
  localAuthor: boolean;
  permissions: {
    canBookmark: boolean;
    canComment: boolean;
    canDelete: boolean;
    canFlag: boolean;
    canShare: boolean;
    canUpdate: boolean;
  };
  premiumContent: boolean;
  schema: PostSchema;
  teaser: string;
  title: string;
  urls: {
    addView: string;
    edit: string;
    flag: string;
    similar?: string;
    update: string;
  };
}

export interface IPostEditView {
  attachments: IAttachment[];
  body: string;
  title: string;
  linkPreview?: ILinkPreview;
}

export interface IEventEditView {
  address: IAddress;
  attachments: IAttachment[];
  body: string;
  categories: IEventCategory[];
  endTime?: string;
  linkPreview?: ILinkPreview;
  startTime: string;
  title: string;
}
