// libs
import React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';

// components
import NavigationItem from 'src/components/navigation_item/navigation_item';
import UserOnboardingCTA from 'src/containers/smart_components/user_onboarding_cta/user_onboarding_cta';

// helpers
import { textResources } from 'src/lang/de';
import { UrlUtils } from 'src/utils/url/url';

import SegmentRightArrow from '../item_segment/parts/segment_right_arrow/segment_right_arrow';
import './group_members_link.scss';

const cls = `${CLASS_PREFIX}group_members_link`;
const clsMemberListLinkText = `${cls}__text`;

interface IProps {
  onNoReadPermissions: () => void;
  groupIdentifier: string;
  intermediateOrNotLoggedIn: boolean;
  canRead: boolean;
}

const GroupMembersLink: React.FC<IProps> = ({
  groupIdentifier,
  intermediateOrNotLoggedIn,
  onNoReadPermissions,
  canRead,
}) => {
  const navProps = canRead
    ? { url: UrlUtils.groupMembersPage(groupIdentifier) }
    : { onClick: onNoReadPermissions };
  return (
    <div className={cls}>
      <UserOnboardingCTA active={intermediateOrNotLoggedIn} >
        <NavigationItem {...navProps} >
          <span className={clsMemberListLinkText}>{textResources.groups.showMemberList}</span>
          <SegmentRightArrow />
        </NavigationItem>
      </UserOnboardingCTA>
    </div>
  );
};

/* set displayName to not have a component without a name */
GroupMembersLink.displayName = 'GroupMembersLink';

export default GroupMembersLink;
