import * as React from 'react';

// classes / components
import Avatar, { IProps as AvatarProps } from 'src/components/avatar/avatar';

import { CLASS_PREFIX } from 'src/constants/';
import './avatar_name.scss';

const cls: string = CLASS_PREFIX + 'avatar_name';
const clsAvatarWrapper: string = `${cls}__avatar-wrapper`;
const clsName: string = `${cls}__title`;

interface IProps extends AvatarProps {
  name?: string;
}

const AvatarName: React.FC<IProps> = ({ name, image, size, children }) => {
  if (!image && !name) {
    return null;
  }

  return (
    <div className={cls}>
      <div className={clsAvatarWrapper}>
        <Avatar size={size} image={image} curved={true} />
      </div>
      <div className={clsName}>
        <span>{name}</span>
      </div>
      {children && <div className={clsName}> {children}</div>}
    </div>
  );
};

/* set displayname to not have a component without a name */
AvatarName.displayName = 'AvatarName';

export default AvatarName;
