// libs
import classNames from 'classnames';
import * as React from 'react';

// interfaces / constants
import { itemSegmentCls } from 'src/components/item_segment/base/item_segment_base';
import { COLOR_TYPE_GRAY_TINT_9 } from 'src/utils/color';

import './segment_right_arrow.scss';

// classes
import Icon from 'src/components/forms/icon/icon';

const cls = itemSegmentCls + 'right-arrow';

interface SegmentRightArrowType {
  shifted?: boolean;
}

const SegmentRightArrow: React.FunctionComponent<SegmentRightArrowType> = ({ shifted = true }) => {
  const classes = {
    [cls]: true,
    [`${cls}--shifted`]: shifted,
  };

  return (
    <div className={classNames(classes)}>
      <Icon name='arrow-right' size={32} color={COLOR_TYPE_GRAY_TINT_9}/>
    </div>
  );
};

export default SegmentRightArrow;
