// libs
import classNames from 'classnames';
import React from 'react';
// interfaces / constants
import { CLASS_PREFIX } from 'src/constants';

import './item_group_header.scss';

interface IProps {
  title: string;
  subtitle?: string;
  spacing?: boolean;
}

const cls = CLASS_PREFIX + 'item-group-header';

const ItemGroupHeader: React.FC<IProps> = ({ title, subtitle, spacing, children }) => {
  const titleClassName = classNames(`${cls}__title`, spacing && `${cls}__title--spacing`);
  return (
    <div className={cls}>
      <div>
        <h2 className={titleClassName}>{title}</h2>
        {subtitle && <p className={`${cls}__subtitle`}>{subtitle}</p>}
      </div>
      {children}
    </div>);
};

export default ItemGroupHeader;
