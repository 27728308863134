import React from 'react';
import { Helmet } from 'react-helmet';
import { getConfigValue } from '../../utils/config/config';

const Eyeable = () => {
  const config = getConfigValue<{enabled: boolean; id: string}>('eyeable');
  if (config?.enabled && config?.id) {
    return <Helmet>
      <script async src={`https://cdn.eye-able.com/configs/${config.id}.js`}/>
      <script async src="https://cdn.eye-able.com/public/js/eyeAble.js"/>
    </Helmet>;
  }

  return null;
};

export default Eyeable;
