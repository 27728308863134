import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { CLASS_PREFIX } from 'src/constants';
import { textResources } from 'src/lang/de';
import { IProfileAnswer } from '../../../../interfaces/profile';
import ExpandableContent from '../../../expandable_content/expandable_content';
import './public.scss';

const cls = `${CLASS_PREFIX}profile-info__content`;

interface IProps {
  answers: IProfileAnswer[];
}

const PublicAnswers: FunctionComponent<IProps> = ({ answers }) =>
  <div className='public_answers'>
    <ExpandableContent maxHeight={185} showMoreLabel={textResources.profile.showMoreAboutMe}>
      {answers.filter(({ answer }) => !!answer).map(({ question, answer }) =>
        <div key={question} className={classNames(cls, `${cls}--no-border`)}>
          <div className={`${cls}-title`}>
            {question} ...
          </div>
          <p className={`${cls}-body`}>
        ...{answer}
          </p>
        </div>)}
    </ExpandableContent>
  </div>;

export default PublicAnswers;
