// libs
import replaceAsciiEmojis from 'emoji-shortcuts';
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { IMoreOption, MORE_OPTIONS } from 'src/constants/more_button';
import { IProps } from 'src/containers/comment';
import { IComment } from 'src/interfaces/comments';

// classes
import Avatar from 'src/components/avatar/avatar';
import LinkPreview from 'src/components/link_preview/link_preview';
import { MSGBOX_BUTTON_TYPE_DANGER, MSGBOX_BUTTON_TYPE_CANCEL } from 'src/components/message_box/message_box';
import MessageBoxWithModal from 'src/components/message_box/message_box_with_modal';
import NavigationItem from 'src/components/navigation_item/navigation_item';
import Like from 'src/components/post/shared/comments/like/like';
import More from 'src/components/post/shared/more_button/more';
import { THUMBNAIL_SIZE_TYPE_LARGE_MEDIUM_LARGE, THUMBNAIL_SIZE_TYPE_XLARGE } from 'src/components/thumbnail/thumbnail';
import ThumbnailGallery from 'src/components/thumbnail_gallery/thumbnail_gallery';

// helpers
import { textResources } from 'src/lang/de';
import { getSponsorBadge } from 'src/utils/badges';
import DateFormatter from 'src/utils/date_formatter/date_formatter';
import { makeHTMLLinksFromText } from 'src/utils/string/string';
import { UrlUtils } from 'src/utils/url/url';
import UserOnboardingCTA from '../../../../containers/smart_components/user_onboarding_cta/user_onboarding_cta';
import './comment.scss';

const cls = CLASS_PREFIX + 'comment';
const clsEditedHint = `${cls}__edited-hint`;
const clsContentsWrapper = `${cls}__contents-wrapper`;
const labels = textResources.comments;

interface IState {
  openModal: boolean;
}

export default class Comment extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.closeMessageBox = this.closeMessageBox.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleLike = this.handleLike.bind(this);
    this.handleMoreActionClick = this.handleMoreActionClick.bind(this);
    this.renderLike = this.renderLike.bind(this);
    this.renderEditedHint = this.renderEditedHint.bind(this);
    this.state = { openModal: false };
  }

  public render() {
    const { attachments, author, createdAt, message, meta, likeToggle, loggedIn } = this.props;
    const { canDelete, canUpdate } = meta;
    const { openModal } = this.state;
    const profileLink = author && UrlUtils.getProfileFrontendPath(author.id, author.type);
    const imageUrls = attachments.map((attchment) => attchment.imageUrls.original);
    const parsedMessage = this.parseMessage(message);
    const options: IMoreOption[] = [];
    if (canUpdate) {
      options.push(MORE_OPTIONS.EDIT_COMMENT);
    }
    if (canDelete) {
      options.push(MORE_OPTIONS.DELETE_COMMENT);
    }
    const isDisabled = !loggedIn && author?.type === 'private_profile';
    return (
      <div className={cls + ' ' + CLASS_PREFIX + 'popover__fix_comment'}>
        <div className={cls + '__avatar'}>
          <Avatar
            image={author?.imageUrl}
            size={THUMBNAIL_SIZE_TYPE_LARGE_MEDIUM_LARGE}
            rounded={false}
            badge={author?.sponsorLevel && getSponsorBadge(author.sponsorLevel)}
          />
        </div>
        <div className={cls + '__body'}>
          <div className={cls + '__contents'}>
            <div className={clsContentsWrapper}>
              <div className={cls + '__message'}>
                <UserOnboardingCTA active={isDisabled}>
                  <NavigationItem target='_self' url={profileLink} className={cls + '__author-name'}>
                    <div>{author?.name || ''}</div>
                  </NavigationItem>
                </UserOnboardingCTA>
                <div className={cls + '__message-content'}>
                  <div className={cls + '__message-html'} dangerouslySetInnerHTML={{ __html: parsedMessage }}/>
                  {attachments.length > 0 &&
                    <div className={cls + '__images'}>
                      <ThumbnailGallery
                        imageUrls={imageUrls}
                        size={THUMBNAIL_SIZE_TYPE_XLARGE}
                        fold
                      />
                    </div>
                  }
                </div>
              </div>

              {this.renderEditedHint()}
            </div>
            {this.renderLinkPreview()}
          </div>
          <div className={cls + '__footer'}>
            {likeToggle && (
              <div className={cls + '__reactions'}>
                {this.renderLike()}
              </div>
            )}
            <div className={cls + '__date'}>
              {DateFormatter.asTimeAgo(createdAt)}
            </div>
            <div className={cls + '__more-button'}>
              {(canDelete || canUpdate) && <More
                disabled={false}
                onChange={this.handleMoreActionClick}
                options={options}
              />
              }
            </div>
          </div>
        </div>
        {openModal &&
          <MessageBoxWithModal
            buttons={[
              {
                onClick: this.handleDeleteClick,
                type: MSGBOX_BUTTON_TYPE_DANGER,
              },
              {
                onClick: this.closeMessageBox,
                type: MSGBOX_BUTTON_TYPE_CANCEL,
              },
            ]}
            onClose={this.closeMessageBox}
            title={labels.remove}
          >
            <p>{labels.sureToRemove}</p>
          </MessageBoxWithModal>
        }
      </div>
    );
  }

  private renderLinkPreview() {
    if (!this.props.linkPreview) {
      return null;
    }
    const { author, description, displayUrl, imageUrl, title, url } = this.props.linkPreview;

    return (
      <div className={cls + '__link-preview'}>
        <LinkPreview
          author={author}
          description={description || ''}
          displayUrl={displayUrl || url}
          imageUrl={imageUrl}
          target={'_blank'}
          title={title}
          url={url}
        />
      </div>
    );
  }

  private closeMessageBox() {
    this.setState({ openModal: false });
  }

  private handleCancelClick() {
    this.closeMessageBox();
  }

  private handleDeleteClick() {
    const { deleteComment, id, urls } = this.props;
    if (deleteComment) {
      deleteComment(id, urls.resource);
    }
    this.closeMessageBox();
  }

  private handleEditClick() {
    const comment = this.props as IComment;
    const { editComment } = this.props;
    if (editComment) {
      editComment(comment);
    }
  }

  private handleLike() {
    const { id, likeToggle, reactions } = this.props;
    if (likeToggle) {
      likeToggle(id, reactions.meta.urls.update);
    }
  }

  private handleMoreActionClick(selected: IMoreOption) {
    if (selected.value === MORE_OPTIONS.DELETE_COMMENT.value) {
      this.setState({ openModal: true });
    }
    if (selected.value === MORE_OPTIONS.EDIT_COMMENT.value) {
      this.handleEditClick();
    }
  }

  private parseMessage(message: string) {
    return makeHTMLLinksFromText(replaceAsciiEmojis(message));
  }

  private renderLike(): JSX.Element | null {
    const { meta, reactions } = this.props;

    if (reactions.reactions.length === 0) {
      return null;
    }

    return (
      <Like
        active={reactions.reactions[0].own}
        likes={reactions.reactions[0].number}
        onClick={this.handleLike}
        canBookmark={!meta.canBookmark}
      />
    );
  }

  private renderEditedHint(): JSX.Element | null {
    const { createdAt, updatedAt } = this.props;

    if (updatedAt === '' || createdAt === updatedAt) {
      return null;
    }

    return (
      <div className={clsEditedHint}>
        {labels.edited(DateFormatter.asTimeAgo(updatedAt))}
      </div>
    );
  }
}
