// libs
import React, { useEffect } from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import {
  GTM_ACTION_BANNER_BLOCKER,
  GTM_CATEGORY_BANNERBLOCKER,
} from 'src/constants/google_tag_manager';

import { IDispatchFromProps as IProps } from 'src/containers/collapsed_banner';

// components
import Icon from 'src/components/forms/icon/icon';

// helpers
import { textResources } from 'src/lang/de';
import initTracker, { COLLAPSED_BANNER } from 'src/utils/reporting/events_tracking/events_tracking';
import GoogleTagManager from 'src/utils/reporting/google-tag-manager';

// styles
import './collapsed_banner.scss';

const cls = CLASS_PREFIX + 'collapsed-banner';
const trigger = cls + '__trigger';
const bannerLabel = textResources.authentication.registerLopo;
const collapsedBannerTracker = initTracker(COLLAPSED_BANNER);

const CollapsedBanner: React.FC<IProps> = ({ showRegistrationModal }) => {
  useEffect(() => {
    GoogleTagManager.pushEventVariable(GTM_CATEGORY_BANNERBLOCKER, GTM_ACTION_BANNER_BLOCKER);
    collapsedBannerTracker(COLLAPSED_BANNER.ACTIONS.SHOW, COLLAPSED_BANNER.LABELS.START);
  }, []);
  return (
    <div className={cls}>
      <div className={trigger} onClick={showRegistrationModal}>
        <div className={trigger + '__logo--small'}>
          <Icon name={'logo'} size={48} />
        </div>
        <div className={trigger + '__label--small'}>
          {bannerLabel}
        </div>
        <div className={trigger + '__arrow'}>
          <Icon name={'arrow-right'} size={18} />
        </div>
      </div>
    </div>
  );
};

export default CollapsedBanner;
