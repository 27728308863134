// libs
import classNames from 'classnames';
import * as React from 'react';

import PopoverLib, {
  callAll,
  IProps as IPopoverlibProps,
  POPOVER_ALIGN_LEFT,
  POPOVER_ALIGN_RIGHT,
  POPOVER_POSITION_BOTTOM,
  renderPropsElementType,
} from 'src/components/_libs/popover_lib/popover_lib';
import { CLASS_PREFIX } from 'src/constants/';
import './popover_menu.scss';

export { callAll };

// helpers
const cls = CLASS_PREFIX + 'popovermenu';

type IPropsFromLib = Pick<IPopoverlibProps, 'isOpen' | 'onHide' | 'onShow' | 'children'>;

interface IProps extends IPropsFromLib {
  /** class to add to the whole popover */
  className?: string;
  /** position of the menu */
  position?: typeof POPOVER_POSITION_BOTTOM;
  /** alignment of the menu */
  align: typeof POPOVER_ALIGN_LEFT | typeof POPOVER_ALIGN_RIGHT;
  /** we can pass just JSX or a React Component here */
  trigger: renderPropsElementType;
  isFullHeight?: boolean;
}

/**
 * a menu popop (for use in the header)
 * - on tablet and desktop it has the width of the content
 * - on mobile breakpoint it will grow to the full width
 * - the menu popover layout has a little margin (0.51rem)
 */
export default class PopoverMenu extends React.Component<IProps, {}> {
  public render() {
    const {
      children,
      className,
      trigger,
      position = POPOVER_POSITION_BOTTOM,
      isFullHeight = true,
      ...props
    } = this.props;

    return (
      <PopoverLib
        {...props}
        className={classNames(cls, className)}
        closeOnClickOutside
        isFullHeight={isFullHeight}
        position={position}
      >
        {({ isOpen, getTogglerProps, getPopoverProps, renderProps }) => (
          <>
            <div {...getTogglerProps()}>{renderProps(trigger)}</div>
            {isOpen && (
              <div {...getPopoverProps({ className: `${cls}__layout` })}>
                <div className={`${cls}__layout--inner`}>
                  {renderProps(children)}
                </div>
              </div>
            )}
          </>
        )}
      </PopoverLib>
    );
  }
}
