// libs
import { LanguageContext } from '@lokalportal/frontends-shared/lib/i18n/LanguageProvider';
import { SupportedLanguages } from '@lokalportal/frontends-shared/lib/i18n/model';
import classNames from 'classnames';
import { History } from 'history';
import { getCurrentLanguage } from 'i18n/i18n';
import React, { useCallback, useEffect, useState } from 'react';

// interfaces / constants
import { VIEWMODE_INBOX, VIEWMODE_ROOM, ViewMode } from 'src/constants/mailbox';

import Icon from 'src/components/forms/icon/icon';
// helpers
import api from 'src/api';
import { ProfileType } from 'src/interfaces/profile';

import EmojiPopup from 'src/components/emoji_popup/emoji_popup';

import {
  ConversationHeader,
  ConversationListHeader,
  ConversationList,
  IMessageAuthor,
  MessageComposer,
  MessageList,
  SharedThemeProvider,
  IConversationPartner,
  useConversationPartner,
  useCurrentConversationId,
} from '@lokalportal/frontends-shared';
import { Link, useParams } from 'react-router-dom';
import { textResources } from 'src/lang/de';
import { useSelectCurrentProfile } from 'src/reducers/user/user_hooks';
import { UrlUtils } from 'src/utils/url/url';
import ThumbnailGallery from '../thumbnail_gallery/thumbnail_gallery';
import Uploader from '../uploader/uploader';
import styles from './mailbox.module.scss';

import {
  removeConversation,
  setCurrentConversationId,
} from '@lokalportal/frontends-shared/lib/mailbox/store/mailboxActions';
import { useDispatch } from 'react-redux';
import { blacklistProfile as blacklistProfileAction } from 'src/actions/profile/profile';
import { MORE_OPTIONS } from 'src/constants/more_button';
import { API_OGLINKS_PATH } from 'src/constants/urls';
import { useGetNavSource } from 'src/reducers/app-state/app-state_hooks';
import colors from 'src/style/_colors.scss';
import { isNavSourceFromApp } from 'src/utils/nav_source';
import { COLOR_TYPE_GRAY_TINT_9 } from '../../utils/color';
import BackButton from '../header/subheaders/shared_contents/backbutton/backbutton';
import { MSGBOX_BUTTON_TYPE_CANCEL, MSGBOX_BUTTON_TYPE_DANGER } from '../message_box/message_box';
import MessageBoxWithModal from '../message_box/message_box_with_modal';
import More from '../post/shared/more_button/more';

export interface IMailboxProps {
  conversationId?: string;
  userId?: string;
  userType?: ProfileType;
  history: History;
}

const currentTheme: {colors: any; dark: boolean} = {
  colors,
  dark: false,
};

const renderImageGallery = (images: string[]) => (
  <div className={styles.thumbnailWrapper}>
    <ThumbnailGallery
      fold={true}
      imageUrls={images}
    />
  </div>
);

const ImageUploadButton: React.FC<{
  children: React.ReactElement;
  disabled?: boolean;
  onImagesAdded: (images: string[]) => void;
}> = ({ children, disabled = false, onImagesAdded }) => (
  <Uploader
    onImageAdded={onImagesAdded}
    disableClick={disabled}
    disabled={disabled}
  >
    {children}
  </Uploader>
);

const EmojiSelector: React.FC<{onSelectEmoji: (selectedEmoji: string) => void; disabled?: boolean}> =
  ({ onSelectEmoji, disabled }) =>
    (<EmojiPopup onEmojiSelected={onSelectEmoji} disabled={disabled} />);

const openUrl = (url: string) => { window.open(url, '_blank'); };

const ProfileButton: React.FC<{
  children: React.ReactElement;
  profile?: IMessageAuthor;
}> = ({ children, profile }) => {
  if (!profile) return children;

  return (
    <Link
      to={UrlUtils.getProfileFrontendPath(profile.id, profile.type as ProfileType)}
      className={styles.profileButton}
    >
      {children}
    </Link>
  );
};

export const Mailbox: React.FC<IMailboxProps> = ({
  history,
}) => {
  const currentProfile = useSelectCurrentProfile();
  const ownProfileGid = currentProfile.gid;
  const { conversationId } = useParams<{conversationId: string}>();
  const { profileType, profileId } = useParams<{profileType: string; profileId: string}>();
  const [viewMode, setViewMode] = useState<ViewMode>(conversationId ? VIEWMODE_ROOM : VIEWMODE_INBOX);
  const [showBlacklistModal, setShowBlacklistModal] = useState(false);
  const dispatch = useDispatch();
  const currentConversationId = useCurrentConversationId();
  const currentConversationPartner = useConversationPartner();
  const navSource = useGetNavSource();
  const isHeaderVisible = !isNavSourceFromApp(navSource);

  const onGoBack = useCallback(() => {
    setViewMode(VIEWMODE_INBOX);
    history.push(UrlUtils.conversationsPage());
    dispatch(setCurrentConversationId({ conversationId: undefined }));
  }, [dispatch, history]);

  const handleConversationChange = useCallback((conversationId: string) => {
    setViewMode(VIEWMODE_ROOM);
    history.push(UrlUtils.conversationPage(conversationId));
  }, [history]);

  const loadConversations = useCallback(
    (page: number, perPage: number) =>
      api.mailbox.conversations({ page, perPage }),
    []
  );

  const loadConversation = useCallback(
    (conversationId: string) =>
      api.mailbox.conversation({ conversationId }),
    []
  );

  const loadMessages = useCallback(
    (conversationId: string, page: number, perPage: number) =>
      api.mailbox.messages({ conversationId, page, perPage }),
    []
  );

  const sendMessage = useCallback(
    (conversationId: string, body?: string, linkPreviewUrl?: string, images?: string[]) =>
      api.mailbox.createMessage({ body, conversationId, images, linkPreviewUrl })
        .then((message) => message ?? undefined),
    []
  );

  const openBlacklistModal = useCallback(
    () => {
      setShowBlacklistModal(true);
    },
    [setShowBlacklistModal]
  );

  const closeBlacklistModal = useCallback(
    () => setShowBlacklistModal(false),
    [setShowBlacklistModal]
  );

  const blacklistProfile = useCallback((path: string) => {
    dispatch(blacklistProfileAction(path));
    history.push(UrlUtils.conversationsPage());
    if (currentConversationId) {
      dispatch(removeConversation({ conversationId: currentConversationId }));
    }
  }, [currentConversationId, dispatch, history]);

  const handleBlacklist = useCallback(() => {
    closeBlacklistModal();

    if (!currentConversationPartner) return;
    if (currentConversationPartner.urls && currentConversationPartner.urls.blacklist) {
      blacklistProfile(currentConversationPartner.urls.blacklist);
    }
  }, [blacklistProfile, closeBlacklistModal, currentConversationPartner]);

  const renderMenuButton = useCallback((conversationPartner: IConversationPartner): React.ReactNode => {
    if (!conversationPartner.meta.isBlacklistable) return null;

    return (
      <div className={styles.conversationMenu}>
        <More
          disabled={false}
          onChange={openBlacklistModal}
          options={[MORE_OPTIONS.BLACKLIST]}
          position={'bottom'}
        />
      </div>
    );
  }, [openBlacklistModal]);

  useEffect(() => {
    if (!conversationId && profileType && profileId) {
      api.mailbox.createConversation({ profileId, profileType })
        .then((conversation) => {
          if (!conversation) throw new Error(); // Get into the catch block
          history.replace(UrlUtils.conversationPage(conversation.id));
        })
        .catch(() => {
          history.replace(UrlUtils.conversationsPage());
        });
    }
  }, [conversationId, history, profileId, profileType]);

  useEffect(() => {
    setViewMode(conversationId ? VIEWMODE_ROOM : VIEWMODE_INBOX);
  }, [conversationId]);

  if (!conversationId && profileType && profileId) return null;
  if (!ownProfileGid) return null;

  const isMessengerDisabled = currentConversationPartner
    ? !currentConversationPartner.isMessengerEnabled ||
      !currentProfile.isMessengerEnabled : false;

  return (
    <SharedThemeProvider currentTheme={currentTheme}>
      <LanguageContext.Provider value={
        {
          currentLanguage: getCurrentLanguage()?.language as SupportedLanguages,
        }
      }>
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={`${styles.conversationList} ${viewMode === VIEWMODE_ROOM ? styles.hidden : ''}`}>
              <ConversationListHeader />
              <ConversationList
                loadConversations={loadConversations}
                loadConversation={loadConversation}
                ownProfileGid={ownProfileGid}
                initialConversationId={conversationId}
                setConversationId={handleConversationChange}
              />
            </div>
            <div className={
              `${styles.messageList} ` +
            `${isHeaderVisible ? styles.messageListTopMargin : ''} ` +
            `${viewMode === VIEWMODE_INBOX ? styles.hidden : ''}`} >
              <ConversationHeader
                ProfileButton={ProfileButton}
                backButton={
                  <div className={styles.backButton}>
                    <BackButton onClick={onGoBack} description={textResources.mailbox.goBackButtonTitle} />
                  </div>
                }
                renderRightButton={renderMenuButton}
              />
              <MessageList
                linkPreviewAPIUrl={API_OGLINKS_PATH}
                loadMessages={loadMessages}
                openUrl={openUrl}
                ownProfileGid={ownProfileGid}
                renderImageGallery={renderImageGallery}
              />
              {isMessengerDisabled &&
                  <div className={styles.composerMessage}>{textResources.mailbox.messagingNotAllowed}</div>
              }

              <div className={
                classNames(styles.composerWrapper, isMessengerDisabled && styles.composerWrapperDisabled)
              }>
                <MessageComposer
                  messengerDisabled={isMessengerDisabled}
                  linkPreviewAPIUrl={API_OGLINKS_PATH}
                  placeholder={textResources.mailbox.replyPlaceholder}
                  sendMessage={sendMessage}
                  cameraIcon={
                    <div style={{ marginBottom: 2 }}>
                      <Icon name={'camera-filled'} size={20} color={COLOR_TYPE_GRAY_TINT_9} />
                    </div>
                  }
                  sendIcon={
                    <div style={{ marginLeft: 2 }}>
                      <Icon name={'paper-plane-filled'} size={20} color="white" />
                    </div>
                  }
                  ImageUploadButton={ImageUploadButton}
                  removeIcon={
                    <div style={{ marginBottom: 1, marginLeft: 1 }}>
                      <Icon name={'thin-x'} size={12} color="white" />
                    </div>
                  }
                  EmojiSelector={EmojiSelector}
                />
              </div>
            </div>
          </div>
        </div>
        {showBlacklistModal ? (
          <MessageBoxWithModal
            buttons={[
              {
                label: textResources.blacklist.blacklist,
                onClick: handleBlacklist,
                type: MSGBOX_BUTTON_TYPE_DANGER,
              },
              {
                onClick: closeBlacklistModal,
                type: MSGBOX_BUTTON_TYPE_CANCEL,
              },
            ]}
            onClose={closeBlacklistModal}
            title={textResources.blacklist.modalTitle}
          >
            <p>{textResources.blacklist.modalContent}</p>
          </MessageBoxWithModal>)
          : null
        }
      </LanguageContext.Provider>
    </SharedThemeProvider>
  );
};
