import { textResources } from '../../../../lang/de';
import DateFormatter from '../../../../utils/date_formatter/date_formatter';

export const getLastPostAtTexts = (lastPostAt: string | null, isLastPostAt: boolean): {
  desktop: string;
  mobile: string;
} => {
  if (!lastPostAt || !isLastPostAt) {
    return {
      desktop: '',
      mobile: '',
    };
  }
  const [firstLetter, ...rest] = DateFormatter.asTimeAgo(lastPostAt);
  const formattedDesktop = `${firstLetter.toLowerCase()}${rest.join('')}`;

  return {
    desktop: formattedDesktop,
    mobile: textResources.groups.lastPostBefore(formattedDesktop),
  };
};
