import React from 'react';
import { Helmet } from 'react-helmet';
import { getConfigValue } from '../../utils/config/config';

const Cookiebot = () => {
  const config = getConfigValue<{enabled: boolean; id: string}>('cookiebot');
  if (config?.enabled && config?.id) {
    return <Helmet>
      <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid={`${config.id}`}
        data-blockingmode="auto" type="text/javascript"></script>
    </Helmet>;
  }

  return null;
};

export default Cookiebot;
