// libs
import React, { useCallback, useMemo } from 'react';
import { useTriggerBlock } from 'src/pages/hooks/hooks';
import { useSelectIsUserLoggedIn } from 'src/reducers/user/user_hooks';
import { getConfigValue } from 'src/utils/config/config';

// constants & interfaces
import { IGetProfilesParams } from 'src/api';
import { PROFILES_SEARCH_QUERY_KEY } from 'src/constants/urls';

// components
import { CategorizedView, SearchView, IViewProps } from './views';

// helpers
import {
  useProfileFirstPages,
  useGetProfilesNextPage,
} from 'src/actions/profiles_list/profiles_list_hooks';
import { useGetQueryParam, useSetQueryParam } from 'src/hooks_shared/use_url_search_param';
import { useGetBoundingBox } from 'src/reducers/app-state/app-state_hooks';
import {
  useProfilesSecondaryList,
  useProfilesFromIds,
  useSelectSecondaryProfileIds,
} from 'src/reducers/profiles_list/profiles_list_hooks';
import initTracker,
{ PROFILE_DIRECTORY } from 'src/utils/reporting/events_tracking/events_tracking';

const tracker = initTracker(PROFILE_DIRECTORY);

const ProfilesDirectoryCategorized: React.FC = () => {
  const query = useGetQueryParam(PROFILES_SEARCH_QUERY_KEY) || '';
  const setUrlQueryParam = useSetQueryParam(PROFILES_SEARCH_QUERY_KEY);

  const { isLoading: isSecondaryLoading } = useProfilesSecondaryList();
  const secondaryIds = useSelectSecondaryProfileIds();
  const profilesList = useProfilesFromIds(secondaryIds);
  const boundingBox = useGetBoundingBox();
  const isUserLoggedIn = useSelectIsUserLoggedIn();

  const params = useMemo<IGetProfilesParams>(() => ({
    boundingBox,
    orderBy: 'created_at',
    orderDirection: 'desc',
    query,
  }), [boundingBox, query]);

  const getNextPage = useGetProfilesNextPage(params);

  useProfileFirstPages(params);

  const onSubmit = useCallback((newQuery: string) => {
    const cleanedQuery = newQuery.trim();
    if (cleanedQuery) {
      tracker(PROFILE_DIRECTORY.ACTIONS.SEARCH, PROFILE_DIRECTORY.LABELS.FINISH);
    }
    setUrlQueryParam(cleanedQuery);
  }, [setUrlQueryParam]);

  const sharedProps: IViewProps = {
    getNextPage,
    isSecondaryLoading,
    onSubmit,
    profilesList,
    query,
  };
  const shouldHideSearch = !!getConfigValue<boolean>('featureFlags.search.disableSearchForLoggedOut');

  useTriggerBlock(shouldHideSearch && !isUserLoggedIn);

  if (query) {
    return <SearchView {...sharedProps} />;
  }
  return <CategorizedView {...sharedProps} />;
};

ProfilesDirectoryCategorized.displayName = 'ProfilesDirectoryCategorized';

export default ProfilesDirectoryCategorized;
