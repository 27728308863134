// libs
import React, { useCallback, useState } from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { FRONTEND_EVENT_PARTICIPANTS_PATH, FRONTEND_EVENT_PATH } from 'src/constants/urls';
import { IProps } from 'src/containers/event_participation';
import { IParticipants } from 'src/interfaces/post_ingredients';
import {
  mapDisplayModeToConst,
  POST_DISPLAY_MODE_BANNER,
  POST_DISPLAY_MODE_PREVIEW,
  PostDisplayMode,
} from 'src/interfaces/posts';
import { COLOR_TYPE_GRAY_TINT_9, COLOR_TYPE_PRIMARY, COLOR_TYPE_SECONDARY7, ColorType } from 'src/utils/color';

// classes
import { BUTTON_TYPE_CONTAINED } from 'src/components/forms/button/button';
import Icon from 'src/components/forms/icon/icon';
import {
  MSGBOX_BUTTON_TYPE_DANGER,
  MSGBOX_BUTTON_TYPE_CANCEL,
} from 'src/components/message_box/message_box';
import MessageBoxWithModal from 'src/components/message_box/message_box_with_modal';
import NavigationItem from 'src/components/navigation_item/navigation_item';
import IngredientRow from 'src/components/post/shared/ingredient_row/ingredient_row';
import EventParticipationButton from 'src/components/post/shared/ingredients/event_participation/event_participation_button';
import UserOnboardingCTA from 'src/containers/smart_components/user_onboarding_cta/user_onboarding_cta';

// helpers
import { textResources } from 'src/lang/de';

// styles
import './event_participation.scss';

const clsPrefix = `${CLASS_PREFIX}event-participation__`;

interface IParticipatingMap {
  color: ColorType;
  iconName: string;
}

const label = textResources.eventParticipants;

const participating: IParticipatingMap = {
  color: COLOR_TYPE_PRIMARY,
  iconName: 'checkmark-outlined',
};

const notParticipating: IParticipatingMap = {
  color: COLOR_TYPE_SECONDARY7,
  iconName: 'checkmark-outlined',
};

interface IParticipatingLinkProps {
  displayMode: PostDisplayMode;
  loggedIn: boolean;
  postDetailsURL?: string;
  participantsRoute: string;
  className: string;
}

interface IParticipantsListProps {
  cls: string;
  data: IParticipants;
  displayMode: PostDisplayMode;
}

interface IParticipantsListWithButtonProps {
  cls: string;
  props: IProps;
  onClick: () => void;
}

interface IParticipateButtonProps {
  cls: string;
  data: IParticipants;
  displayMode: PostDisplayMode;
  onClick: () => void;
}
const ParticipationLink: React.FC<IParticipatingLinkProps> = ({
  children, loggedIn, postDetailsURL, participantsRoute, displayMode, className,
}) => {
  if (displayMode === POST_DISPLAY_MODE_BANNER) {
    return (
      <NavigationItem
        className={className}
        target='_self'
        url={postDetailsURL}
      >{children}</NavigationItem>
    );
  }
  return (
    <UserOnboardingCTA active={!loggedIn}>
      <NavigationItem
        className={className}
        target='_self'
        url={participantsRoute}
      >{children}</NavigationItem>
    </UserOnboardingCTA>
  );
};

const EventParticipation: React.FC<IProps> = (props) => {
  const { createParticipation, displayMode, data, postId } = props;
  const cls = `${clsPrefix}${mapDisplayModeToConst[displayMode]}`;
  const isInPreviewMode = displayMode === POST_DISPLAY_MODE_PREVIEW;
  const [show, setShown] = useState(false);

  const showModal = useCallback(() => {
    setShown(true);
  }, [setShown]);

  const hideModal = useCallback(() => {
    setShown(false);
  }, [setShown]);

  const buttonOnClick = onClickParticipate(createParticipation, data, postId, showModal);

  return (
    <>
      {isInPreviewMode
        ? <ParticipantsList cls={cls} data={data} displayMode={displayMode} />
        : <ParticipantsListWithButton cls={cls} props={props} onClick={buttonOnClick} />
      }
      {show &&
        <MessageBoxWithModal
          buttons={[
            {
              label: label.deleteModalButton,
              onClick: deleteParticipation(props, hideModal),
              type: MSGBOX_BUTTON_TYPE_DANGER,
            },
            {
              onClick: hideModal,
              type: MSGBOX_BUTTON_TYPE_CANCEL,
            },
          ]}
          onClose={hideModal}
          title={label.deleteModalTitle}
        >
          <p>{label.deleteModalBody}</p>
        </MessageBoxWithModal>
      }
    </>
  );
};

const ParticipantsList: React.FC<IParticipantsListProps> = ({
  cls, data, displayMode,
}) => {
  const { isParticipating, participantsCount } = data;
  const participantCountLabel = getParticipationCountString(isParticipating, participantsCount);
  const isInPreviewMode = displayMode === POST_DISPLAY_MODE_PREVIEW;

  if (!participantsCount) {
    return null;
  }
  return (
    <div className={cls + '__columns'}>
      <IngredientRow
        icon='participant-icon'
        title={participantCountLabel}
        displayMode={displayMode}
      />
      {!isInPreviewMode &&
        <Icon className={cls + '__icon'} size={32} name='arrow-right' color={COLOR_TYPE_GRAY_TINT_9} />
      }
    </div>
  );
};

const ParticipantsListWithButton: React.FC<IParticipantsListWithButtonProps> = ({
  cls, props, onClick,
}) => {
  const { data, displayMode, loggedIn, postDetailsURL, postId } = props;
  const participantsRoute = `${FRONTEND_EVENT_PATH}/${postId}${FRONTEND_EVENT_PARTICIPANTS_PATH}`;
  return (
    <>
      {
        data.participantsCount > 0 &&
        <ParticipationLink
          postDetailsURL={postDetailsURL}
          loggedIn={loggedIn}
          participantsRoute={participantsRoute}
          displayMode={displayMode}
          className={cls + '__list'}
        >
          <ParticipantsList cls={cls} data={data} displayMode={displayMode} />
        </ParticipationLink>
      }
      <ParticipateButton cls={cls} data={data} displayMode={displayMode} onClick={onClick} />
    </>
  );
};

const ParticipateButton: React.FC<IParticipateButtonProps> = ({
  cls, data, displayMode, onClick,
}) => {
  const { canParticipate, isLoading, isParticipating } = data;
  const [color, iconName] = isParticipating
    ? [participating.color, participating.iconName]
    : [notParticipating.color, notParticipating.iconName];
  const displayModeConst = mapDisplayModeToConst[displayMode];
  return (
    <div className={cls + '__button-container'}>
      <UserOnboardingCTA active={!canParticipate}>
        <EventParticipationButton
          disabled={isLoading}
          onClick={onClick}
          variant={BUTTON_TYPE_CONTAINED}
          icon={iconName}
          label={isParticipating ? label.imParticipating : label.iWantToParticipate}
          color={color}
          fullWidth={true}
          displayMode={displayModeConst}
          lowerCase={true}
        />
      </UserOnboardingCTA>
    </div>
  );
};

const getParticipationCountString = (isParticipating: boolean, participantsCount: number): string => {
  if (isParticipating) {
    if (participantsCount > 1) {
      // The user is already included in participationCount, so we have to subtract him.
      return `${label.you} ${textResources.shared.and} ${participantsCount - 1} ${label.participants}`;
    }
    return label.justYou;
  }
  return `${participantsCount} ${label.participants}`;
};

const deleteParticipation = (props: IProps, callback?: () => void) => () => {
  const { data: { isParticipating, participateUrl }, postId, deleteParticipation } = props;

  if (isParticipating) {
    deleteParticipation(participateUrl, postId);
  }

  callback && callback();
};

const onClickParticipate = (
  createParticipation: (url: string, id: string) => void, data: IParticipants, postId: string, showModal: () => void,
) => () => {
  const { isParticipating, participateUrl } = data;
  if (isParticipating) {
    showModal();
  } else {
    createParticipation(participateUrl, postId);
  }
};

export default EventParticipation;
