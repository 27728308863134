import React, { FunctionComponent } from 'react';
import UserOnboardingCTA from '../../../../containers/smart_components/user_onboarding_cta/user_onboarding_cta';
import { ItemSegmentHeight } from '../../../item_segment/base/item_segment_base';
import PostCreateItemSegment from '../../../item_segment/layouts/post_create/post_create';
import * as circlePlusPrimary from './circle_plus_primary.svg';

interface CreateItemProps {
  active: boolean;
  onClick: () => void;
  label: string;
  centerText?: boolean;
  height?: ItemSegmentHeight;
}

const CreateItem: FunctionComponent<CreateItemProps> = ({ active, height, onClick, label, centerText, children }) =>
  <UserOnboardingCTA active={active}>
    <PostCreateItemSegment
      navigation={{ onClick: onClick }}
      avatar={{ imageUrl: circlePlusPrimary }}
      label={label}
      center={centerText}
      height={height}>
      {children}
    </PostCreateItemSegment>
  </UserOnboardingCTA>;

export default CreateItem;
