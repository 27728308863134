// libs
import classNames from 'classnames';
import React, { useCallback } from 'react';

// components
import EmptySearchResults from 'src/components/empty_search_results/empty_search_results';
import ItemGroupHeader from 'src/components/item_group_header/item_group_header';
import LoadingSpinnerWrapper from 'src/components/loading_spinner/loading_spinner_wrapper';
import ProfilesList from 'src/components/profiles_list/profiles_list';
import ProfilesListCategorized from 'src/components/profiles_list/profiles_list_categorized';
import SearchField from 'src/components/search_field/search_field';
import ProfileListWrapper from 'src/containers/smart_components/list_wrapper/profile_list_wrapper';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants';
import { IProfile } from 'src/interfaces/profile';

// helpers
import { useMarkersInViewport } from 'src/actions/feed/feed_hooks';
import { textResources } from 'src/lang/de';
import { useSelectProfiles } from 'src/reducers/profiles/profiles_hooks';
import {
  useProfilesList,
  useCategorizedProfiles,
  useSelectSecondaryProfileIds,
  useSelectAllIds,
} from 'src/reducers/profiles_list/profiles_list_hooks';

import { useCallToAction } from '../../../../actions/onboarding_cta/onboarding_cta_hooks';
import { useSelectIsUserLoggedIn } from '../../../../reducers/user/user_hooks';
import { getConfigValue } from '../../../../utils/config/config';
import './profiles_directory_categorized.scss';

const cls = CLASS_PREFIX + 'profiles-directory-categorized';
const searchCls = cls + '__search';

export interface IViewProps {
  isSecondaryLoading: boolean;
  getNextPage: () => void;
  profilesList: IProfile[];
  onSubmit: (query: string) => void;
  query: string;
}

const useHideSearch = () => {
  const shouldHideSearch = !!getConfigValue<boolean>('featureFlags.search.disableSearchForLoggedOut');
  const callToAction = useCallToAction();
  const isUserLoggedIn = useSelectIsUserLoggedIn();

  return useCallback((event, callback) => {
    if (shouldHideSearch && !isUserLoggedIn) {
      event.preventDefault();
      callback();
      callToAction();
    }
  }, [callToAction, shouldHideSearch, isUserLoggedIn]);
};

export const CategorizedView: React.FC<IViewProps> = ({
  getNextPage,
  profilesList,
  isSecondaryLoading,
  onSubmit,
  query,
}) => {
  const { isLoading } = useProfilesList();
  const allIds = useSelectAllIds();
  const record = useSelectProfiles();
  const isUserLoggedIn = useSelectIsUserLoggedIn();
  const shouldHideSections = !!getConfigValue<boolean>('featureFlags.search.hideSectionsForLoggedOut');
  const profilesCategorized = useCategorizedProfiles(shouldHideSections);
  const emptyDirectory = profilesCategorized.length === 0 && profilesList.length === 0;
  useMarkersInViewport(allIds, record);
  const hideSearch = useHideSearch();

  return (
    <div>
      <div className={cls}>
        <div className={searchCls}>
          <SearchField
            onSubmit={onSubmit}
            defaultValue={query}
            onFocus={hideSearch}
          />
          {emptyDirectory && !isLoading &&
          <EmptySearchResults subtitle={textResources.shared.noLocalResultFound} />}
        </div>
      </div>
      <LoadingSpinnerWrapper isLoading={isLoading || isSecondaryLoading}>
        <ProfilesListCategorized categorizedProfiles={profilesCategorized} />
        { (!shouldHideSections || isUserLoggedIn) && !!profilesList.length &&
          <>
            <div className={`${cls}__more-title`}>
              <ItemGroupHeader
                title={textResources.shared.hereIsMore}
                subtitle={textResources.profileSearch.relatedContentSubtitle}/>
            </div>
            <ProfileListWrapper
              isSecondary
              getNextPage={getNextPage}
              initialLoad={false}
            >
              <ProfilesList profiles={profilesList}/>
            </ProfileListWrapper></>
        }
      </LoadingSpinnerWrapper>
    </div>
  );
};

export const SearchView: React.FC<IViewProps> = ({
  getNextPage,
  profilesList,
  isSecondaryLoading,
  onSubmit,
  query,
}) => {
  const secondaryIds = useSelectSecondaryProfileIds();
  const record = useSelectProfiles();
  const hideSearch = useHideSearch();

  useMarkersInViewport(secondaryIds, record);
  return (
    <div>
      <div className={cls}>
        <div className={classNames(searchCls, searchCls + '--show-search-results')}>
          <SearchField
            onSubmit={onSubmit}
            defaultValue={query}
            onFocus={hideSearch}
          />
          {profilesList.length === 0 && !isSecondaryLoading &&
          <EmptySearchResults subtitle={textResources.shared.noLocalResultFound} />
          }
        </div>
      </div>
      <LoadingSpinnerWrapper isLoading={isSecondaryLoading}>
        <ProfileListWrapper
          isSecondary
          getNextPage={getNextPage}
        >
          <ProfilesList profiles={profilesList} />
        </ProfileListWrapper>
      </LoadingSpinnerWrapper>
    </div>
  );
};
