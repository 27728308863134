// interfaces & constants
import { IIdentifiable, ISharedImageUrls } from 'src/api/interfaces/responses/shared';
import * as Response from 'src/api/interfaces/responses/user';
import {
  AUTHORITY_PROFILE_TYPE,
  BLOG_PROFILE_TYPE,
  INTERMEDIATE_PROFILE_TYPE,
  PRESS_PROFILE_TYPE,
  PRIVATE_PROFILE_TYPE,
} from 'src/constants/profile';
import {
  IAvatar,
  IContact,
  ICTAs,
  IIdentity,
  INotificationsSettings,
  IPermissions,
  IProfiles,
  IUpdatableUser,
  IUser,
  IUserMeta,
  IUserProfile,
  IUserProfileIdentifiers,
  IUserProfileMeta,
  IUserProfileSettings,
  IUserProfileType,
  IUserProfileUrls,
  IUserUpdatePhone,
} from 'src/interfaces/user';

// decoders
import { $address } from 'src/api/decoders/address';
import { $category } from 'src/api/decoders/category';
import { $topic } from 'src/api/decoders/group';
import { $locationShape } from 'src/api/decoders/location_shape';
import { $profileTags } from 'src/api/decoders/tags';
import { isPrivateProfile } from 'src/utils/profile';
import { $questionsAnswers } from './profile';

/******************************************************************************
 * User
 */
export const $user = (longIdentifier?: string) => (json: Response.IUserResponse): IUser => {
  const profiles: IProfiles = $userProfiles(json);
  const idToSwitchTo = longIdentifier && profiles[longIdentifier]?.longIdentifier;
  const firstPrivateProfileId = Object.values(profiles).find(profile => isPrivateProfile(profile.type))?.longIdentifier;

  // just select any profileId if no other profileId should be selected
  const fallbackProfileId = Object.values(profiles)[0].longIdentifier;
  const selectedProfileId = idToSwitchTo || firstPrivateProfileId || fallbackProfileId;

  return {
    confirmed: json.data.confirmed,
    email: json.data.email,
    identities: json.data.identities.map($identity),
    hasToken: true,
    loggedIn: true,
    meta: $userMeta(json.data.meta),
    phone: json.data.phone || undefined,
    profiles,
    ...$receiveNewsletter(json),
    selectedProfileId,
    unverifiedPhone: json.data.unverified_phone || undefined,
    verified: json.data.verified,
    identifier: json.data.identifier,
  };
};

const $identity = (json: Response.IIdentity): IIdentity => ({
  externalUid: json.external_uid,
  globalStableIdentifier: json.global_stable_identifier,
  id: json.identifier,
  meta: json.meta,
  provider: json.provider,
  type: json.type,
});

const $userMeta = (json: Response.IUserMeta): IUserMeta => ({
  ableToVerifyAgainNow: json.able_to_verify_again_now,
  maxPhoneVerificationAttempts: json.max_phone_verification_attempts || undefined,
  maxPhoneVerificationTime: json.max_phone_verification_time || undefined,
  minTimeBetweenVerifications: json.min_time_between_verifications || undefined,
  pendingEmailReconfirmation: json.pending_email_reconfirmation,
  phoneVerificationPending: json.phone_verification_pending,
  postcardVerificationPending: json.postcard_verification_pending,
  remainingPhoneVerificationAttempts: json.remaining_phone_verification_attempts || undefined,
});

const $userProfiles = (json: Response.IUserResponse): IProfiles => {
  const result: IProfiles = {};

  if (json.data.private_profile) {
    result[json.data.private_profile.global_stable_identifier] = $privateProfile(json.data.private_profile);
  }

  if (json.data.intermediate_profile) {
    result[json.data.intermediate_profile.global_stable_identifier] =
      $intermediateProfile(json.data.intermediate_profile);
  }

  json.data.blog_profiles.map((blogProfile) => {
    result[blogProfile.global_stable_identifier] = $blogProfile(blogProfile);
  });

  json.data.authority_profiles.map((authorityProfile) => {
    result[authorityProfile.global_stable_identifier] = $authorityProfile(authorityProfile);
  });

  json.data.press_profiles.map((pressProfile) => {
    result[pressProfile.global_stable_identifier] = $pressProfile(pressProfile);
  });

  return result;
};

export const $receiveNewsletter = (json: Response.IUserResponse): IUpdatableUser => ({
  receiveNewsletter: json.data.receive_newsletter === null ? undefined : json.data.receive_newsletter,
});

/******************************************************************************
 * PrivateProfile
 */

export const $privateProfileResponse = (json: Response.IPrivateProfileResponse) => $privateProfile(json.data);

const $privateProfile = (json: Response.IPrivateProfile): IUserProfile => ({
  ...$profile(json),
  ...$fullProfile(json),
  ...$identifiableProfile(json),
  dayOfBirth: json.day_of_birth,
  meta: $privateProfileMeta(json.meta),
  notificationSettings: $profileNotificationSettings(json.settings),
  privatePostTopic: $topic(json.private_post_topic),
  settings: $privateProfileSettings(json.settings),
  type: PRIVATE_PROFILE_TYPE,
  isMessengerEnabled: json.set_messenger_availability,
});

const $privateProfileMeta = (json: Response.IPrivateProfileMeta): IUserProfileMeta => $profileMeta(json);

const $privateProfileSettings = (json: Response.IPrivateProfileNotificationsSettings): IUserProfileSettings => ({
  ...$allowReceiveDailyEmailSettings(json),
});

/******************************************************************************
 * IntermediateProfile
 */

export const $intermediateProfileResponse = (json: Response.IIntermediateProfileResponse) =>
  $intermediateProfile(json.data);

const $intermediateProfile = (json: Response.IIntermediateProfile): IUserProfile => ({
  ...$profile(json),
  ...$officialProfile(json),
  ...$identifiableProfile(json),
  categories: json.categories.map($category),
  contact: $profileNullableContact(json.contact),
  cta: $profileCallToActions(json.cta),
  meta: $intermediateProfileMeta(json.meta),
  targetModel: json.target_model,
  type: INTERMEDIATE_PROFILE_TYPE,
  urls: $profileUrls(json.urls),
  isMessengerEnabled: true,

});

const $intermediateProfileMeta = (json: Response.IIntermediateProfileMeta): IUserProfileMeta => $profileMeta(json);

/******************************************************************************
 * Upgrade intermediate profile
 */
export const $upgradeProfileResponse = (target?: IUserProfileType) => (json: Response.IAnyProfileResponse) => {
  switch (target) {
    case PRIVATE_PROFILE_TYPE:
      return $privateProfile(json.data as Response.IPrivateProfile);
    case BLOG_PROFILE_TYPE:
      return $blogProfile(json.data as Response.IBlogProfile);
    case PRESS_PROFILE_TYPE:
      return $pressProfile(json.data as Response.IPressProfile);
    case AUTHORITY_PROFILE_TYPE:
      return $authorityProfile(json.data as Response.IAuthorityProfile);
    default:
      return $intermediateProfile(json.data as Response.IIntermediateProfile);
  }
};

/******************************************************************************
 * BlogProfile
 */
export const $blogProfileResponse = (json: Response.IBlogProfileResponse) => $blogProfile(json.data);

const $blogProfile = (json: Response.IBlogProfile): IUserProfile => ({
  ...$profile(json),
  ...$fullProfile(json),
  ...$officialProfile(json),
  ...$identifiableProfile(json),
  categories: json.categories.map($category),
  meta: $blogProfileMeta(json.meta),
  notificationSettings: $profileNotificationSettings(json.extension_participation.settings),
  settings: $blogProfileSettings(json.settings),
  type: BLOG_PROFILE_TYPE,
  isMessengerEnabled: true,
});

const $blogProfileMeta = (json: Response.IBlogProfileMeta): IUserProfileMeta => $profileMeta(json);

const $blogProfileSettings = (json: Response.IBlogProfileSettings): IUserProfileSettings =>
  $allowReceiveMessageSettings(json);

/******************************************************************************
 * AuthorityProfile
 */

export const $authorityProfileResponse = (json: Response.IAuthorityProfileResponse) => $authorityProfile(json.data);

const $authorityProfile = (json: Response.IAuthorityProfile): IUserProfile => ({
  ...$profile(json),
  ...$fullProfile(json),
  ...$locationBasedProfile(json),
  ...$officialProfile(json),
  ...$identifiableProfile(json),
  meta: $authorityProfileMeta(json.meta),
  notificationSettings: $profileNotificationSettings(json.extension_participation.settings),
  settings: $authorityProfileSettings(json.settings),
  type: AUTHORITY_PROFILE_TYPE,
  isMessengerEnabled: true,
});

const $authorityProfileMeta = (json: Response.IAuthorityProfileMeta): IUserProfileMeta => $profileMeta(json);

const $authorityProfileSettings = (json: Response.IAuthorityProfileSettings): IUserProfileSettings => ({
  ...$allowPostCommentSettings(json),
  ...$allowReceiveMessageSettings(json),
});

/******************************************************************************
 * PressProfile
 */

export const $pressProfileResponse = (json: Response.IPressProfileResponse) => $pressProfile(json.data);

const $pressProfile = (json: Response.IPressProfile): IUserProfile => ({
  ...$profile(json),
  ...$fullProfile(json),
  ...$locationBasedProfile(json),
  ...$officialProfile(json),
  ...$identifiableProfile(json),
  logoLeft: $profileImageUrls(json.logo_left),
  logoTop: $profileImageUrls(json.logo_top),
  meta: $pressProfileMeta(json.meta),
  notificationSettings: $profileNotificationSettings(json.extension_participation.settings),
  panelBranding: $profileImageUrls(json.panel_branding),
  panelBrandingText: json.panel_branding_text,
  settings: $pressProfileSettings(json.settings),
  type: PRESS_PROFILE_TYPE,
  isMessengerEnabled: true,
});

const $pressProfileMeta = (json: Response.IPressProfileMeta): IUserProfileMeta => $profileMeta(json);

const $pressProfileSettings = (json: Response.IPressProfileSettings): IUserProfileSettings => ({
  ...$allowPostCommentSettings(json),
  ...$allowReceiveMessageSettings(json),
});

/******************************************************************************
 * Shared profile related decoders
 */

const $profile = (json: Response.IProfile): Partial<IUserProfile> => ({
  address: $address(json.address),
  avatar: $profileImageUrls(json.avatar),
  banner: $profileImageUrls(json.banner),
  description: json.description,
  name: json.name,
  permissions: $profilePermissions(json.meta.permissions),
  tags: json.taggings && $profileTags(json.taggings),
  ...$questionsAnswers(json.questions_answers),
  profileLinks: json.profile_links,
});

const $fullProfile = (json: Response.IFullProfile): Partial<IUserProfile> => ({
  contact: $profileContact(json.contact),
  enableProfileVisits: json.enable_profile_visits,
  urls: $profileFullUrls(json.urls),
});

const $locationBasedProfile = (json: Response.ILocationBasedProfile): Partial<IUserProfile> => ({
  locationShapes: json.location_shapes.map($locationShape),
});

const $officialProfile = (json: Response.IOfficialProfile): Partial<IUserProfile> => ({
  siteNotice: json.site_notice,
  website: json.website,
});

export const $notificationSettings = (json: Response.IExtensionParticipationResponse) =>
  $profileNotificationSettings(json.data.settings);

const $profileNotificationSettings = (json: Response.ISettings): INotificationsSettings => json.notifications;

const $identifiableProfile = (json: IIdentifiable): IUserProfileIdentifiers => ({
  gid: json.gid,
  id: json.identifier,
  longIdentifier: json.global_stable_identifier,
});

const $profileNullableContact = (json: Response.INullableContact): IContact => ({
  firstName: json.first_name || '',
  lastName: json.last_name || '',
  phone: json.phone || '',
});

const $profileContact = (json: Response.IContact): IContact => ({
  firstName: json.first_name,
  lastName: json.last_name,
  phone: json.phone || undefined,
});

const $profileImageUrls = (json: ISharedImageUrls): IAvatar | undefined => {
  if (json.standard_image !== undefined && json.image_urls.square_small) {
    return {
      standardImage: json.standard_image,
      url: json.image_urls.square_small,
    };
  }

  return undefined;
};

const $profileMeta = (json: Response.IProfileMeta): IUserProfileMeta => ({
  blacklistMe: json.blacklists_me,
  isBlacklistable: json.is_blacklistable,
  isBlacklisted: json.is_blacklisted,
  isGraylistable: json.is_graylistable,
  isGraylisted: json.is_graylisted,
  isHome: true,
  isLocationBased: json.is_location_based,
  isMessagable: json.is_messagable,
  isOwn: json.is_own,
});

const $profileUrls = (json: Response.IProfileUrls): IUserProfileUrls => ({
  editableResource: json.editable_resource,
  validateProfile: json.validate_profile,
});

const $profileFullUrls = (json: Response.IFullProfileUrls): IUserProfileUrls => ({
  editableResource: json.editable_resource,
  resource: json.resource,
  validateProfile: json.validate_profile,
});

export const $updatePhone = ({ data }: Response.IUpdatePhone): IUserUpdatePhone => ({
  meta: {
    phoneVerificationPending: data.meta.phone_verification_pending,
    remainingPhoneVerificationAttempts: data.meta.remaining_phone_verification_attempts,
  },
  phone: data.phone,
  unverifiedPhone: data.unverified_phone,
});

/******************************************************************************
 * Shared setting related decoders
 */
const $allowReceiveMessageSettings = (json: Response.IProfileAllowReceiveMessageSettings): IUserProfileSettings => ({
  allowReceiveMessages: json.allow_receive_messages,
});

const $allowPostCommentSettings = (json: Response.IProfileAllowPostCommentSettings): IUserProfileSettings => ({
  allowPostComments: json.allow_post_comments,
});

// only used by private profiles:
const $allowReceiveDailyEmailSettings =
  (json: Response.IPrivateProfileNotificationsSettings): IUserProfileSettings => ({
    allowReceiveDailyEmails: json.receive_daily_update_email,
  });

/******************************************************************************
 * Shared permission related decoders
 */

const $profilePermissions = (json: Response.IProfilePermissions): IPermissions => ({
  blacklist: json.blacklist || false,
  bookmarkPosts: json.bookmark_posts || false,
  canReadPremiumContent: json.read_premium_posts || false,
  createBlogProfile: json.create_blog_profile || false,
  createComments: json.create_comments || false,
  createEvents: json.create_events || false,
  createGroups: json.create_groups || false,
  createPosts: json.create_posts || false,
  createPrivateProfile: json.create_private_profile || false,
  follow: json.follow || false,
  joinGroups: json.join_groups || false,
  readCommentCounts: json.read_comment_counts || false,
  readComments: json.read_comments || false,
  readGlobals: json.read_globals || false,
  readMessages: json.read_messages || false,
  readNotifications: json.read_notifications || false,
  readOwnPage: json.read_own_page || false,
  readPremiumPosts: json.read_premium_posts || false,
  readQuestions: json.read_questions || false,
});

const $profileCallToActions = (json: Response.IProfileCallToActions): ICTAs => ({
  bookmarkPosts: json.bookmark_posts || false,
  createComments: json.create_comments || false,
  createEvents: json.create_events || false,
  createPosts: json.create_posts || false,
  joinGroups: json.join_groups || false,
  readBookmarks: json.read_bookmarks || false,
  readComments: json.read_comments || false,
  readKiosk: json.read_kiosk || false,
  readOwnPage: json.read_own_page || false,
  readPremiumPosts: json.read_premium_posts || false,
  type: json.type,
  updateMe: json.update_me || false,
});
