import {
  create,
  destroy,
  read,
  update,
} from 'src/api/http';
import { UrlUtils } from 'src/utils/url/url';

import * as decoder from 'src/api/decoders';
import * as encoder from 'src/api/encoders';
import {
  AUTHORITY_PROFILE_TYPE,
  BLOG_PROFILE_TYPE,
  PRESS_PROFILE_TYPE,
  PRIVATE_PROFILE_TYPE,
  REPORTER_PROFILE_TYPE,
} from 'src/constants/profile';
import * as PATH from 'src/constants/urls';

import {
  ICategorizedEventfeedRequestParams,
  IEventfeedRequestParams,
  INewsfeedRequestParams,
  IParticipatingFeedRequestParams,
  IProfileFeedRequestParams,
  IGroupFeedRequestParams,
} from 'src/actions/feed/feed_interfaces';
import { ICategoryType, OrderByCreatedAt, OrderByName } from 'src/api/interfaces/requests';
import { OrderDirection } from 'src/api/interfaces/responses/shared';
import { Id } from 'src/interfaces';
import { IImageObject } from 'src/interfaces/attachment';
import { IFlagCreateData } from 'src/interfaces/flag';
import { IBoundingBox, IPosition } from 'src/interfaces/location';
import { NotificationEventCode, NotificationTraitType } from 'src/interfaces/notification';
import { IEventCreateData, IPostCreateData, ISnapshotCreateData, ItemType } from 'src/interfaces/posts';
import { ProfileType } from 'src/interfaces/profile';
import { ReactionName } from 'src/interfaces/reactions';
import { IUserProfileType } from 'src/interfaces/user';
import { pascalCase } from '../utils/string/string';
import { IExtensionLink, IExtensionLinkPlatform } from './interfaces/responses/profile';

export interface IPaginationParams {
  page?: number;
  perPage?: number;
  orderBy?: string;
  sortBy?: string;
}

export interface IReactionUpdateConfig {
  bookmarkType: ReactionName;
  path: string;
}

export interface ICampaignCreate {
  data: {
    ad_campaign: {
      amount: string;
    };
  };
  path: string;
}

export interface ICommentCreate {
  images?: string[];
  message: string;
  linkPreviewId?: string;
  path: string;
}

export interface ICommentUpdate {
  addedImages?: string[];
  deletedImagesIds?: string[];
  message: string;
  linkPreviewId?: string;
  path: string;
}

interface IMailboxMessagesParams extends IPaginationParams {
  conversationId: string;
}

export interface ISendMessageParams {
  body?: string;
  conversationId: string;
  images?: string[];
  linkPreviewUrl?: string;
}

interface IConversationParams {
  conversationId: string;
}

interface ICreateConversationParams {
  profileType: string;
  profileId: string;
}

export interface IPostCreate {
  data: IPostCreateData | ISnapshotCreateData;
  path: string;
}

export interface IPostUpdateParam {
  data: IPostUpdateData;
  path: string;
  postId: string;
}

export interface IPostUpdateData {
  addedImages?: IImageObject[];
  body?: string;
  commentable?: boolean;
  deletedImagesIds?: string[];
  changedImages?: IImageObject[];
  linkPreviewId?: string | null;
  title?: string;
}

export interface IEventUpdateParam {
  data: IEventUpdateData;
  path: string;
  postId: string;
}

export interface IEventUpdateData extends IPostUpdateData {
  categoryId?: string;
  endTime?: string;
  startTime?: string;
}

export interface IPostGetParams {
  previewToken?: string;
}

export interface IEventCreate {
  data: IEventCreateData;
  path: string;
}

export interface ILookupParam {
  onlyReleased?: boolean;
  streetRequired?: boolean;
}

export interface IGeoLookupParams extends ILookupParam {
  position: IPosition;
}

export interface IPlaceLookupParam extends ILookupParam {
  placeID: string;
}

export type IAddressLookupParams = IGeoLookupParams | IPlaceLookupParam;

export interface IUserParams {
  id: string;
  type: ProfileType;
}

export interface ISharedProfileUpdateParam {
  avatar?: string;
  description?: string;
  googlePlaceId?: string;
  firstName?: string;
  lastName?: string;
  setMessengerAvailability?: boolean;
}

export interface INotificationUpdateParams {
  notifications?: Partial<INotificationsUpdateParam>;
}

interface INotificationsUpdateParam {
  [code: string]: INotificationMediumUpdateParam | undefined;
}

interface INotificationMediumUpdateParam {
  [medium: string]: INotificationSettingUpdateParam | undefined;
}

interface INotificationSettingUpdateParam {
  attribute: string;
  value: boolean;
}

export interface IPrivateProfileUpdateParam extends ISharedProfileUpdateParam, INotificationUpdateParams {
  dayOfBirth?: string;
  receiveDailyUpdateEmail?: boolean;
}

export interface IIntermediateProfileUpdateParam extends ISharedProfileUpdateParam {
  dayOfBirth?: string;
  phone?: string;
  website?: string;
  name?: string;
  siteNotice?: string;
  allowReceiveDailyEmails?: boolean;
  categoryIds?: string[];
  targetModel?: IntermediateProfileTargetModel;
}

export type IntermediateProfileTargetModel = typeof PRIVATE_PROFILE_TYPE | typeof BLOG_PROFILE_TYPE | '';

export interface ISharedPublicProfileUpdateParam extends IIntermediateProfileUpdateParam, INotificationUpdateParams {}

export interface IParticipationUpdateParam extends INotificationUpdateParams {}

declare type RelatedContentAuthorType
  = typeof PRIVATE_PROFILE_TYPE
  | typeof BLOG_PROFILE_TYPE
  | typeof AUTHORITY_PROFILE_TYPE
  | typeof PRESS_PROFILE_TYPE
  | typeof REPORTER_PROFILE_TYPE
  ;

export interface IRelatedContentParams extends IPaginationParams {
  authorTypes?: RelatedContentAuthorType[];
}

export interface IValidateAddressParam {
  data: IPosition | string;
}

export interface IOAuth2LoginParams {
  email: string;
  password: string;
}

export interface IOAuth2LogoutParams {
  token: string;
}

export interface IOAuth2RefreshParams {
  refreshToken: string;
}

export interface IRegistrationParams extends IOAuth2LoginParams {
  preRegistrationToken?: string;
}

export interface IRegistrationFixedParams extends IRegistrationParams {
  ageCheckAccepted: boolean;
  googlePlaceId: string;
  privacyConditionsAccepted: boolean;
  redirectUrl: string;
  termsOfUseAccepted: boolean;
}

export interface IPasswordChangeParams {
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
}

export interface IPasswordResetParams {
  email: string;
  redirectUrl: string;
}

export interface IPasswordResetConfirmParams {
  password: string;
  passwordConfirmation: string;
  resetPasswordToken: string;
}

export interface IFeedbackCreate {
  email?: string;
  message: string;
}

export interface IQuestion {
  id: number;
  text: string;
}

export interface IShareCreate {
  emails: string[];
  personalMessage?: string;
  sender: string;
}

export type GetProfilesOrderBy = OrderByName | OrderByCreatedAt;

interface IGetProfilesBaseParams extends IPaginationParams {
  boundingBox?: IBoundingBox;
  query?: string;
  orderBy?: GetProfilesOrderBy;
  orderDirection?: OrderDirection;
}

export interface IGetProfilesParams extends IGetProfilesBaseParams {
  authorType?: ProfileType;
}

export interface IGetFollowListParams extends IPaginationParams {
  orderBy?: OrderByCreatedAt;
  orderDirection?: OrderDirection;
}

export interface IGetCategorizedProfilesParams extends IGetProfilesBaseParams {
  perType?: number;
}

// our frontend currently does not support all notification event codes which the API knows,
// so we map unsupported codes to an internally used 'unknown' code; but we do not want
// to send this code to the API
type APINotificationEventCode = Exclude<NotificationEventCode, 'unknown'>;

export interface INotificationsParams extends IPaginationParams {
  padding?: number;
  withoutEventCodes?: APINotificationEventCode[];
}

export interface INotificationsSetTraitParams {
  trait: NotificationTraitType;
  notificationIds: string[];
}

export interface INotificationsUnseenCountsParams {
  withoutEventCodes?: APINotificationEventCode[];
}

const defaultNotificationsUnseenCountsParams: INotificationsUnseenCountsParams = {
  withoutEventCodes: [],
};

export interface IEventParticipationListParams extends IPaginationParams {
  id: string;
}

interface IGroupMemberListParams extends IPaginationParams {
  path: string;
}

export type FollowerType =
  | typeof AUTHORITY_PROFILE_TYPE
  | typeof BLOG_PROFILE_TYPE
  | typeof PRESS_PROFILE_TYPE
  | typeof PRIVATE_PROFILE_TYPE;

export type FollowableType =
  | typeof AUTHORITY_PROFILE_TYPE
  | typeof BLOG_PROFILE_TYPE
  | typeof PRESS_PROFILE_TYPE;

export interface IFollowable {
  followableId: string;
  followableType: ProfileType;
}

export interface IFollower {
  followerId: string;
  followerType: FollowerType;
}

export interface IFollowSettings {
  notification?: boolean;
}

export interface IFollowByFollowableParams extends IFollowable, IFollowSettings {}

export interface IFollowByProfilesParams extends IFollowable, Partial<IFollower>, IFollowSettings {}

export type FollowParams = IFollowByFollowableParams | IFollowByProfilesParams;

export interface IUnFollowByProfilesParams extends IFollowable, Partial<IFollower> {}

export interface IFollowUpdateNotificationsByProfilesParams extends IFollowable, Partial<IFollower>, IFollowSettings {}

export default {
  address: {
    getAddressBy: (param: IAddressLookupParams) => {
      return read(PATH.API_ADDRESS_LOOKUP_PATH, decoder.$addressGeoLookup, encoder.$addressLookup(param));
    },
  },
  authorFeed: {
    get: (params: IProfileFeedRequestParams, signal?: AbortSignal) => {
      const url = UrlUtils.getProfileApiPath(params.id, params.authorType);

      if (!url) {
        return Promise.resolve(null);
      }

      return read(url, decoder.$feed, encoder.$feedPagination(params), signal);
    },
  },
  authorityProfile: {
    get: (id: string) =>
      read(PATH.API_AUTHORITY_PROFILE_PATH + id, decoder.$profileResponse),
    update: (param: ISharedPublicProfileUpdateParam) =>
      update(PATH.API_AUTHORITY_PROFILE_UPDATE_PATH,
        encoder.$authorityProfileUpdate(param), decoder.$authorityProfileResponse),
  },
  blacklist: {
    get: (params: IPaginationParams) =>
      read(PATH.API_BLACKLIST_PATH, decoder.$blacklistGet, encoder.$blacklistGet(params)),
    update: (path: string) =>
      update(path, encoder.$blacklistUpdate(), decoder.$noop),
  },
  blogProfile: {
    create: (params: ISharedPublicProfileUpdateParam) =>
      create(PATH.API_BLOG_PROFILE_PATH, encoder.$blogProfileUpdate(params), decoder.$blogProfileResponse),
    get: (id: string) =>
      read(PATH.API_BLOG_PROFILE_PATH + id, decoder.$profileResponse),
    preview: (param: ISharedPublicProfileUpdateParam) =>
      create(PATH.API_BLOG_PROFILE_PREVIEW_PATH, encoder.$blogProfileUpdate(param), decoder.$noop),
    update: (param: ISharedPublicProfileUpdateParam) =>
      update(PATH.API_BLOG_PROFILE_UPDATE_PATH, encoder.$blogProfileUpdate(param), decoder.$blogProfileResponse),
  },
  campaign: {
    create: ({ data, path }: ICampaignCreate) =>
      create(path, data, decoder.$noop),
  },
  category: {
    list: (categorizable: ICategoryType) =>
      read(PATH.API_CATEGORIES_PATH, decoder.$categories, encoder.$categories(categorizable)),
  },
  comment: {
    create: ({ message, path, images, linkPreviewId }: ICommentCreate) =>
      create(path, encoder.$commentCreate(message, images, linkPreviewId), decoder.$commentCreate),
    delete: (url: string) => destroy(url, {}, decoder.$commentSingle),
    list: (path: string) =>
      read(path, decoder.$comments, encoder.$comments()),
    update: ({ message, path, addedImages, deletedImagesIds, linkPreviewId }: ICommentUpdate) =>
      update(path, encoder.$commentUpdate(message, addedImages, deletedImagesIds, linkPreviewId),
        decoder.$commentCreate),
  },
  event: {
    create: ({ data, path }: IEventCreate) =>
      create(path, encoder.$eventCreate(data), decoder.$postResponse),
    edit: (path: string) => read(path, decoder.$eventEditView),
    update: ({ data, path }: IEventUpdateParam) =>
      update(path, encoder.$eventUpdate(data), decoder.$postResponse),
  },
  eventFeed: {
    get: (params: IEventfeedRequestParams, signal?: AbortSignal) =>
      read(PATH.API_EVENTSFEED_PATH, decoder.$feedGroupedByDate, encoder.$eventFeed(params), signal),
    getCategorized: (params: ICategorizedEventfeedRequestParams, signal?: AbortSignal) =>
      read(
        PATH.API_EVENTS_CATEGORIZED_FEED_PATH,
        decoder.$feed,
        encoder.$eventCategorizedFeed(params),
        signal,
      ),
    getParticipating: (params: IParticipatingFeedRequestParams, signal?: AbortSignal) =>
      read(
        PATH.API_EVENTS_PARTICIPATING_FEED_PATH,
        decoder.$feedGroupedByDate,
        encoder.$participatingFeed(params),
        signal
      ),
  },
  eventParticipation: {
    create: (path: string) =>
      create(path, {}, decoder.$noop),
    delete: (path: string) =>
      destroy(path, {}, decoder.$noop),
    get: (params: IEventParticipationListParams) =>
      read(`${PATH.API_EVENTSFEED_PATH}${params.id}${PATH.FRONTEND_EVENT_PARTICIPANTS_PATH}`,
        decoder.$profiles, encoder.$eventParticipationList(params)),
  },
  factSheet: {
    createLink: (link: IExtensionLink) => create(
      PATH.API_FACT_SHEET_LINKS,
      encoder.$createFactSheetLink(link),
      decoder.$noop
    ),
    deleteLink: (id: string) => destroy(PATH.API_FACT_SHEET_LINKS, { id }, decoder.$noop),
    getLink: (
      type: IExtensionLinkPlatform,
      extensionType: ProfileType,
      extensionId: string
    ) => read<IExtensionLink[], IExtensionLink | null>(
      `${PATH.API_FACT_SHEET_LINKS}?extension_type=${pascalCase(extensionType)}&extension_id=${extensionId}`,
      (json) => decoder.$factSheetLink(type, json)
    ),
    list: (type: IUserProfileType) => read<IQuestion[], IQuestion[]>(
      `${PATH.API_FACT_SHEET_QUESTIONS}?responder=${pascalCase(type)}`,
      decoder.$noop
    ),
    update: (answer: string, questionId: number) =>
      update(`${PATH.API_FACT_SHEET_QUESTIONS}respond`,
        encoder.$factSheet(answer, questionId), decoder.$noop),
    updateLink: (link: IExtensionLink) => update(
      PATH.API_FACT_SHEET_LINKS,
      encoder.$updateFactSheetLink(link),
      decoder.$noop
    ),
  },
  feedback: {
    create: (params: IFeedbackCreate) => create(PATH.API_FEEDBACK_PATH, encoder.$feedback(params), decoder.$noop),
  },
  flagPost: {
    create: (path: string, flag: IFlagCreateData) =>
      create(path, encoder.$flagCreate(flag), decoder.$noop),
  },
  follow: {
    follow: (params: FollowParams) =>
      'followerId' in params
        ? create(PATH.API_FOLLOWS_PATH, encoder.$followByProfiles(params), decoder.$follow)
        : create(PATH.API_FOLLOWS_PATH, encoder.$followByFollowable(params), decoder.$follow),
    followeesList: (params: IGetFollowListParams, signal?: AbortSignal) =>
      read(PATH.API_FOLLOWEES_PATH, decoder.$profiles, encoder.$followers(params), signal),
    followersList: (params: IGetFollowListParams, signal?: AbortSignal) =>
      read(PATH.API_FOLLOWERS_PATH, decoder.$profiles, encoder.$followers(params), signal),
    recentFeed: (params: IPaginationParams, signal?: AbortSignal) =>
      read(PATH.API_FOLLOWEES_RECENT_FEED, decoder.$feed, encoder.$feedPagination(params), signal),
    unFollow: (params: IUnFollowByProfilesParams) =>
      destroy(PATH.API_FOLLOWS_PATH, encoder.$unFollowByProfiles(params), decoder.$noop),
    updateNotifications: (params: IFollowUpdateNotificationsByProfilesParams) =>
      update(PATH.API_FOLLOWS_PATH, encoder.$followUpdateNotificationsByProfiles(params), decoder.$follow),
  },
  graylist: {
    get: (params: IPaginationParams) =>
      read(PATH.API_GRAYLIST_PATH, decoder.$graylistGet, encoder.$graylistGet(params)),
    update: (path: string, expires?: Date) =>
      update(path, encoder.$graylistUpdate(expires), decoder.$noop),
  },
  group: {
    feed: (params: IGroupFeedRequestParams, signal: AbortSignal) =>
      read(params.path, decoder.$feed, encoder.$groupFeed(params), signal),
    get: (id: string) =>
      read(PATH.API_GROUP_PATH(id), decoder.$group),
    join: (path: string) =>
      create(path, {}, decoder.$groupParticipation),
    joined: (params: IPaginationParams) => read(PATH.API_MY_GROUPS_PATH, decoder.$groups, encoder.$myGroups(params)),
    leave: (path: string) =>
      destroy(path, {}, decoder.$noop),
    more: (params: IPaginationParams) =>
      read(PATH.API_ALL_GROUPS_PATH, decoder.$moreGroups, encoder.$groups(params)),
    notifications: (enabled: boolean, groupId: string) =>
      update(PATH.API_GROUP_NOTIFICATION_SETTINGS(groupId), encoder.$groupSettings(enabled), decoder.$noop),
    recentFeed: (params: IPaginationParams, signal?: AbortSignal) =>
      read(PATH.API_GROUPS_RECENT_FEED, decoder.$feed, encoder.$feedPagination(params), signal),
  },
  groupMember: {
    confirm: (path: string) => update(path, {}, decoder.$noop),
    eliminate: (path: string) =>
      destroy(path, {}, decoder.$noop),
    list: (params: IGroupMemberListParams) =>
      read(params.path, decoder.$groupParticipations, encoder.$groupMembers(params)),
    remove: (path: string) => destroy(path, {}, decoder.$noop),
  },
  intermediateProfile: {
    get: (id: string) =>
      read(PATH.API_INTERMEDIATE_PROFILE_PATH + id, decoder.$profileResponse),
    update: (param: IIntermediateProfileUpdateParam) =>
      update(PATH.API_INTERMEDIATE_PROFILE_PATH,
        encoder.$intermediateProfileUpdate(param), decoder.$intermediateProfileResponse),
    upgrade: (target?: IUserProfileType) =>
      update(PATH.API_INTERMEDIATE_PROFILE_UPGRADE_PATH, {}, decoder.$upgradeProfileResponse(target)),
  },
  locationShape: {
    get: (id: string) =>
      read(`${PATH.API_LOCATION_SHAPES_PATH}${id}`, decoder.$locationShapeResponse),
  },
  mailbox: {
    conversation: (params: IConversationParams) => {
      return (read(`${PATH.API_CONVERSATIONS_PATH}${params.conversationId}/`, decoder.$conversationResponse));
    },
    conversations: (params: IPaginationParams) => {
      return (read(PATH.API_CONVERSATIONS_PATH, decoder.$conversations, encoder.$pagination(params)));
    },
    createConversation: (params: ICreateConversationParams) =>
      create(
        PATH.API_CONVERSATIONS_PATH,
        encoder.$conversationCreate(params.profileType, params.profileId),
        decoder.$conversationCreate,
      ),
    createMessage: (params: ISendMessageParams) =>
      create(
        `${PATH.API_CONVERSATIONS_PATH}${params.conversationId}/messages`,
        encoder.$messageCreate(params.body, params.images, params.linkPreviewUrl),
        decoder.$messageCreate
      ),
    getUnreadCount: () => read(PATH.API_CONVERSATIONS_UNREAD_COUNT_PATH, decoder.$conversationUnreadCount),
    messages: (params: IMailboxMessagesParams) => {
      const path = `${PATH.API_CONVERSATIONS_PATH}${params.conversationId}/messages`;
      return (read(path, decoder.$conversationMessages, encoder.$conversationMessage(params)));
    },
  },
  me: {
    changePassword: (params: IPasswordChangeParams) =>
      update(PATH.API_USER_UPDATE_PASSWORD, encoder.$passwordChange(params), decoder.$noop),
    get: (id?: string) => read(PATH.API_ME_PATH, decoder.$user(id)),
    registration: (params: IRegistrationFixedParams) =>
      create(PATH.API_REGISTRATION_PATH, encoder.$registration(params), decoder.$noop),
    resetPassword: (params: IPasswordResetParams) =>
      create(PATH.API_PASSWORD_PATH, encoder.$passwordReset(params), decoder.$noop),
    resetPasswordConfirm: (params: IPasswordResetConfirmParams) =>
      update(PATH.API_PASSWORD_PATH, encoder.$passwordResetConfirm(params), decoder.$noop),
  },
  newsFeed: {
    get: (params: INewsfeedRequestParams, signal?: AbortSignal) => {
      return read(PATH.API_NEWSFEED_PATH, decoder.$feed, encoder.$newsFeed(params), signal);
    },
  },

  newsFeedHighlights: {
    get: () => read(PATH.API_NEWSFEED_HIGHLIGHTS_PATH, decoder.$newsFeedHighlights),
  },

  notification: {
    fetch: (params: INotificationsParams) =>
      read(PATH.API_NOTIFICATION_FETCH_PATH, decoder.$notifications, encoder.$notifications(params)),
    getUnseenCounts: (params: INotificationsUnseenCountsParams = defaultNotificationsUnseenCountsParams) =>
      read(PATH.API_NOTIFICATION_COUNTS_PATH,
        decoder.$notificationsUnseenCounts,
        encoder.$notificationsUnseenCounts(params)),
    setTrait: (params: INotificationsSetTraitParams) =>
      update(PATH.API_NOTIFICATION_SET_TRAIT, encoder.$notificationsSetTrait(params), decoder.$noop),
  },

  oauth2: {
    login: (params: IOAuth2LoginParams) =>
      create(PATH.OAUTH2_TOKEN_PATH, encoder.$oauth2Login(params), decoder.$oauth2Credentials),
    logout: (params: IOAuth2LogoutParams) =>
      create(PATH.OAUTH2_REVOKE_PATH, encoder.$oauth2Logout(params), decoder.$noop),
    refresh: (params: IOAuth2RefreshParams) =>
      create(PATH.OAUTH2_TOKEN_PATH, encoder.$oauth2Refresh(params), decoder.$oauth2Credentials),
  },

  oglink: {
    get: (url: string) =>
      read(PATH.API_OGLINKS_PATH, decoder.$oglink, encoder.$url(url)),
  },

  paricipations: {
    update: (settings: IParticipationUpdateParam) =>
      update(PATH.API_PROFILE_PARTICIPATIONS, encoder.$updateParticipations(settings), decoder.$notificationSettings),
  },
  post: {
    addView: (path: string) =>
      update(path, {}, decoder.$noop),
    create: ({ data, path }: IPostCreate) =>
      create(path, encoder.$postCreate(data), decoder.$postResponse),
    destroy: (path: string) =>
      destroy(path, {}, decoder.$noop),
    edit: (path: string) => read(path, decoder.$postEditView),
    get: (path: string, params?: IPostGetParams) =>
      read(path, decoder.$postResponse, params && encoder.$postGet(params)),
    isAllowedToPostHere: (param: IValidateAddressParam) =>
      read(PATH.API_POST_VALIDATE_ADDRESS_PATH, decoder.$validateAddress, encoder.$validateAddress(param)),
    update: ({ path, data }: IPostUpdateParam) =>
      update(path, encoder.$postUpdate(data), decoder.$postResponse),
  },

  postRecommendation: {
    get: () => read(PATH.API_NEWSFEED_POST_RECOMMENDATION_PATH, decoder.$postResponse),
  },

  pressProfile: {
    get: (id: string) =>
      read(PATH.API_PRESS_PROFILE_PATH + id, decoder.$profileResponse),
    update: (param: ISharedPublicProfileUpdateParam) =>
      update(PATH.API_PRESS_PROFILE_UPDATE_PATH, encoder.$pressProfileUpdate(param), decoder.$pressProfileResponse),
  },
  privateProfile: {
    create: (param: IPrivateProfileUpdateParam) =>
      create(PATH.API_PRIVATE_PROFILE_PATH, encoder.$privateProfileUpdate(param), decoder.$privateProfileResponse),
    get: (id: string) =>
      read(PATH.API_PRIVATE_PROFILE_PATH + id, decoder.$profileResponse),
    preview: (param: IPrivateProfileUpdateParam) =>
      create(PATH.API_PRIVATE_PROFILE_PREVIEW_PATH, encoder.$privateProfileUpdate(param), decoder.$noop),
    update: (param: IPrivateProfileUpdateParam) =>
      update(PATH.API_PRIVATE_PROFILE_UPDATE_PATH,
        encoder.$privateProfileUpdate(param), decoder.$privateProfileResponse),
  },
  profile: {
    delete: (password: string) =>
      destroy(PATH.API_USER, { password }, decoder.$noop),
    phoneVerify: (token: string) =>
      create(PATH.API_USER_PHONE_VERIFY, encoder.$profileVerify(token), decoder.$noop),
    resendConfirmationEmail: () => {
      const redirectUrl = UrlUtils.emailConfirmationRedirectUrl();
      return create(
        PATH.API_USER_RESEND_CONFIRMATION_EMAIL,
        encoder.$resendConfirmationEmail(redirectUrl),
        decoder.$noop,
      );
    },
    updateEmail: (email: string, password: string) =>
      update(PATH.API_USER_UPDATE_EMAIL, encoder.$updateEmail(email, password), decoder.$noop),
    updatePhone: (phone: string) =>
      update(PATH.API_USER_UPDATE_PHONE, { phone }, decoder.$updatePhone),
    updateReceiveNewsletter: (recieveNewsletter: boolean) =>
      update(PATH.API_USER, encoder.$profileUpdateReceiveNewsletter(recieveNewsletter), decoder.$receiveNewsletter),
    verify: (token: string) =>
      create(PATH.API_USER_VERIFY, encoder.$profileVerify(token), decoder.$noop),
  },
  profiles: {
    getCategorizedProfiles: (params: IGetCategorizedProfilesParams) =>
      read(PATH.API_PROFILES_CATEGORIZED, decoder.$categorizedProfiles, encoder.$categorizedProfiles(params)),
    getGlobalProfiles: () =>
      read('api/profile_extensions/global_profiles', decoder.$globalProfiles),
    getProfiles: (params: IGetProfilesParams, signal: AbortSignal) =>
      read(PATH.API_PROFILES, decoder.$profiles, encoder.$profiles(params), signal),
    getShapeSponsors: () =>
      read(PATH.API_SHAPE_SPONSORS, decoder.$shapeSponsors, {}),

  },
  reaction: {
    reactedProfiles: (itemType: ItemType, id: Id, signal: AbortSignal) =>
      read(PATH.API_POST_REACTED_PROFILES_PATH(itemType, id), decoder.$reactedProfiles, {}, signal),
    update: ({ path, bookmarkType }: IReactionUpdateConfig) =>
      update(path, encoder.$reactionUpdate(bookmarkType), decoder.$reactionUpdate),
  },
  relatedContent: {
    list: (path: string, params: IRelatedContentParams) =>
      read(path, decoder.$relatedContents, encoder.$relatedContent(params)),
  },
  reporterProfile: {
    get: (id: string) =>
      read(PATH.API_REPORTER_PROFILE_PATH + id, decoder.$profileResponse),
  },
  share: {
    create: (params: IShareCreate) => create(PATH.API_SHARE_PATH, encoder.$share(params), decoder.$noop),
  },
  tags: {
    add: (id: string) => create(PATH.API_ADD_TAGS_PATH, encoder.$addTag(id), decoder.$addTag),
    get: (query: string) => read(PATH.API_GET_TAGS_PATH(query), decoder.$tagsList),
    remove: (id: string) => destroy(PATH.API_REMOVE_TAG_PATH(id), {}, decoder.$noop),
  },
};
