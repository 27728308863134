/* eslint-disable react/jsx-no-bind */
import classNames from 'classnames';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { GroupsSorting, GroupsSortingSettings } from '../../../../actions/groups/groups';
import { OrderDirection } from '../../../../api/interfaces/responses/shared';
import { textResources } from '../../../../lang/de';
import { COLOR_TYPE_GRAY_TINT_9 } from '../../../../utils/color';
import Icon from '../../../forms/icon/icon';
import MenuItemSegment from '../../../item_segment/layouts/menu/menu';
import Popover from '../../../popover/popover';
import './group_sort.scss';

const options: Array<{
  value: GroupsSorting;
  label: string;
} > = [
  {
    label: textResources.groups.sortByName,
    value: GroupsSorting.NAME,
  },
  {
    label: textResources.groups.sortByLatestPost,
    value: GroupsSorting.LATEST_POST,
  },
];

interface IProps {
  sortingSettings: GroupsSortingSettings;
  onChange: (sortingSettings: GroupsSortingSettings) => void;
}

const GroupSort: FunctionComponent<IProps> = ({ sortingSettings, onChange }) => {
  const [sortBy, setSortBy] = useState<GroupsSorting>(sortingSettings.sortBy);
  const [orderBy, setOrderBy] = useState<OrderDirection>(sortingSettings.orderBy);

  const changeOrder = useCallback((orderBy: OrderDirection) => {
    onChange({
      orderBy,
      sortBy,
    });
  }, [onChange, sortBy]);

  const onOrderUp = useCallback(() => {
    setOrderBy('asc');
    changeOrder('asc');
  }, [changeOrder]);

  const onOrderDown = useCallback(() => {
    setOrderBy('desc');
    changeOrder('desc');
  }, [changeOrder]);

  const onSort = useCallback((sortBy: GroupsSorting) => {
    setSortBy(sortBy);
    onChange({
      orderBy,
      sortBy,
    });
  }, [onChange, orderBy]);

  return <div className={'group-sort'}>
    <div className={'group-sort__name'}>
      <Popover
        position={'bottom'}
        align={'relative-right'}
        layout={'plain'}
        trigger={
          <div>{options.find(({ value }) => value === sortBy)?.label}</div>
        }
      >
        <div className={'group-sort__sortby'}>
          {options.map(({ value, label }) =>
            <MenuItemSegment
              key={value}
              label={label}
              navigation={{ onClick: () => {
                onSort(value);
              } }}
              showArrow={false}
            />)}
        </div>
      </Popover>
    </div>
    <div className={'group-sort__orderby'}>
      <Icon
        name={'arrow-up'}
        onClick={onOrderUp}
        color={COLOR_TYPE_GRAY_TINT_9}
        className={classNames('group-sort__arrow', { 'group-sort__arrow--selected': orderBy === 'asc' })} />
      <Icon
        name={'arrow-down'}
        onClick={onOrderDown}
        color={COLOR_TYPE_GRAY_TINT_9}
        className={ classNames('group-sort__arrow', { 'group-sort__arrow--selected': orderBy === 'desc' }) } />
    </div>
  </div>;
};

export default GroupSort;
