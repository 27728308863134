// libs
import React, { useState } from 'react';

// interfaces / constants
import { IErrorResponse } from 'src/api/interfaces/errors';
import { CLASS_PREFIX } from 'src/constants/';
import { COLOR_TYPE_GRAY_TINT_9 } from 'src/utils/color';

// classes / components
import Button, { BUTTON_TYPE_CONTAINED, BUTTON_TYPE_TEXT } from 'src/components/forms/button/button';
import FieldSet, { FIELDSET_TYPE_XSMALL_SPACE } from 'src/components/forms/field_set/field_set';
import InputField from 'src/components/forms/input_field/input_field';
import MessageBoxWithModal from 'src/components/message_box/message_box_with_modal';
import MessageSegmentWithModal from 'src/components/message_segment/message_segment_with_modal';
import SnackBar from 'src/components/snack_bar/snack_bar';

// helpers
import api from 'src/api/';
import { textResources } from 'src/lang/de';
import { useSelectUserMail } from 'src/reducers/user/user_hooks';
import { formatApiErrorFullMessages } from 'src/utils/api_error_message/api_error_message';
import { validateGroupDescription, validateGroupName } from 'src/utils/validation';
import './group_recommendation.scss';

const labels = textResources.groups.ctaRecommendation;
const sharedLabels = textResources.shared;
const groupLabels = textResources.groups;

const cls: string = CLASS_PREFIX + 'group-recommendation';

interface IProps {
  hideModal: () => void;
}

const GroupRecommendation: React.FC<IProps> = ({ hideModal }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validName, setValidName] = useState(true);
  const [validDescription, setValidDescription] = useState(true);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const email = useSelectUserMail();

  const changeDescription = (value: string) => {
    setDescription(value);
    setValidDescription(true);
  };

  const changeName = (value: string) => {
    setName(value);
    setValidName(true);
  };

  const closeModal = () => {
    setFormSubmitted(false);
    hideModal();
  };

  const hideSnackbar = () => {
    setSnackbarMessage('');
  };

  const renderGroupRecommendationForm = () => {
    return (
      <MessageBoxWithModal
        bodyTitle={labels.title}
        isLoading={isLoading}
        onClose={closeModal}
        title={labels.header}
        whiteBackground
        light
      >
        {labels.body}
        <div className={cls + '__input'}>
          <FieldSet>
            <InputField
              assistiveText={sharedLabels.inputTooShort(5)}
              errorText={sharedLabels.inputTooShort(5)}
              label={labels.groupNameInput}
              name='emails'
              onChange={changeName}
              required
              type='email'
              valid={validName}
              value={name}
            />
            <InputField
              assistiveText={sharedLabels.inputTooShort(5)}
              errorText={sharedLabels.inputTooShort(5)}
              expandable
              label={labels.groupDescriptionInput}
              name='message'
              onChange={changeDescription}
              required
              valid={validDescription}
              value={description}
            />
          </FieldSet>
        </div>
        <div className={cls + '__button'}>
          <FieldSet type={FIELDSET_TYPE_XSMALL_SPACE}>
            <Button
              color={COLOR_TYPE_GRAY_TINT_9}
              fullWidth
              label={labels.header}
              lowerCase
              onClick={submitForm}
              variant={BUTTON_TYPE_CONTAINED}
              primary={true}
            />
            <Button
              fullWidth
              label={textResources.shared.cancel}
              lowerCase
              onClick={closeModal}
              variant={BUTTON_TYPE_TEXT}
            />
          </FieldSet>
        </div>
      </MessageBoxWithModal>
    );
  };

  const renderGroupRecommendationThanks = () => {
    return (
      <MessageSegmentWithModal
        description={groupLabels.recommendationSuccessBody}
        image={'group-create'}
        onClose={closeModal}
        title={groupLabels.recommendationSuccessTitle}
      />
    );
  };

  const submitForm = () => {
    const validName = validateGroupName(name);
    const validDescription = validateGroupDescription(description);

    if (!validName) {
      setValidName(false);
    }

    if (!validDescription) {
      setValidDescription(false);
    }

    if (validName && validDescription) {
      const message = groupLabels.sendRecommendationBody(name, description);
      setIsLoading(true);
      api.feedback.create({ email, message })
        .then(() => {
          setFormSubmitted(true);
          setIsLoading(false);
        })
        .catch((error: IErrorResponse) => {
          const errorMessage = formatApiErrorFullMessages(error, undefined, sharedLabels.errorUnknown);
          setIsLoading(false);
          setSnackbarMessage(errorMessage);
        });
    }
  };

  return (
    <>
      {snackbarMessage &&
        <SnackBar showClose onClose={hideSnackbar}>
          {snackbarMessage}
        </SnackBar>
      }
      {!formSubmitted
        ? renderGroupRecommendationForm()
        : renderGroupRecommendationThanks()
      }
    </>
  );
};

export default GroupRecommendation;
