// libs
import classNames from 'classnames';
import * as React from 'react';
import { CLASS_PREFIX } from 'src/constants/';

// components
import withPortal from 'src/high_order_components/with_portal';

// helpers
import './modal.scss';

export interface IModalProps {
  children?: JSX.Element;
  onClose?: () => void;
  noBorderRadius?: boolean;
  customClassNames?: string;
  autoSize?: boolean;
}

const cls = `${CLASS_PREFIX}modal`;
const clsBackdrop = `${cls}__backdrop`;
const clsBody = `${cls}__body`;
const BODY_CLASS = `${CLASS_PREFIX}body--modal-open`;
const bodyElement: HTMLElement = document.body;

class Modal extends React.Component<IModalProps, {}> {
  public componentDidMount() {
    bodyElement.classList.add(BODY_CLASS);
  }

  public componentWillUnmount() {
    bodyElement.classList.remove(BODY_CLASS);
  }

  public render() {
    const { children, onClose, customClassNames, autoSize } = this.props;
    return (
      <div className={cls}>
        <div className={clsBackdrop} onClick={onClose} />
        <div className={classNames(clsBody, customClassNames, !autoSize && `${clsBody}--small`)}>
          {children}
        </div>
      </div>
    );
  }
}

export default withPortal(Modal);
